import React, { useState, ChangeEvent, FormEvent, useCallback, useEffect, useRef } from 'react';
import { PlusCircle, Save, MinusCircle, RotateCw, Trash, List, XCircle, ChevronUp, ArrowDown, ArrowUp, X } from 'lucide-react';
import { ChevronDown } from 'lucide-react';

interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  departamento: string;
}

interface MultiUserAutocompleteProps {
  users: User[];
  selectedUsers: User[];
  onSelect: (users: User[]) => void;
  placeholder?: string;
}



interface Subproceso {
  id: number;
  nombre: string;
  color: string;
  orden: number; // Nueva propiedad para el orden
  globalId?: number;  // ID de la base de datos

}
interface FormData {
  id: number; 
  codigo: string;
  nombreProceso: string;
  anio: string;
  region: string;
  numeroRegion: string;
  comuna: string;
  numeroProceso: string;
  etapa: string;
  responsables_ids: number[];
  subprocesos: Subproceso[];
  originalNumeroProceso: string, // Nuevo campo para mantener el número original

}

interface Region {
  region: string;
  numero: string;
  comunas: string[];
}

interface LabelMessageProps {
  type: 'success' | 'error';
  message: string;
}


const LabelMessage: React.FC<LabelMessageProps> = ({ type, message }) => {
  const [progress, setProgress] = useState(100);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevProgress - 1;
      });
    }, 31); // Ajusta este valor para cambiar la velocidad de la animación

    return () => clearInterval(timer);
  }, []);

  const bgColor = type === 'success' ? 'bg-green-500' : 'bg-red-500';
  const borderColor = type === 'success' ? 'border-green-600' : 'border-red-600';
  const textColor = 'text-white';

  return (
    <div className={`${bgColor} ${borderColor} ${textColor} px-4 py-3 rounded relative mb-4 border-l-4 overflow-hidden`} role="alert">
      <span className="block sm:inline">{message}</span>
      <div 
        className={`absolute bottom-0 left-0 h-1 ${type === 'success' ? 'bg-green-700' : 'bg-red-700'}`} 
        style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
      ></div>
    </div>
  );
};





const MultiUserAutocomplete: React.FC<MultiUserAutocompleteProps> = ({ 
  users, 
  selectedUsers, 
  onSelect, 
  placeholder 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredUsers(users.filter(user => 
        !selectedUsers.some(selected => selected.id === user.id)
      ));
    } else {
      const filtered = users.filter(user => 
        !selectedUsers.some(selected => selected.id === user.id) &&
        (`${user.firstname} ${user.lastname}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.departamento.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredUsers(filtered);
    }
  }, [searchTerm, users, selectedUsers]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleSelect = (user: User) => {
    const newSelectedUsers = [...selectedUsers, user];
    onSelect(newSelectedUsers);
    setSearchTerm('');
    setIsOpen(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleRemoveUser = (userId: number) => {
    const newSelectedUsers = selectedUsers.filter(user => user.id !== userId);
    onSelect(newSelectedUsers);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && searchTerm === '' && selectedUsers.length > 0) {
      handleRemoveUser(selectedUsers[selectedUsers.length - 1].id);
    }
  };

  return (
    <div className="relative" ref={wrapperRef}>
      <div className="p-2 border border-gray-300 rounded min-h-[42px] flex flex-wrap gap-2 bg-white">
        {selectedUsers.map((user) => (
          <div
            key={user.id}
            className="inline-flex items-center gap-1 bg-blue-100 text-blue-800 rounded px-2 py-1 text-sm"
          >
            <span>{`${user.firstname} ${user.lastname}`}</span>
            <button
              type="button"
              onClick={() => handleRemoveUser(user.id)}
              className="text-blue-600 hover:text-blue-800"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        ))}
        <input
          ref={inputRef}
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyDown}
          placeholder={selectedUsers.length === 0 ? placeholder : ''}
          className="flex-grow outline-none text-sm min-w-[120px]"
        />
      </div>

      {isOpen && filteredUsers.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {filteredUsers.map((user) => (
            <div
              key={user.id}
              onClick={() => handleSelect(user)}
              className="p-2 hover:bg-gray-100 cursor-pointer text-sm"
            >
              <div className="font-medium">{`${user.firstname} ${user.lastname}`}</div>
              <div className="text-xs text-gray-600">
                <span className="mr-2">{user.email}</span>
                <span className="text-gray-500">{user.departamento}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};



const AdminModule = () => {
  const [formData, setFormData] = useState<FormData>({
    id: 0,
    codigo: '',
    nombreProceso: '',
    anio: '',
    region: '',
    numeroRegion: '',
    comuna: '',
    numeroProceso: '',
    originalNumeroProceso: '', // Guardamos el número original
    responsables_ids: [],
    etapa: '',
    subprocesos: [{ id: 1, nombre: '', color: '#000000', orden: 1 }], // Añadimos orden inicial
  });

  const [regions, setRegions] = useState<Region[]>([]);
  const [loadingRegions, setLoadingRegions] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [selectedComuna, setSelectedComuna] = useState<string>('');
  const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
  const [processes, setProcesses] = useState<string[]>([]);
  const [selectedProcess, setSelectedProcess] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  const comunaSelectRef = useRef<HTMLSelectElement>(null); // Crear un ref para el select de comuna
  const [message, setMessage] = useState<{ type: 'success' | 'error', message: string } | null>(null);


  
  const fetchNumeroProceso = useCallback(async (newRegion: string, newAnio: string) => {
    // Solo fetch si estamos creando un nuevo proceso (sin número de proceso original)
    if (!formData.originalNumeroProceso) {
      try {
        const response = await fetch('http://localhost:3000/php/pages/proceso/consultarnoproceso.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            region: newRegion,
            anio: newAnio,
          }),
        });
    
        const result = await response.json();
        if (result.success) {
          return "0" + result.numeroProceso;
        } else {
          console.error('Error fetching numeroProceso:', result.error);
          return null;
        }
      } catch (error) {
        console.error('Error fetching numeroProceso:', error);
        return null;
      }
    }
    return formData.originalNumeroProceso; // Retorna el número original si existe
  }, [formData.originalNumeroProceso]);
  


  const resetForm = () => {
    setFormData({
      id: 0, 
      codigo: '',
      nombreProceso: '',
      anio: '',
      region: '',
      numeroRegion: '',
      comuna: '',
      numeroProceso: '',
      originalNumeroProceso: '',
      etapa: '',
      responsables_ids: [], // Cambiado de responsable_id a responsables_ids
      subprocesos: [{ id: 1, nombre: '', color: '#000000', orden: 1 }], // Default subproceso with black color
    });
    setSelectedComuna('');
    setSelectedRegion('');
    setSelectedProcess('');
  };




  
  const decimalToRoman = (num: number): string => {
    const romanNumerals = [
      { value: 1000, symbol: 'M' },
      { value: 900, symbol: 'CM' },
      { value: 500, symbol: 'D' },
      { value: 400, symbol: 'CD' },
      { value: 100, symbol: 'C' },
      { value: 90, symbol: 'XC' },
      { value: 50, symbol: 'L' },
      { value: 40, symbol: 'XL' },
      { value: 10, symbol: 'X' },
      { value: 9, symbol: 'IX' },
      { value: 5, symbol: 'V' },
      { value: 4, symbol: 'IV' },
      { value: 1, symbol: 'I' }
    ];

    let roman = '';
    for (const { value, symbol } of romanNumerals) {
      while (num >= value) {
        roman += symbol;
        num -= value;
      }
    }
    return roman;
  };

  function obtenerCodigo(cadena: string): string {
    // Encuentra la posición del primer punto
    const puntoPos: number = cadena.indexOf('.');

    if (puntoPos === -1) {
        // Si no hay punto en la cadena, devuelve la cadena completa
        return cadena;
    }

    // Parte de la cadena antes del punto
    const parteAntesDelPunto: string = cadena.substring(0, puntoPos);

    // Parte de la cadena después del punto
    const subcadenaDespuesDelPunto: string = cadena.slice(puntoPos + 1);
    
    // Encuentra la posición del primer espacio en blanco después del punto
    const espacioPos: number = subcadenaDespuesDelPunto.search(/\s/);

    let parteDespuesDelPunto: string;

    if (espacioPos === -1) {
        // Si no hay espacio en blanco, toma toda la subcadena después del punto
        parteDespuesDelPunto = subcadenaDespuesDelPunto.trim();
    } else {
        // Si hay un espacio, toma la subcadena desde el punto hasta el espacio
        parteDespuesDelPunto = subcadenaDespuesDelPunto.substring(0, espacioPos).trim();
    }
    
    // Devuelve la concatenación de la parte antes del punto y la parte después del punto
    return parteAntesDelPunto + '.' + parteDespuesDelPunto;
}


  const generateCodigo = useCallback((data: FormData): string => {
    const { nombreProceso, anio, numeroRegion, numeroProceso, etapa } = data;
    const nombreProcesoAbreviada = nombreProceso ? nombreProceso.substring(0, 3).toUpperCase() : '';
    const anioAbreviado = anio ? anio.substring(2) : '';
    const etapaRoman = decimalToRoman(parseInt(etapa, 10) || 0);
    return `${anioAbreviado}${numeroRegion}${numeroProceso}.${etapaRoman}`;
  }, []);

 
  useEffect(() => {
    if (formData.region && formData.anio) {
  //   fetchNumeroProceso();
    }
  }, [formData.region, formData.anio]);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await import('../../data/regiones.json');
        const data = response.default as { regiones: Region[] };
        setRegions(data.regiones);
        setLoadingRegions(false);
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };

    fetchRegions();
  }, []);

  useEffect(() => {
    const region = regions.find(region => region.comunas.includes(selectedComuna));
    if (region) {
      setFormData(prevData => ({
        ...prevData,
        comuna: selectedComuna,
        region: region.region,
        numeroRegion: region.numero
      }));
    }
  }, [selectedComuna, regions]);

  useEffect(() => {
    if (selectedRegion) {
      const region = regions.find(r => r.numero === selectedRegion);
      if (region) {
        setFormData(prevData => ({
          ...prevData,
          region: region.region,
          numeroRegion: region.numero
        }));
      }
    }
  }, [selectedRegion, regions]);
  const handleComunaChange = useCallback(async (event: ChangeEvent<HTMLSelectElement>) => {
    const comuna = event.target.value;
    setSelectedComuna(comuna);

    const region = regions.find(region => region.comunas.includes(comuna));
    if (region) {
      setSelectedRegion(region.numero);
      
      setFormData(prevData => {
        const updatedData = {
          ...prevData,
          comuna: comuna,
          region: region.region,
          numeroRegion: region.numero
        };

        // Solo actualiza el número de proceso si la región ha cambiado y no tenemos un número original
        if (region.region !== prevData.region && !prevData.originalNumeroProceso) {
          fetchNumeroProceso(region.region, updatedData.anio).then(newNumeroProceso => {
            if (newNumeroProceso) {
              setFormData(currentData => ({
                ...currentData,
                numeroProceso: newNumeroProceso,
                codigo: generateCodigo({...currentData, numeroProceso: newNumeroProceso})
              }));
            }
          });
        } else {
          // Si la región no ha cambiado o tenemos un número original, mantén el número de proceso actual
          updatedData.codigo = generateCodigo(updatedData);
        }

        return updatedData;
      });
    } else {
    setSelectedRegion('');
    setFormData(prevData => {
      const updatedData = {
        ...prevData,
        comuna: comuna,
        region: '',
        numeroRegion: ''
      };
      if (updatedData.nombreProceso || updatedData.anio || updatedData.numeroRegion || updatedData.numeroProceso || updatedData.etapa) {
        updatedData.codigo = generateCodigo(updatedData);
      }
      return updatedData;
    });
  }
}, [regions, fetchNumeroProceso, generateCodigo]);

  const handleRegionChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const regionNumero = event.target.value;
    setSelectedRegion(regionNumero);
  
    const region = regions.find(r => r.numero === regionNumero);
    if (region) {
      setFormData(prevData => ({
        ...prevData,
        region: region.region,
        numeroRegion: region.numero
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        region: '',
        numeroRegion: ''
      }));
    }
  };


  useEffect(() => {
    if (formData.nombreProceso || formData.anio || formData.region || formData.numeroRegion || formData.numeroProceso || formData.etapa) {
      // Re-fetch processes when any data changes
      fetchProcesses();
    }
  }, [formData.nombreProceso, formData.anio, formData.region, formData.numeroRegion, formData.numeroProceso, formData.etapa]);

  
  
  const fetchProcesses = async () => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/proceso/get_processes.php');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setProcesses(data);
    } catch (error) {
      console.error('Error fetching processes:', error);
    }
  };

   
  const showLabelMessage = (type: 'success' | 'error', message: string, duration = 3500) => {
    setMessage({ type, message });
    setTimeout(() => {
      setMessage(null);
    }, duration);
  };


  const fetchProcessDetails = async (codigo: string) => {
    try {
      const response = await fetch(`http://localhost:3000/php/pages/proceso/get_process_details.php?codigo=${codigo}`);
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setSelectedComuna(data.data?.comuna);
      const event = { target: { value: data.data?.comuna } } as React.ChangeEvent<HTMLSelectElement>;
      handleComunaChange(event);
  
      // Primero, asegúrate de que tienes los ids de responsables
      const responsablesIds = data.data?.responsables_ids ? data.data.responsables_ids.split(',').map(Number) : [];
  
      // Si hay IDs de responsables, busca los usuarios correspondientes
      if (responsablesIds.length > 0) {
        try {
          const respUsuarios = await fetch('http://localhost:3000/php/pages/users/get_users.php');
          const allUsers = await respUsuarios.json();
          
          // Filtra los usuarios que corresponden a los IDs de responsables
          const responsablesUsers = allUsers.filter((user: User) => 
            responsablesIds.includes(user.id)
          );
          
          // Establece los usuarios seleccionados
          setSelectedUsers(responsablesUsers);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      }
  
      const subprocesosObjects = (data.data?.subprocesos || [])
        .map((subproceso: { id: number, subproceso: string, color: string, orden: number }, index: number) => ({
          id: index + 1,
          globalId: subproceso.id,
          nombre: subproceso.subproceso,
          color: subproceso.color || '#000000',
          orden: subproceso.orden || index + 1
        }))
        .sort((a: Subproceso, b: Subproceso) => a.orden - b.orden);
  
      setFormData(prevData => ({
        ...prevData,
        id: data.data?.id || 0, 
        codigo: data.data?.codigo || '',
        nombreProceso: data.data?.nombreProceso || '',
        anio: data.data?.anio || '',
        comuna: data.data?.comuna || '',
        region: data.data?.region || '',
        numeroProceso: `0${data.data?.numeroProceso || ''}`,
        originalNumeroProceso: `0${data.data?.numeroProceso || ''}`,
        etapa: data.data?.etapa || '',
        responsables_ids: responsablesIds,
        subprocesos: subprocesosObjects,
      }));
    } catch (error) {
      console.error('Error fetching process details:', error);
    }
  };

  useEffect(() => {
    fetchProcesses();

  }, []);

  const handleProcessSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const process = event.target.value;
    setSelectedProcess(process);
    const codigo = obtenerCodigo(process);
    fetchProcessDetails(codigo);
  };


  const handleAddSubproceso = () => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: [
        ...prevData.subprocesos,
        { 
          id: prevData.subprocesos.length + 1, 
          nombre: '', 
          color: '#000000', 
          orden: prevData.subprocesos.length + 1 // Asignamos el siguiente orden
        }
      ],
    }));
  };
  
  const handleRemoveSubproceso = (id: number) => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: prevData.subprocesos.filter(sp => sp.id !== id),
    }));
  };

  
  const handleSubprocesoChange = (id: number, field: 'nombre' | 'color', value: string) => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: prevData.subprocesos.map(sp =>
        sp.id === id ? { ...sp, [field]: value } : sp
      ),
    }));
  };

  
  const predefinedSubprocesos = ['Terreno', 'Permiso de Edificación', 'Proyectos Ingeniería', 'Licitación', 'Desarrollo de Proyectos', 'Estudio de Impacto Ambiental'];
  


  


  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    setFormData(prevData => {
      const updatedData = { ...prevData, [name]: value };

      if (name === 'anio' && updatedData.region) {
        fetchNumeroProceso(updatedData.region, value).then(newNumeroProceso => {
          if (newNumeroProceso) {
            setFormData(currentData => ({
              ...currentData,
              numeroProceso: newNumeroProceso,
              codigo: generateCodigo({...currentData, numeroProceso: newNumeroProceso})
            }));
          }
        });
      } else if (name === 'nombreProceso' || name === 'numeroRegion' || name === 'numeroProceso' || name === 'etapa') {
        updatedData.codigo = generateCodigo(updatedData);
      }

      return updatedData;
    });
  }, [fetchNumeroProceso, generateCodigo]);


// Función para cargar la lista de usuarios
const fetchUsers = async () => {
  try {
    const response = await fetch('http://localhost:3000/php/pages/users/get_users.php');
    if (!response.ok) throw new Error('Error fetching users');
    const data = await response.json();
    setUsers(data);
  } catch (error) {
    console.error('Error fetching users:', error);
    showLabelMessage('error', 'Error al cargar la lista de usuarios');
  }
};

// Llamar a la función al montar el componente
useEffect(() => {
  fetchUsers();
}, []);


  // Modificamos handleSubmit para enviar el orden al backend
  const handleSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (!formData.nombreProceso || !formData.anio || !formData.region || !formData.numeroRegion || !formData.numeroProceso || !formData.etapa) {
      showLabelMessage('error', 'Por favor, complete todos los campos requeridos.');
      return;
    }
  
    const dataToSend = {
      ...formData,
      responsables_ids: selectedUsers.map(user => user.id),
      subprocesos: formData.subprocesos
        .filter(sp => sp.nombre.trim() !== '')
        .map((sp, index) => ({ 
          id: sp.globalId,  // Enviamos el globalId como id
          nombre: sp.nombre,
          color: sp.color,
          orden: index + 1 
        }))
    };
    const endpoint = 'http://localhost:3000/php/pages/proceso/update_process.php';
  
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });
  
      const data = await response.json();
  
      if (data.success) {
        showLabelMessage('success', 'Datos guardados exitosamente');
        resetForm();
        setSelectedUsers([]); // Limpiar usuarios seleccionados

  
        // Fetch the updated list of processes right after saving
        fetchProcesses();
      } else {
        showLabelMessage('error', `Hubo un error al guardar los datos: ${data.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      showLabelMessage('error', 'Hubo un error al guardar los datos');
    }
  }, [formData, resetForm]);
  

  
  // Modificamos handleMoveSubproceso para actualizar el orden
  const handleMoveSubproceso = (index: number, direction: 'up' | 'down') => {
    setFormData(prevData => {
      const newSubprocesos = [...prevData.subprocesos];
      if (direction === 'up' && index > 0) {
        [newSubprocesos[index - 1], newSubprocesos[index]] = [newSubprocesos[index], newSubprocesos[index - 1]];
      } else if (direction === 'down' && index < newSubprocesos.length - 1) {
        [newSubprocesos[index], newSubprocesos[index + 1]] = [newSubprocesos[index + 1], newSubprocesos[index]];
      }
      // Actualizamos el orden de todos los subprocesos
      return { 
        ...prevData, 
        subprocesos: newSubprocesos.map((sp, idx) => ({ ...sp, orden: idx + 1 }))
      };
    });
  };


  const renderSubprocesoFields = () => {
    return formData.subprocesos.map((subproceso, index) => (
      <div key={subproceso.id} className="flex items-center mb-2">
           <div className="flex ml-2">
           <div className="flex space-x-2">
  <button 
    type="button" 
    onClick={() => handleMoveSubproceso(index, 'up')}
    disabled={index === 0}
    className={`flex items-center justify-center w-10 h-10 border border-gray-300 rounded-md bg-white text-gray-500 hover:text-gray-700 transition-colors duration-200 ${index === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    <ArrowUp className="w-5 h-5" />
  </button>
  <button 
    type="button" 
    onClick={() => handleMoveSubproceso(index, 'down')}
    disabled={index === formData.subprocesos.length - 1}
    className={`flex items-center justify-center w-10 h-10 border border-gray-300 rounded-md bg-white text-gray-500 hover:text-gray-700 transition-colors duration-200 ${index === formData.subprocesos.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    <ArrowDown className="w-5 h-5" />
  </button>
</div>

        </div>
        <div className="relative ml-5">
          <input
            type="color"
            value={subproceso.color}
            onChange={e => handleSubprocesoChange(subproceso.id, 'color', e.target.value)}
            className="absolute top-0 left-0 opacity-0 w-6 h-6 cursor-pointer"
          />
          <div 
            className="w-6 h-6 rounded-full mr-2 border border-gray-300 cursor-pointer" 
            style={{ backgroundColor: subproceso.color }}
          ></div>
        </div>
        <input
          type="text"
          value={subproceso.nombre}
          onChange={e => handleSubprocesoChange(subproceso.id, 'nombre', e.target.value)}
          className="flex-grow p-2 border border-gray-300 rounded text-sm"
          placeholder="Nombre del subproceso"
        />
     
        <button 
          type="button" 
          onClick={() => handleRemoveSubproceso(subproceso.id)} 
          className="ml-2 text-red-500 hover:text-red-700 transition-colors duration-200"
        >
          <MinusCircle className="w-5 h-5" />
        </button>
      </div>
    ));
  };

  const predefinedColors = [
    '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#C39BD3'
  ];

  const generatePastelColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 80%)`;
  };

  const handleLoadDefaultColors = () => {
    setFormData(prevData => {
      let colorIndex = 0;
      const updatedSubprocesos = prevData.subprocesos.map(sp => {
        if (sp.color === '#000000' || sp.color === '') {
          let newColor;
          if (colorIndex < predefinedColors.length) {
            newColor = predefinedColors[colorIndex];
            colorIndex++;
          } else {
            newColor = generatePastelColor();
          }
          return { ...sp, color: newColor };
        }
        return sp;
      });

      return {
        ...prevData,
        subprocesos: updatedSubprocesos
      };
    });
  };

  const handleUsersSelect = (users: User[]) => {
    setSelectedUsers(users);
    setFormData(prev => ({
      ...prev,
      responsables_ids: users.map(user => user.id)
    }));
  };
  

  const handleClearAllColors = () => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: prevData.subprocesos.map(sp => ({
        ...sp,
        color: '#000000'
      }))
    }));
  };


  return (
    <div className="max-w-7xl text-sm mx-auto p-6 bg-white rounded-lg shadow-md">
     

      <div className="mb-6">
        <label htmlFor="processSelect" className="block text-sm font-medium text-gray-700 mb-2">
          Seleccionar Proceso
        </label>
        <select
          id="processSelect"
          value={selectedProcess}
          onChange={handleProcessSelect}
          className="w-full p-2 text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Seleccionar Proceso</option>
          {processes.map((process, index) => (
            <option key={index} value={process}>
              {process}
            </option>
          ))}
        </select>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 mb-6">
          
        <div className="flex flex-col">
            <label htmlFor="codigo" className="text-sm text-lg font-semibold mb-2">Código</label>
            <input
              id="codigo"
              name="codigo"
              type="text"
              value={formData.codigo}
              readOnly
              className="p-2 border text-sm border-gray-300 rounded bg-gray-100 cursor-not-allowed"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="nombreProceso" className="text-sm font-semibold mb-2">Nombre del proceso</label>
            <input
              id="nombreProceso"
              name="nombreProceso"
              type="text"
              value={formData.nombreProceso}
              onChange={handleInputChange}
              className="p-2 text-sm border border-gray-300 rounded"
            />
          </div>

          <div className="flex flex-col ">
            <label htmlFor="anio" className="text-sm font-semibold mb-2">Año</label>
            <input
              id="anio"
              name="anio"
              type="text"
              value={formData.anio}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="comuna" className="text-sm font-semibold mb-2">Comuna</label>
            <select
        value={selectedComuna}
        onChange={handleComunaChange}
        className='p-2  border  border-gray-300 rounded'
      >
        
        <option value="">Seleccione una comuna</option>
        {regions.flatMap(region => region.comunas).map((comuna, index) => (
          <option key={index} value={comuna}>{comuna}</option>
        ))}
      </select>
          </div>

     
          <div className="flex flex-col">
            <label htmlFor="region" className="text-sm font-semibold mb-2">Región</label>
            <select
  value={selectedRegion}
  onChange={(e) => {
    const regionNumero = e.target.value;
    setSelectedRegion(regionNumero);

    const region = regions.find(r => r.numero === regionNumero);
    if (region) {
      setFormData(prevData => ({
        ...prevData,
        region: region.region,
        numeroRegion: region.numero
      }));
    }
  }}
  className='p-2 border border-gray-300 bg-gray-100 rounded  cursor-not-allowed'
  disabled
>
  <option value="" disabled>Región</option>
  {regions.map((region) => (
    <option key={region.numero} value={region.numero}>{region.region}</option>
  ))}
</select>

          </div>


          <div className="flex flex-col">
          <label htmlFor="numeroProceso" className="text-sm font-semibold mb-2">Número de proceso</label>
      <input
        id="numeroProceso"
        name="numeroProceso"
        type="text"
        value={formData.numeroProceso}
        onChange={handleInputChange}
        className="p-2 border border-gray-300 rounded bg-gray-100  cursor-not-allowed"
        disabled // Haz el campo solo lectura si no se debe editar manualmente
      />
          </div>
          <div className="flex flex-col">
          <label htmlFor="etapa" className="text-sm font-semibold mb-2">Etapa</label>
          <input
              id="etapa"
              name="etapa"
              min={1}
              type="number"
              value={formData.etapa}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded"
            />
        
      </div>
   {/*   <div className="flex flex-col">
            <label className="text-sm font-semibold mb-2">
              Responsables
            </label>
            <MultiUserAutocomplete
              users={users}
              selectedUsers={selectedUsers}
              onSelect={handleUsersSelect}
              placeholder="Buscar responsables..."
            />
          </div>
          */}
        </div>
        <div className="mb-6">
        <div className="justify-between items-center">
        <label className="text-sm font-semibold">Subprocesos</label>
       <div className='flex justify-end'>
        <button 
              type="button" 
              onClick={handleLoadDefaultColors} 
              className="flex items-center text-green-500 hover:text-green-700 transition-colors duration-200"
            >
              <RotateCw className="w-5 h-5 mr-1" /> 
              <span>Cargar colores predefinidos</span>
            </button>
            <button 
                type="button" 
                onClick={handleClearAllColors} 
                className="ml-5 flex items-center text-red-500 hover:text-red-700 transition-colors duration-200"
              >
                <XCircle className="w-5 h-5 mr-1" /> 
                <span>Borrar colores</span>
              </button>
              </div>
      </div>
        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            {renderSubprocesoFields()}
            <button 
              type="button" 
              onClick={handleAddSubproceso} 
              className="mt-2 flex items-center text-blue-500 hover:text-blue-700 transition-colors duration-200"
            >
              <PlusCircle className="w-5 h-5 mr-1" /> 
              <span>Añadir subproceso</span>
            </button>
          </div>
        </div>
        {message && (
                 <LabelMessage type={message.type} message={message.message} />

      )}
      
        <div className="flex justify-center mt-6">
          <button type="submit" className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200">
            <Save className="mr-2" /> Guardar
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminModule;