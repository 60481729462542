import { Check, ChevronDown, ChevronRight, Edit2, Plus, Trash2 } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent } from '../ui/card';



interface CollapsedSections {
    [key: string]: boolean;
  }

interface Row {
    id: string;
    centroCosto: string;
    montoUnitario: number;
    valores: number[];
    total: number;
    unidad: string;
    isHeader?: boolean;
    isTotal?: boolean;
  }

interface CalculoUrbanizacion {
    id: number;
    tipo: string;
    urbanizacion: string;
    referencia: string;
    cubicacion: number;
    costoUnitario: number;
    costoTotal: number;
  }
  

interface DatosGenerales {
  superficieTerreno: number;
  valorTerreno: number;
  factor: number;
  numeroTipologias: number;
}

interface Tipologia {
  item: number;
  unidad: string;
  cantidad: number;
  m2: number;
  ufm2: number;
}

interface SuperficiePavimento {
  item: string;
  referencia: string;
  ancho: number;
  largo: number;
  area: number;
}

const EvaluacionTecnicoComercial: React.FC = () => {
    const [superficieTerreno, setSuperficieTerreno] = useState<number>(18000);
    const [valorTerreno, setValorTerreno] = useState<number>(2);
    const [factor, setFactor] = useState<number>(1.05);
    const [numeroTipologias, setNumeroTipologias] = useState<number>(5);
  
      // Nuevos estados para los porcentajes
  const [costoFinancieroPorcentaje, setCostoFinancieroPorcentaje] = useState<number>(6);
  const [comercialPorcentaje, setComercialPorcentaje] = useState<number>(1);
  const [marketingPorcentaje, setMarketingPorcentaje] = useState<number>(1);
  const [postVentaPorcentaje, setPostVentaPorcentaje] = useState<number>(1);
  const [reservaPorcentaje, setReservaPorcentaje] = useState<number>(4);
  

    const [tipologias, setTipologias] = useState<Tipologia[]>([
        { item: 1, unidad: 'Discapacitado', cantidad: 1, m2: 60, ufm2: 10 },
        { item: 2, unidad: 'Vulnerable', cantidad: 40, m2: 50, ufm2: 11 },
        { item: 3, unidad: 'Sector medio', cantidad: 40, m2: 55, ufm2: 12 },
        { item: 4, unidad: 'Comercial 1', cantidad: 65, m2: 60, ufm2: 13 },
        { item: 5, unidad: 'Comercial 2', cantidad: 54, m2: 60, ufm2: 14 },
      ]);
    

  const [superficiePavimento, setSuperficiePavimento] = useState<SuperficiePavimento[]>([
    { item: 'Calle 1', referencia: '--', ancho: 7, largo: 1000, area: 7000 },
    { item: 'Calle 2', referencia: '--', ancho: 7, largo: 0, area: 0 },
    { item: 'Calle 3', referencia: '--', ancho: 7, largo: 0, area: 0 },
    { item: 'Pasajes 1', referencia: '--', ancho: 3.5, largo: 500, area: 1750 },
    { item: 'Pasajes 2', referencia: '--', ancho: 3.5, largo: 0, area: 0 },
    { item: 'Pasajes 3', referencia: '--', ancho: 3.5, largo: 0, area: 0 },
  ]);

  const [superficieTotalConstruida, setSuperficieTotalConstruida] = useState<number>(0);

   // State variables from the second file
   const [calculosUrbanizacion, setCalculosUrbanizacion] = useState<CalculoUrbanizacion[]>([
    { id: 1, tipo: "Urbanización Interior", urbanizacion: "Pavimentación calles", referencia: "1,5 a 2,5 UF/m2", cubicacion: 8750, costoUnitario: 2.5, costoTotal: 21875.0 },
    { id: 2, tipo: "Urbanización Interior", urbanizacion: "Pavimentación calles", referencia: "1,5 a 2,5 UF/m2", cubicacion: 0, costoUnitario: 2.5, costoTotal: 0 },
  ]);

  const [gruposColapsados, setGruposColapsados] = useState<Record<string, boolean>>({});
  const [nuevoGrupo, setNuevoGrupo] = useState<string>('');
  const [mostrarAlerta, setMostrarAlerta] = useState<boolean>(false);
  const [editandoGrupo, setEditandoGrupo] = useState<string | null>(null);
  const [nombreGrupoEditado, setNombreGrupoEditado] = useState<string>('');

// Nuevos estados para la tabla final
const [terreno, setTerreno] = useState<number[]>([199, 166, 182, 199, 199]);
const [supViviendaBrutaUnitaria, setSupViviendaBrutaUnitaria] = useState<number[]>([95, 79, 87, 95, 95]);
const [costoContruccion, setCostoContruccion] = useState<number[]>([774, 741, 868, 988, 1038]);
const [vivienda, setVivienda] = useState<number[]>([600, 550, 660, 780, 840]);
const [urbanizacion, setUrbanizacion] = useState<number[]>([174, 191, 208, 208, 198]);
const [estudios, setEstudios] = useState<number[]>([45, 45, 45, 45, 45]);
const [imiv, setImiv] = useState<number[]>([25, 25, 25, 25, 25]);
const [dia, setDia] = useState<number[]>([20, 20, 20, 20, 20]);
const [arqueologia, setArqueologia] = useState<number[]>([25, 25, 25, 25, 25]);
const [aporteEspacioPublico, setAporteEspacioPublico] = useState<number[]>([40, 40, 40, 40, 40]);
const [leyDuctos, setLeyDuctos] = useState<number[]>([10, 10, 10, 10, 10]);
const [afr, setAfr] = useState<number[]>([10, 10, 10, 10, 10]);
const [financierosYOtros, setFinancierosYOtros] = useState<number[]>([151, 141, 161, 179, 184]);
const [costoFinanciero, setCostoFinanciero] = useState<number[]>([65, 61, 69, 78, 81]);
const [comercial, setComercial] = useState<number[]>([11, 10, 12, 13, 13]);
const [marketing, setMarketing] = useState<number[]>([11, 10, 12, 13, 13]);
const [postVenta, setPostVenta] = useState<number[]>([11, 10, 12, 13, 13]);
const [imprevistos, setImprevistos] = useState<number[]>([10, 10, 10, 10, 10]);
const [reserva, setReserva] = useState<number[]>([43, 40, 46, 52, 54]);
const [gastosGenerales, setGastosGenerales] = useState<number[]>([65, 61, 69, 78, 81]);
const [utilidades, setUtilidades] = useState<number[]>([108, 101, 116, 129, 134]);
const [costoEstimadoProduccion, setCostoEstimadoProduccion] = useState<number[]>([1402, 1315, 1501, 1678, 1741]);
const [precioVenta, setPrecioVenta] = useState<number[]>([1000, 1000, 1450, 1550, 1550]);
const [deficit, setDeficit] = useState<number[]>([-402, -315, -51, -128, -191]);
const [margen, setMargen] = useState<number[]>([0, 0, 0, 0, 0]);



  const [van, setVan] = useState<number>(0);
  const [tir, setTir] = useState<number>(0);
  
  const manejarCambioCalculosUrbanizacion = (index: number, campo: keyof CalculoUrbanizacion, valor: string) => {
    setCalculosUrbanizacion(prevCalculos => {
      const nuevosCalculos = [...prevCalculos];
      if (campo === 'urbanizacion' || campo === 'referencia') {
        nuevosCalculos[index][campo] = valor;
      } else if (campo === 'cubicacion' || campo === 'costoUnitario') {
        nuevosCalculos[index][campo] = parseFloat(valor) || 0;
      }
      
      // Recalcular costoTotal
      nuevosCalculos[index].costoTotal = 
        nuevosCalculos[index].cubicacion * nuevosCalculos[index].costoUnitario;
      
      return nuevosCalculos;
    });
  };

  const alternarGrupo = (tipo: string) => {
    setGruposColapsados(prev => ({ ...prev, [tipo]: !prev[tipo] }));
  };

  const agregarFila = (tipo: string) => {
    setCalculosUrbanizacion(prevCalculos => {
      const nuevoId = Math.max(...prevCalculos.map(c => c.id)) + 1;
      const nuevaFila: CalculoUrbanizacion = {
        id: nuevoId,
        tipo: tipo,
        urbanizacion: "",
        referencia: "",
        cubicacion: 0,
        costoUnitario: 0,
        costoTotal: 0
      };
      return [...prevCalculos, nuevaFila];
    });
  };

  const eliminarFila = (id: number) => {
    setCalculosUrbanizacion(prevCalculos => prevCalculos.filter(calculo => calculo.id !== id));
  };

  const agregarNuevoGrupo = () => {
    if (nuevoGrupo.trim() !== '') {
      agregarFila(nuevoGrupo.trim());
      setNuevoGrupo('');
      setMostrarAlerta(false);
    } else {
      setMostrarAlerta(true);
    }
  };

  const manejarCambioNuevoGrupo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNuevoGrupo(e.target.value);
    setMostrarAlerta(false);
  };

  const iniciarEdicionGrupo = (tipo: string) => {
    setEditandoGrupo(tipo);
    setNombreGrupoEditado(tipo);
  };

  const guardarEdicionGrupo = () => {
    if (editandoGrupo && nombreGrupoEditado.trim() !== '') {
      setCalculosUrbanizacion(prevCalculos =>
        prevCalculos.map(calculo =>
          calculo.tipo === editandoGrupo ? { ...calculo, tipo: nombreGrupoEditado.trim() } : calculo
        )
      );
      setEditandoGrupo(null);
    }
  };

  const calculosAgrupados = calculosUrbanizacion.reduce((acc, calculo) => {
    if (!acc[calculo.tipo]) {
      acc[calculo.tipo] = [];
    }
    acc[calculo.tipo].push(calculo);
    return acc;
  }, {} as Record<string, CalculoUrbanizacion[]>);

  const calcularSumaParcial = (calculos: CalculoUrbanizacion[]) => {
    return calculos.reduce((sum, calculo) => sum + calculo.costoTotal, 0);
  };

  const calcularSumaTotal = () => {
    return Object.values(calculosAgrupados).reduce((total, calculos) => total + calcularSumaParcial(calculos), 0);
  };

  
  useEffect(() => {
    const totalSuperficie = tipologias.reduce((total, tipologia) => {
      return total + (tipologia.cantidad * tipologia.m2);
    }, 0);
    setSuperficieTotalConstruida(totalSuperficie);
  }, [tipologias]);

  useEffect(() => {
    const updatedSuperficiePavimento = superficiePavimento.map(item => ({
      ...item,
      area: item.ancho * item.largo
    }));
    setSuperficiePavimento(updatedSuperficiePavimento);
  }, [superficiePavimento]);


  const calculateSubtotals = () => {
    const totalLargo = superficiePavimento.reduce((sum, item) => sum + item.largo, 0);
    const totalArea = superficiePavimento.reduce((sum, item) => sum + item.area, 0);
    return { totalLargo, totalArea };
  };

  const { totalLargo, totalArea } = calculateSubtotals();
  const [sumatoriaTotal, setSumatoriaTotal] = useState<number>(0);
  const [indiceUrbanizacion, setIndiceUrbanizacion] = useState<number>(0);

  const [totalSuperficie, setTotalSuperficie] = useState<number>(0);

  const calculateTotalSuperficie = () => {
    return tipologias.reduce((total, tipologia) => total + (tipologia.cantidad * tipologia.m2), 0);
  };

  // Actualizar efecto para recalcular el total de la Tabla 2
  useEffect(() => {
    const total = calculateTotalSuperficie();
    setTotalSuperficie(total);
    setSuperficieTotalConstruida(total);
  }, [tipologias]);

  const [rows, setRows] = useState([
    { id: "A", centroCosto: "TERRENO", montoUnitario: 0, valores: [199, 166, 182, 199, 199], total: 945, unidad: "UF", isHeader: true },
    { id: "A.1", centroCosto: "SUP. VIVIENDA BRUTA UNITARIA", montoUnitario: 0, valores: [95, 79, 87, 95, 95], total: 451, unidad: "m2" },
    { id: "B", centroCosto: "COSTO CONTRUCCIÓN", montoUnitario: 0, valores: [774, 741, 868, 988, 1038], total: 4409, unidad: "UF", isHeader: true },
    { id: "B.1", centroCosto: "VIVIENDA", montoUnitario: 0, valores: [600, 550, 660, 780, 840], total: 3430, unidad: "UF" },
    { id: "B.2", centroCosto: "URBANIZACIÓN", montoUnitario: 0, valores: [174, 191, 208, 208, 198], total: 979, unidad: "UF" },
       { id: "C", centroCosto: "ESTUDIOS", montoUnitario: 0, valores: [45, 45, 45, 45, 45], total: 225, unidad: "UF/VIV", isHeader: true },
    { id: "C.1", centroCosto: "IMIV", montoUnitario: 0, valores: [25, 25, 25, 25, 25], total: 125, unidad: "UF/VIV" },
    { id: "C.2", centroCosto: "DIA", montoUnitario: 0, valores: [20, 20, 20, 20, 20], total: 100, unidad: "UF/VIV" },
    { id: "C.3", centroCosto: "ARQUEOLOGÍA", montoUnitario: 0, valores: [25, 25, 25, 25, 25], total: 125, unidad: "UF/VIV" },
    { id: "D", centroCosto: "APORTE ESPACIO PUBLICO", montoUnitario: 0, valores: [40, 40, 40, 40, 40], total: 200, unidad: "UF", isHeader: true },
    { id: "E", centroCosto: "LEY DE DUCTOS", montoUnitario: 0, valores: [10, 10, 10, 10, 10], total: 50, unidad: "UF", isHeader: true },
    { id: "F", centroCosto: "AFR", montoUnitario: 0, valores: [10, 10, 10, 10, 10], total: 50, unidad: "UF", isHeader: true },
    { id: "G", centroCosto: "FINANCIEROS Y OTROS", montoUnitario: 0, valores: [151, 141, 161, 179, 184], total: 816, unidad: "UF", isHeader: true },
    { id: "G.1", centroCosto: "Costo financiero", montoUnitario: 0, valores: [65, 61, 69, 78, 81], total: 354, unidad: "%" },
    { id: "G.2", centroCosto: "Comercial", montoUnitario: 0, valores: [11, 10, 12, 13, 13], total: 59, unidad: "%" },
    { id: "G.3", centroCosto: "Marketing", montoUnitario: 0, valores: [11, 10, 12, 13, 13], total: 59, unidad: "%" },
    { id: "G.4", centroCosto: "Post Venta", montoUnitario: 0, valores: [11, 10, 12, 13, 13], total: 59, unidad: "%" },
    { id: "G.5", centroCosto: "Imprevistos", montoUnitario: 0, valores: [10, 10, 10, 10, 10], total: 50, unidad: "%" },
    { id: "G.6", centroCosto: "Reserva", montoUnitario: 0, valores: [43, 40, 46, 52, 54], total: 235, unidad: "%" },
    { id: "H", centroCosto: "GASTOS GENERALES", montoUnitario: 0, valores: [65, 61, 69, 78, 81], total: 354, unidad: "%", isHeader: true },
    { id: "I", centroCosto: "UTILIDADES", montoUnitario: 0, valores: [108, 101, 116, 129, 134], total: 588, unidad: "%", isHeader: true },
    { id: "A+B+C+D+E+F+G+H+I", centroCosto: "COSTO ESTIMADO PRODUCCIÓN", montoUnitario: 0, valores: [1402, 1315, 1501, 1678, 1741], total: 7637, unidad: "UF", isHeader: true, isTotal: true },
    { id: "J", centroCosto: "PRECIO VENTA", montoUnitario: 0, valores: [1000, 1000, 1450, 1550, 1550], total: 6550, unidad: "UF", isHeader: true },
    { id: "K", centroCosto: "DEFICIT", montoUnitario: 0, valores: [-402, -315, -51, -128, -191], total: -1087, unidad: "UF", isHeader: true },
    { id: "L", centroCosto: "MARGEN", montoUnitario: 0, valores: [0, 0, 0, 0, 0], total: 0, unidad: "UF", isHeader: true },
  ]);

  const [collapsedSections, setCollapsedSections] = useState<Record<string, boolean>>({});

  useEffect(() => {
    updateTerrenoValue();
    updateSupViviendaBrutaUnitaria();
  }, [tipologias]);



  useEffect(() => {
    updateCalculatedValues();
  }, [tipologias, rows, indiceUrbanizacion]);


  
useEffect(() => {
    // Actualizar tipologías basado en numeroTipologias
    const newTipologias = [...tipologias];
    const currentLength = newTipologias.length;
    const targetLength = Math.max(0, numeroTipologias); // Asegurarse de que no sea negativo
  
    // Agregar o eliminar tipologías según sea necesario
    if (currentLength < targetLength) {
      for (let i = currentLength + 1; i <= targetLength; i++) {
        newTipologias.push({
          item: i,
          unidad: `Tipología ${i}`,
          cantidad: 0,
          m2: 0,
          ufm2: 0
        });
      }
    } else if (currentLength > targetLength) {
      newTipologias.splice(targetLength);
    }
  
    // Actualizar estado de tipologías
    setTipologias(newTipologias);
  
    // Actualizar filas basado en el número de tipologías
    const updatedRows = rows.map((row) => {
      const currentValores = Array.isArray(row.valores) ? row.valores : [];
      const newValores = [...currentValores];
  
      // Ajustar longitud de newValores para que coincida con numeroTipologias
      if (newValores.length < targetLength) {
        // Rellenar con ceros si faltan elementos
        newValores.push(...Array(targetLength - newValores.length).fill(0));
      } else if (newValores.length > targetLength) {
        // Eliminar valores sobrantes si hay más de los necesarios
        newValores.splice(targetLength);
      }
  
      // Retornar fila actualizada con valores ajustados
      return {
        ...row,
        valores: newValores,
      };
    });
  
    // Actualizar estado de filas
    setRows(updatedRows);
  
  }, [numeroTipologias, tipologias, rows]); // Incluir dependencias correctas
  


  useEffect(() => {
    updateCalculatedValues();
  }, [tipologias, rows, indiceUrbanizacion]);

  const updateTerrenoValue = () => {
    const newRows = rows.map(row => {
      if (row.id === "A") {
        const newValores = [...row.valores];
        newValores[0] = calculateTerrenoValue();
        const newTotal = newValores.reduce((sum, val) => sum + (val || 0), 0);
        return { ...row, valores: newValores, total: newTotal };
      }
      return row;
    });
    setRows(newRows);
  };

  const updateSupViviendaBrutaUnitaria = () => {
    const newRows = rows.map(row => {
      if (row.id === "A.1") {
        const newValores = [...row.valores];
        newValores[0] = calculateSupViviendaBrutaUnitaria();
        const newTotal = newValores.reduce((sum, val) => sum + (val || 0), 0);
        return { ...row, valores: newValores, total: newTotal };
      }
      return row;
    });
    setRows(newRows);
  };

  const calculateSupViviendaBrutaUnitaria = () => {
    const discapacitadoM2 = tipologias[0]?.m2 || 0;
    const superficieTotalConstruida = tipologias.reduce((total, tipologia) => total + (tipologia.cantidad * tipologia.m2), 0);
    return Math.round((discapacitadoM2 * superficieTerreno) / superficieTotalConstruida);
  };

// Funciones auxiliares
  const calculateTerrenoValue = () => {
    const supViviendaBrutaUnitaria = rows.find(row => row.id === "A.1")?.valores[0] || 0;
    return Math.round(supViviendaBrutaUnitaria * valorTerreno * factor);
  };

  // Función para obtener el nombre amigable de los campos en Datos Generales
  const getNombreAmigable = (key: string): string => {
    switch(key) {
      case 'superficieTerreno': return 'Superficie Terreno';
      case 'valorTerreno': return 'Valor Terreno';
      case 'factor': return 'Factor';
      case 'numeroTipologias': return 'Número de Tipologías';
      default: return key;
    }
  };

  const isEditable = (rowId: string): boolean => {
    const editableIds = [
      'C.1', 'C.2', 'C.3', 'D', 'E', 'F',
      'G.1', 'G.2', 'G.3', 'G.4', 'G.6',
      'H', 'I'
    ];
    return editableIds.includes(rowId);
  };
 
 
 
  const handleDatosGeneralesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numValue = parseFloat(value) || 0;
    switch(name) {
      case 'superficieTerreno':
        setSuperficieTerreno(numValue);
        break;
      case 'valorTerreno':
        setValorTerreno(numValue);
        break;
      case 'factor':
        setFactor(numValue);
        break;
      case 'numeroTipologias':
        setNumeroTipologias(numValue);
        break;
    }
  };

  const handleTipologiasChange = (index: number, field: keyof Tipologia, value: string) => {
    const newTipologias = [...tipologias];
    if (field === 'unidad') {
      newTipologias[index][field] = value;
    } else {
      newTipologias[index][field] = parseFloat(value) || 0;
    }
    setTipologias(newTipologias);
  };

  const handleSuperficiePavimentoChange = (index: number, field: keyof SuperficiePavimento, value: string) => {
    const newSuperficiePavimento = [...superficiePavimento];
    if (field === 'item' || field === 'referencia') {
      newSuperficiePavimento[index][field] = value;
    } else {
      newSuperficiePavimento[index][field] = parseFloat(value) || 0;
    }
    if (field === 'ancho' || field === 'largo') {
      newSuperficiePavimento[index].area = newSuperficiePavimento[index].ancho * newSuperficiePavimento[index].largo;
    }
    setSuperficiePavimento(newSuperficiePavimento);
  };

  const handleCalculosUrbanizacionChange = (index: number, campo: keyof CalculoUrbanizacion, valor: string) => {
    const nuevosCalculos = [...calculosUrbanizacion];
    if (campo === 'urbanizacion' || campo === 'referencia') {
      nuevosCalculos[index][campo] = valor;
    } else if (campo === 'cubicacion' || campo === 'costoUnitario') {
      nuevosCalculos[index][campo] = parseFloat(valor) || 0;
    }
    
    nuevosCalculos[index].costoTotal = 
      nuevosCalculos[index].cubicacion * nuevosCalculos[index].costoUnitario;
    
    setCalculosUrbanizacion(nuevosCalculos);
  };

  const handleEdit = (rowId: string, index: number, value: string) => {
    const updatedRows = rows.map(row => {
      if (row.id === rowId) {
        const newValores = [...row.valores];
        newValores[index] = parseFloat(value) || 0;
        return { ...row, valores: newValores };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleCentroCostoEdit = (rowId: string, value: string) => {
    const updatedRows = rows.map(row => {
      if (row.id === rowId) {
        return { ...row, centroCosto: value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleUnidadEdit = (rowId: string, value: string) => {
    const updatedRows = rows.map(row => {
      if (row.id === rowId) {
        return { ...row, unidad: value };
      }
      return row;
    });
    setRows(updatedRows);
  };
 
 
  const updateTotals = (updatedRows: Row[]) => {
    const newRows = updatedRows.map(row => {
      if (Array.isArray(row.valores)) {
        const total = row.valores.reduce((sum, val) => sum + (val || 0), 0);
        return { 
          ...row, 
          total,
          isHeader: row.isHeader ?? false
        };
      }
      return { 
        ...row,
        total: 0,
        isHeader: row.isHeader ?? false
      };
    });
    setRows(newRows);
  };

  const formatNumber = (num: number | string | undefined): string => {
    if (num === undefined || num === '') {
      return '';
    }
    if (typeof num === 'string') {
      return num;
    }
    if (isNaN(num)) {
      return '0';
    }
    return new Intl.NumberFormat('es-CL').format(Math.round(num));
  };

  const canCollapse = (id: string): boolean => ['A', 'B', 'C', 'G'].includes(id);
  const toggleCollapse = (id: string) => {
    setCollapsedSections(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const isCollapsed = (id: string): boolean => !!collapsedSections[id];

  const shouldShow = (row: Row): boolean => {
    const parts = row.id.split('.');
    if (parts.length === 1) return true;
    if (parts.length === 2) return !isCollapsed(parts[0]);
    if (parts.length === 3) return !isCollapsed(parts[0]) && !isCollapsed(`${parts[0]}.${parts[1]}`);
    return true;
  };

  const getRowColor = (id: string): string => {
    const parts = id.split('.');
    if (parts.length === 1) return "bg-blue-200";
    if (parts.length === 2) return "bg-green-100";
    if (parts.length === 3) return "bg-pink-100";
    return "";
  };





  // Calculate the summary fields
  useEffect(() => {
    const totalCost = calcularSumaTotal();
    setSumatoriaTotal(totalCost);
    
    setIndiceUrbanizacion(superficieTerreno > 0 ? parseFloat((totalCost / superficieTerreno).toFixed(2)) : 0);
  }, [calculosUrbanizacion, superficieTerreno]);


  useEffect(() => {
    // Actualizar tipologías basado en numeroTipologias
    const newTipologias = [...tipologias];
    const currentLength = newTipologias.length;
    const targetLength = Math.max(0, numeroTipologias);
  
    if (currentLength < targetLength) {
      for (let i = currentLength + 1; i <= targetLength; i++) {
        newTipologias.push({
          item: i,
          unidad: `Tipología ${i}`,
          cantidad: 0,
          m2: 0,
          ufm2: 0
        });
      }
    } else if (currentLength > targetLength) {
      newTipologias.splice(targetLength);
    }
  
    setTipologias(newTipologias);
  
    // Actualizar estados de la tabla final
    const updateState = (state: number[], setState: React.Dispatch<React.SetStateAction<number[]>>) => {
      const newState = state.slice(0, targetLength);
      while (newState.length < targetLength) {
        newState.push(0);
      }
      setState(newState);
    };

    updateState(terreno, setTerreno);
    updateState(supViviendaBrutaUnitaria, setSupViviendaBrutaUnitaria);
    updateState(costoContruccion, setCostoContruccion);
    updateState(vivienda, setVivienda);
    updateState(urbanizacion, setUrbanizacion);
    updateState(estudios, setEstudios);
    updateState(imiv, setImiv);
    updateState(dia, setDia);
    updateState(arqueologia, setArqueologia);
    updateState(aporteEspacioPublico, setAporteEspacioPublico);
    updateState(leyDuctos, setLeyDuctos);
    updateState(afr, setAfr);
    updateState(financierosYOtros, setFinancierosYOtros);
    updateState(costoFinanciero, setCostoFinanciero);
    updateState(comercial, setComercial);
    updateState(marketing, setMarketing);
    updateState(postVenta, setPostVenta);
    updateState(imprevistos, setImprevistos);
    updateState(reserva, setReserva);
    updateState(gastosGenerales, setGastosGenerales);
    updateState(utilidades, setUtilidades);
    updateState(costoEstimadoProduccion, setCostoEstimadoProduccion);
    updateState(precioVenta, setPrecioVenta);
    updateState(deficit, setDeficit);
    updateState(margen, setMargen);
  
  }, [numeroTipologias]);

  
  useEffect(() => {
    // Actualizar tipologías basado en numeroTipologias
    const newTipologias = [...tipologias];
    const currentLength = newTipologias.length;
    const targetLength = Math.max(0, numeroTipologias);
  
    if (currentLength < targetLength) {
      for (let i = currentLength + 1; i <= targetLength; i++) {
        newTipologias.push({
          item: i,
          unidad: `Tipología ${i}`,
          cantidad: 0,
          m2: 0,
          ufm2: 0
        });
      }
    } else if (currentLength > targetLength) {
      newTipologias.splice(targetLength);
    }
  
    setTipologias(newTipologias);
  
    // Actualizar estados de la tabla final
    const updateState = (state: number[], setState: React.Dispatch<React.SetStateAction<number[]>>) => {
      const newState = state.slice(0, targetLength);
      while (newState.length < targetLength) {
        newState.push(0);
      }
      setState(newState);
    };

    updateState(terreno, setTerreno);
    updateState(supViviendaBrutaUnitaria, setSupViviendaBrutaUnitaria);
    updateState(costoContruccion, setCostoContruccion);
    updateState(vivienda, setVivienda);
    updateState(urbanizacion, setUrbanizacion);
    updateState(estudios, setEstudios);
    updateState(imiv, setImiv);
    updateState(dia, setDia);
    updateState(arqueologia, setArqueologia);
    updateState(aporteEspacioPublico, setAporteEspacioPublico);
    updateState(leyDuctos, setLeyDuctos);
    updateState(afr, setAfr);
    updateState(financierosYOtros, setFinancierosYOtros);
    updateState(costoFinanciero, setCostoFinanciero);
    updateState(comercial, setComercial);
    updateState(marketing, setMarketing);
    updateState(postVenta, setPostVenta);
    updateState(imprevistos, setImprevistos);
    updateState(reserva, setReserva);
    updateState(gastosGenerales, setGastosGenerales);
    updateState(utilidades, setUtilidades);
    updateState(costoEstimadoProduccion, setCostoEstimadoProduccion);
    updateState(precioVenta, setPrecioVenta);
    updateState(deficit, setDeficit);
    updateState(margen, setMargen);
  
  }, [numeroTipologias]);

  // Función para actualizar los valores calculados
  const updateCalculatedValues = () => {
    // Actualizar TERRENO
    setTerreno(tipologias.map(t => Math.round(t.m2 * valorTerreno * factor)));

    // Actualizar SUP. VIVIENDA BRUTA UNITARIA
    setSupViviendaBrutaUnitaria(tipologias.map(t => t.m2));

    // Actualizar COSTO CONSTRUCCIÓN
    setCostoContruccion(tipologias.map((t, i) => vivienda[i] + urbanizacion[i]));

    // Actualizar VIVIENDA
    setVivienda(tipologias.map(t => t.m2 * t.ufm2));

    // Actualizar URBANIZACIÓN
    setUrbanizacion(tipologias.map(t => Math.round(t.m2 * indiceUrbanizacion)));

    // Actualizar ESTUDIOS
    setEstudios(tipologias.map((_, i) => imiv[i] + dia[i] + arqueologia[i]));

    // Actualizar FINANCIEROS Y OTROS
    setFinancierosYOtros(tipologias.map((_, i) => 
      costoFinanciero[i] + comercial[i] + marketing[i] + postVenta[i] + imprevistos[i] + reserva[i]
    ));
   // Actualizar FINANCIEROS Y OTROS
   const newFinancierosYOtros = tipologias.map((_, i) => {
    const costoFinancieroValue = (costoContruccion[i] * costoFinancieroPorcentaje) / 100;
    const comercialValue = (costoContruccion[i] * comercialPorcentaje) / 100;
    const marketingValue = (costoContruccion[i] * marketingPorcentaje) / 100;
    const postVentaValue = (costoContruccion[i] * postVentaPorcentaje) / 100;
    const reservaValue = (costoContruccion[i] * reservaPorcentaje) / 100;
    return costoFinancieroValue + comercialValue + marketingValue + postVentaValue + imprevistos[i] + reservaValue;
  });
  setFinancierosYOtros(newFinancierosYOtros);

  setCostoFinanciero(tipologias.map((_, i) => (costoContruccion[i] * costoFinancieroPorcentaje) / 100));
  setComercial(tipologias.map((_, i) => (costoContruccion[i] * comercialPorcentaje) / 100));
  setMarketing(tipologias.map((_, i) => (costoContruccion[i] * marketingPorcentaje) / 100));
  setPostVenta(tipologias.map((_, i) => (costoContruccion[i] * postVentaPorcentaje) / 100));
  setReserva(tipologias.map((_, i) => (costoContruccion[i] * reservaPorcentaje) / 100));

  // Actualizar COSTO ESTIMADO PRODUCCIÓN
  const newCostoEstimadoProduccion = tipologias.map((_, i) => 
    terreno[i] + costoContruccion[i] + estudios[i] + aporteEspacioPublico[i] + leyDuctos[i] + 
    afr[i] + newFinancierosYOtros[i] + gastosGenerales[i] + utilidades[i]
  );
  setCostoEstimadoProduccion(newCostoEstimadoProduccion);

  // Actualizar DEFICIT
  const newDeficit = tipologias.map((_, i) => {
    if (precioVenta[i] > newCostoEstimadoProduccion[i]) {
      return 0;
    } else {
      return newCostoEstimadoProduccion[i] - precioVenta[i];
    }
  });
  setDeficit(newDeficit);

  // Actualizar MARGEN
  const newMargen = tipologias.map((_, i) => {
    if (precioVenta[i] <= newCostoEstimadoProduccion[i]) {
      return 0;
    } else {
      return precioVenta[i] - newCostoEstimadoProduccion[i];
    }
  });
  setMargen(newMargen);
};

  useEffect(() => {
    updateCalculatedValues();
  }, [tipologias, valorTerreno, factor, indiceUrbanizacion, ...Object.values(rows)]);


  return (
    
    <div className="p-4 bg-white min-h-screen text-sm">
         {/* Add the summary fields at the top */}
      <div className="flex justify-end mb-4">
        <Card className="w-64">
          <CardContent className="p-2">
            <h3 className="font-bold text-center mb-2">SUMATORIA COSTO TOTAL</h3>
            <div className="flex justify-between mb-1">
              <span>TOTAL URB. UF:</span>
              <span className="font-bold">{sumatoriaTotal.toFixed(2)}</span>
            </div>
            <div className="flex justify-between">
              <span>UF/M2:</span>
              <span className="font-bold">{indiceUrbanizacion.toFixed(2)}</span>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="flex flex-wrap -mx-2 mb-4">
 
         {/* Tabla de Datos Generales */}
         <div className="w-full lg:w-1/2 px-2 mb-4">
         
      <Card>
        <CardHeader className="py-2">
          <h2 className="text-lg font-semibold">T1: Datos Generales</h2>
        </CardHeader>
        <CardContent className="p-2">
          <table className="w-full border-collapse text-xs">
            <thead>
              <tr className="bg-blue-100">
                <th className="border p-1">Campo</th>
                <th className="border p-1">Valor</th>
                <th className="border p-1">Unidad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border p-1">Superficie Terreno</td>
                <td className="border p-1">
                  <input
                    type="number"
                    name="superficieTerreno"
                    value={superficieTerreno}
                    onChange={handleDatosGeneralesChange}
                    className="w-full bg-yellow-50 p-1 rounded text-xs"
                  />
                </td>
                <td className="border p-1">m2</td>
              </tr>
              <tr>
                <td className="border p-1">Valor Terreno</td>
                <td className="border p-1">
                  <input
                    type="number"
                    name="valorTerreno"
                    value={valorTerreno}
                    onChange={handleDatosGeneralesChange}
                    className="w-full bg-yellow-50 p-1 rounded text-xs"
                  />
                </td>
                <td className="border p-1">UF/m2</td>
              </tr>
              <tr>
                <td className="border p-1">Factor</td>
                <td className="border p-1">
                  <input
                    type="number"
                    name="factor"
                    value={factor}
                    onChange={handleDatosGeneralesChange}
                    className="w-full bg-yellow-50 p-1 rounded text-xs"
                  />
                </td>
                <td className="border p-1">%</td>
              </tr>
              <tr>
                <td className="border p-1">Número de Tipologías</td>
                <td className="border p-1">
                  <input
                    type="number"
                    name="numeroTipologias"
                    value={numeroTipologias}
                    onChange={handleDatosGeneralesChange}
                    className="w-full bg-yellow-50 p-1 rounded text-xs"
                  />
                </td>
                <td className="border p-1">cantidad</td>
              </tr>
              <tr className="font-bold bg-green-100">
              <tr className="font-bold bg-green-100">
                    <td className="border border-gray-300 p-2">Superficie Total Construida</td>
                    <td className="border border-gray-300 p-2">{totalSuperficie.toFixed(2)}</td>
                    <td className="border border-gray-300 p-2">m2</td>
                  </tr>
            </tr>
            </tbody>
          </table>
          
        </CardContent>
      </Card>
      </div>
   {/* Tabla de Tipologías */}
   <div className="w-full md:w-1/2 px-2 mb-4">

   <Card className="mt-4">
        <CardHeader className="py-2">
          <h2 className="text-lg font-semibold">T2: Tipologías</h2>
        </CardHeader>
        <CardContent className="p-2">
          <table className="w-full border-collapse text-xs">
            <thead>
              <tr className="bg-blue-100">
                <th className="border p-1">ITEM</th>
                <th className="border p-1">UNIDAD</th>
                <th className="border p-1">CANTIDAD</th>
                <th className="border p-1">M2</th>
                <th className="border p-1">UF/M2</th>
              </tr>
            </thead>
            <tbody>
              {tipologias.map((tipologia, index) => (
                <tr key={tipologia.item}>
                  <td className="border p-1">{tipologia.item}</td>
                  <td className="border p-1">
                    <input
                      type="text"
                      value={tipologia.unidad}
                      onChange={(e) => handleTipologiasChange(index, 'unidad', e.target.value)}
                      className="w-full bg-yellow-50 p-1 rounded text-xs"
                    />
                  </td>
                  <td className="border p-1">
                    <input
                      type="number"
                      value={tipologia.cantidad}
                      onChange={(e) => handleTipologiasChange(index, 'cantidad', e.target.value)}
                      className="w-full bg-yellow-50 p-1 rounded text-xs"
                    />
                  </td>
                  <td className="border p-1">
                    <input
                      type="number"
                      value={tipologia.m2}
                      onChange={(e) => handleTipologiasChange(index, 'm2', e.target.value)}
                      className="w-full bg-yellow-50 p-1 rounded text-xs"
                    />
                  </td>
                  <td className="border p-1">
                    <input
                      type="number"
                      value={tipologia.ufm2}
                      onChange={(e) => handleTipologiasChange(index, 'ufm2', e.target.value)}
                      className="w-full bg-yellow-50 p-1 rounded text-xs"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CardContent>
      </Card>
  
</div>
</div>

<div className="mb-4">
          <Card>
            <CardHeader className="py-2">
              <h2 className="text-lg font-semibold">T3: Cálculos superficie pavimento</h2>
            </CardHeader>
            <CardContent className="p-2">
              <table className="w-full border-collapse text-xs">
                <thead>
                  <tr className="bg-blue-100">
                    <th className="border p-1">Item</th>
                    <th className="border p-1">REFERENCIA</th>
                    <th className="border p-1">ANCHO (m)</th>
                    <th className="border p-1">LARGO (m)</th>
                    <th className="border p-1">ÁREA (M2)</th>
                  </tr>
                </thead>
                <tbody>
                  {superficiePavimento.map((item, index) => (
                    <tr key={item.item}>
                      <td className="border p-1">{item.item}</td>
                      <td className="border p-1">{item.referencia}</td>
                      <td className="border p-1">
                        <input
                          type="number"
                          value={item.ancho}
                          onChange={(e) => handleSuperficiePavimentoChange(index, 'ancho', e.target.value)}
                          className="w-full bg-yellow-50 p-1 rounded text-xs"
                        />
                      </td>
                      <td className="border p-1">
                        <input
                          type="number"
                          value={item.largo}
                          onChange={(e) => handleSuperficiePavimentoChange(index, 'largo', e.target.value)}
                          className="w-full bg-yellow-50 p-1 rounded text-xs"
                        />
                      </td>
                      <td className="border p-1">{item.area.toFixed(2)}</td>
                    </tr>
                  ))}
                    <tr className="font-bold">
              <td colSpan={3} className="border border-gray-300 p-2 text-right">Subtotal</td>
              <td className="border border-gray-300 p-2">{totalLargo}</td>
              <td className="border border-gray-300 p-2">{totalArea.toFixed(2)}</td>
            </tr>
                  {/* ... (mantenemos el subtotal) */}
                </tbody>
              </table>
            </CardContent>
          </Card>
        </div>
  
      <div className="mb-4">
        <Card>
          <CardHeader className="py-2">
            <h2 className="text-lg font-semibold">T4: Cálculos Urbanización</h2>
          </CardHeader>
          <CardContent className="p-2">
            <div className="overflow-x-auto">
              <table className="w-full border-collapse text-xs">
                <thead>
                  <tr className="bg-blue-100">
                    <th className="border p-1">ID</th>
                    <th className="border p-1">URBANIZACIÓN</th>
                    <th className="border p-1">REFERENCIA</th>
                    <th className="border p-1">CUBICACIÓN</th>
                    <th className="border p-1">COSTO UNITARIO</th>
                    <th className="border p-1">COSTO TOTAL</th>
                    <th className="border p-1">ACCIONES</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(calculosAgrupados).map(([tipo, calculos]) => (
                    <React.Fragment key={tipo}>
                      <tr className="bg-gray-200">
                        <td className="border p-1">
                          <button onClick={() => alternarGrupo(tipo)} className="mr-1">
                            {gruposColapsados[tipo] ? <ChevronRight size={16} /> : <ChevronDown size={16} />}
                          </button>
                        </td>
                        <td className="border p-1 font-bold" colSpan={5}>
                          {editandoGrupo === tipo ? (
                            <input
                              type="text"
                              value={nombreGrupoEditado}
                              onChange={(e) => setNombreGrupoEditado(e.target.value)}
                              className="w-full bg-white p-1 rounded text-xs"
                            />
                          ) : (
                            tipo
                          )}
                        </td>
                        <td className="border p-1">
                          {editandoGrupo === tipo ? (
                            <button onClick={guardarEdicionGrupo} className="bg-green-500 text-white p-1 rounded mr-1">
                              <Check size={16} />
                            </button>
                          ) : (
                            <button onClick={() => iniciarEdicionGrupo(tipo)} className="bg-yellow-500 text-white p-1 rounded mr-1">
                              <Edit2 size={16} />
                            </button>
                          )}
                          <button onClick={() => agregarFila(tipo)} className="bg-green-500 text-white p-1 rounded">
                            <Plus size={16} />
                          </button>
                        </td>
                      </tr>
                      {!gruposColapsados[tipo] && calculos.map((calculo, index) => (
                        <tr key={calculo.id} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>
                          <td className="border p-1 text-center">{calculo.id}</td>
                          <td className="border p-1">
                            <input
                              type="text"
                              value={calculo.urbanizacion}
                              onChange={(e) => manejarCambioCalculosUrbanizacion(calculosUrbanizacion.findIndex(c => c.id === calculo.id), 'urbanizacion', e.target.value)}
                              className="w-full bg-yellow-50 p-1 rounded text-xs"
                            />
                          </td>
                          <td className="border p-1">
                            <input
                              type="text"
                              value={calculo.referencia}
                              onChange={(e) => manejarCambioCalculosUrbanizacion(calculosUrbanizacion.findIndex(c => c.id === calculo.id), 'referencia', e.target.value)}
                              className="w-full bg-yellow-50 p-1 rounded text-xs"
                            />
                          </td>
                          <td className="border p-1">
                            <input
                              type="number"
                              value={calculo.cubicacion}
                              onChange={(e) => manejarCambioCalculosUrbanizacion(calculosUrbanizacion.findIndex(c => c.id === calculo.id), 'cubicacion', e.target.value)}
                              className="w-full bg-yellow-50 p-1 rounded text-xs"
                            />
                          </td>
                          <td className="border p-1">
                            <input
                              type="number"
                              value={calculo.costoUnitario}
                              onChange={(e) => manejarCambioCalculosUrbanizacion(calculosUrbanizacion.findIndex(c => c.id === calculo.id), 'costoUnitario', e.target.value)}
                              className="w-full bg-yellow-50 p-1 rounded text-xs"
                            />
                          </td>
                          <td className="border p-1">{calculo.costoTotal.toFixed(2)}</td>
                          <td className="border p-1">
                            <button onClick={() => eliminarFila(calculo.id)} className="bg-red-500 text-white p-1 rounded">
                              <Trash2 size={16} />
                            </button>
                          </td>
                        </tr>
                      ))}
                      <tr className="bg-gray-300 font-bold">
                        <td colSpan={5} className="border p-1 text-right">Suma Parcial:</td>
                        <td className="border p-1">{calcularSumaParcial(calculos).toFixed(2)}</td>
                        <td className="border p-1"></td>
                      </tr>
                    </React.Fragment>
                  ))}
                  <tr className="bg-blue-200 font-bold text-base">
                    <td colSpan={5} className="border p-1 text-right">SUMA TOTAL:</td>
                    <td className="border p-1">{calcularSumaTotal().toFixed(2)}</td>
                    <td className="border p-1"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
      </div>
  
      <div className="mb-4">
        <Card>
          <CardHeader className="py-2">
            <h2 className="text-lg font-semibold">Evaluación Técnico Comercial</h2>
          </CardHeader>
          <CardContent className="p-2">
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border-collapse text-xs">
                <thead>
                  <tr className="bg-blue-100">
                    <th className="border p-1">ID</th>
                    <th className="border p-1">CENTRO COSTO</th>
                    <th className="border p-1">UNIDAD</th>
                    {tipologias.map((tipologia, index) => (
                      <th key={index} className="border p-1">{tipologia.unidad}</th>
                    ))}
                    <th className="border p-1">TOTAL UF</th>
                  </tr>
                </thead>
                <tbody>
                  {/* TERRENO */}
                  <tr className="bg-blue-200">
                    <td className="border p-1">A</td>
                    <td className="border p-1 font-bold">TERRENO</td>
                    <td className="border p-1">UF</td>
                    {terreno.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(terreno.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* SUP. VIVIENDA BRUTA UNITARIA */}
                  <tr>
                    <td className="border p-1">A.1</td>
                    <td className="border p-1">SUP. VIVIENDA BRUTA UNITARIA</td>
                    <td className="border p-1">m2</td>
                    {supViviendaBrutaUnitaria.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(supViviendaBrutaUnitaria.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* COSTO CONSTRUCCIÓN */}
                  <tr className="bg-blue-200">
                    <td className="border p-1">B</td>
                    <td className="border p-1 font-bold">COSTO CONSTRUCCIÓN</td>
                    <td className="border p-1">UF</td>
                    {costoContruccion.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(costoContruccion.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* VIVIENDA */}
                  <tr>
                    <td className="border p-1">B.1</td>
                    <td className="border p-1">VIVIENDA</td>
                    <td className="border p-1">UF</td>
                    {vivienda.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(vivienda.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* URBANIZACIÓN */}
                  <tr>
                    <td className="border p-1">B.2</td>
                    <td className="border p-1">URBANIZACIÓN</td>
                    <td className="border p-1">{formatNumber(indiceUrbanizacion)}</td>
                    {urbanizacion.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(urbanizacion.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* ESTUDIOS */}
                  <tr className="bg-blue-200">
                    <td className="border p-1">C</td>
                    <td className="border p-1 font-bold">ESTUDIOS</td>
                    <td className="border p-1">UF/VIV</td>
                    {estudios.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(estudios.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* IMIV */}
                  <tr>
                    <td className="border p-1">C.1</td>
                    <td className="border p-1">IMIV</td>
                    <td className="border p-1">UF/VIV</td>
                    {imiv.map((valor, index) => (
                      <td key={index} className="border p-1">
                        <input
                          type="number"
                          value={valor}
                          onChange={(e) => {
                            const newImiv = [...imiv];
                            newImiv[index] = Number(e.target.value);
                            setImiv(newImiv);
                          }}
                          className="w-full bg-yellow-50 p-1 rounded text-xs"
                        />
                      </td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(imiv.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* DIA */}
                  <tr>
                    <td className="border p-1">C.2</td>
                    <td className="border p-1">DIA</td>
                    <td className="border p-1">UF/VIV</td>
                    {dia.map((valor, index) => (
                      <td key={index} className="border p-1">
                        <input
                          type="number"
                          value={valor}
                          onChange={(e) => {
                            const newDia = [...dia];
                            newDia[index] = Number(e.target.value);
                            setDia(newDia);
                          }}
                          className="w-full bg-yellow-50 p-1 rounded text-xs"
                        />
                      </td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(dia.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* ARQUEOLOGÍA */}
                  <tr>
                    <td className="border p-1">C.3</td>
                    <td className="border p-1">ARQUEOLOGÍA</td>
                    <td className="border p-1">UF/VIV</td>
                    {arqueologia.map((valor, index) => (
                      <td key={index} className="border p-1">
                        <input
                          type="number"
                          value={valor}
                          onChange={(e) => {
                            const newArqueologia = [...arqueologia];
                            newArqueologia[index] = Number(e.target.value);
                            setArqueologia(newArqueologia);
                          }}
                          className="w-full bg-yellow-50 p-1 rounded text-xs"
                        />
                      </td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(arqueologia.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* APORTE ESPACIO PÚBLICO */}
                  <tr className="bg-blue-200">
                    <td className="border p-1">D</td>
                    <td className="border p-1 font-bold">APORTE ESPACIO PÚBLICO</td>
                    <td className="border p-1">UF</td>
                    {aporteEspacioPublico.map((valor, index) => (
                      <td key={index} className="border p-1">
                        <input
                          type="number"
                          value={valor}
                          onChange={(e) => {
                            const newAporteEspacioPublico = [...aporteEspacioPublico];
                            newAporteEspacioPublico[index] = Number(e.target.value);
                            setAporteEspacioPublico(newAporteEspacioPublico);
                          }}
                          className="w-full bg-yellow-50 p-1 rounded text-xs"
                        />
                      </td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(aporteEspacioPublico.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* LEY DE DUCTOS */}
                  <tr className="bg-blue-200">
                    <td className="border p-1">E</td>
                    <td className="border p-1 font-bold">LEY DE DUCTOS</td>
                    <td className="border p-1">UF</td>
                    {leyDuctos.map((valor, index) => (
                      <td key={index} className="border p-1">
                        <input
                          type="number"
                          value={valor}
                          onChange={(e) => {
                            const newLeyDuctos = [...leyDuctos];
                            newLeyDuctos[index] = Number(e.target.value);
                            setLeyDuctos(newLeyDuctos);
                          }}
                          className="w-full bg-yellow-50 p-1 rounded text-xs"
                        />
                      </td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(leyDuctos.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* AFR */}
                  <tr className="bg-blue-200">
                    <td className="border p-1">F</td>
                    <td className="border p-1 font-bold">AFR</td>
                    <td className="border p-1">UF</td>
                    {afr.map((valor, index) => (
                      <td key={index} className="border p-1">
                        <input
                          type="number"
                          value={valor}
                          onChange={(e) => {
                            const newAfr = [...afr];
                            newAfr[index] = Number(e.target.value);
                            setAfr(newAfr);
                          }}
                          className="w-full bg-yellow-50 p-1 rounded text-xs"
                        />
                      </td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(afr.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                {/* FINANCIEROS Y OTROS */}
                <tr className="bg-blue-200">
                    <td className="border p-1">G</td>
                    <td className="border p-1 font-bold">FINANCIEROS Y OTROS</td>
                    <td className="border p-1">UF</td>
                    {financierosYOtros.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(financierosYOtros.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* Costo financiero */}
                  <tr>
                    <td className="border p-1">G.1</td>
                    <td className="border p-1">Costo financiero</td>
                    <td className="border p-1">
                      <input
                        type="number"
                        value={costoFinancieroPorcentaje}
                        onChange={(e) => setCostoFinancieroPorcentaje(Number(e.target.value))}
                        className="w-full bg-yellow-50 p-1 rounded text-xs"
                      />
                    </td>
                    {costoFinanciero.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(costoFinanciero.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* Comercial */}
                  <tr>
                    <td className="border p-1">G.2</td>
                    <td className="border p-1">Comercial</td>
                    <td className="border p-1">
                      <input
                        type="number"
                        value={comercialPorcentaje}
                        onChange={(e) => setComercialPorcentaje(Number(e.target.value))}
                        className="w-full bg-yellow-50 p-1 rounded text-xs"
                      />
                    </td>
                    {comercial.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(comercial.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* Marketing */}
                  <tr>
                    <td className="border p-1">G.3</td>
                    <td className="border p-1">Marketing</td>
                    <td className="border p-1">
                      <input
                        type="number"
                        value={marketingPorcentaje}
                        onChange={(e) => setMarketingPorcentaje(Number(e.target.value))}
                        className="w-full bg-yellow-50 p-1 rounded text-xs"
                      />
                    </td>
                    {marketing.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(marketing.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* Post Venta */}
                  <tr>
                    <td className="border p-1">G.4</td>
                    <td className="border p-1">Post Venta</td>
                    <td className="border p-1">
                      <input
                        type="number"
                        value={postVentaPorcentaje}
                        onChange={(e) => setPostVentaPorcentaje(Number(e.target.value))}
                        className="w-full bg-yellow-50 p-1 rounded text-xs"
                      />
                    </td>
                    {postVenta.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(postVenta.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* Imprevistos */}
                  <tr>
                    <td className="border p-1">G.5</td>
                    <td className="border p-1">Imprevistos</td>
                    <td className="border p-1">
                      <input
                        type="number"
                        value={imprevistos[0]}
                        onChange={(e) => {
                          const newValue = Number(e.target.value);
                          setImprevistos(Array(tipologias.length).fill(newValue));
                        }}
                        className="w-full bg-yellow-50 p-1 rounded text-xs"
                      />
                    </td>
                    {imprevistos.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(imprevistos.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* Reserva */}
                  <tr>
                    <td className="border p-1">G.6</td>
                    <td className="border p-1">Reserva</td>
                    <td className="border p-1">
                      <input
                        type="number"
                        value={reservaPorcentaje}
                        onChange={(e) => setReservaPorcentaje(Number(e.target.value))}
                        className="w-full bg-yellow-50 p-1 rounded text-xs"
                      />
                    </td>
                    {reserva.map((valor, index) => (
                      <td key={index} className="border p-1">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(reserva.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  
                    {/* GASTOS GENERALES */}
                  <tr className="bg-blue-200">
                    <td className="border p-1">H</td>
                    <td className="border p-1 font-bold">GASTOS GENERALES</td>
                    <td className="border p-1">%</td>
                    {gastosGenerales.map((valor, index) => (
                      <td key={index} className="border p-1">
                        <input
                          type="number"
                          value={valor}
                          onChange={(e) => {
                            const newGastosGenerales = [...gastosGenerales];
                            newGastosGenerales[index] = Number(e.target.value);
                            setGastosGenerales(newGastosGenerales);
                          }}
                          className="w-full bg-yellow-50 p-1 rounded text-xs"
                        />
                      </td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(gastosGenerales.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* UTILIDADES */}
                  <tr className="bg-blue-200">
                    <td className="border p-1">I</td>
                    <td className="border p-1 font-bold">UTILIDADES</td>
                    <td className="border p-1">%</td>
                    {utilidades.map((valor, index) => (
                      <td key={index} className="border p-1">
                        <input
                          type="number"
                          value={valor}
                          onChange={(e) => {
                            const newUtilidades = [...utilidades];
                            newUtilidades[index] = Number(e.target.value);
                            setUtilidades(newUtilidades);
                          }}
                          className="w-full bg-yellow-50 p-1 rounded text-xs"
                        />
                      </td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(utilidades.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                {/* COSTO ESTIMADO PRODUCCIÓN */}
                <tr className="bg-orange-200">
                    <td className="border p-1">A+B+C+D+E+F+G+H+I</td>
                    <td className="border p-1 font-bold">COSTO ESTIMADO PRODUCCIÓN</td>
                    <td className="border p-1">UF</td>
                    {costoEstimadoProduccion.map((valor, index) => (
                      <td key={index} className="border p-1 font-bold">{formatNumber(valor)}</td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(costoEstimadoProduccion.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* PRECIO VENTA */}
                  <tr className="bg-blue-200">
                    <td className="border p-1">J</td>
                    <td className="border p-1 font-bold">PRECIO VENTA</td>
                    <td className="border p-1">UF</td>
                    {precioVenta.map((valor, index) => (
                      <td key={index} className="border p-1">
                        <input
                          type="number"
                          value={valor}
                          onChange={(e) => {
                            const newPrecioVenta = [...precioVenta];
                            newPrecioVenta[index] = Number(e.target.value);
                            setPrecioVenta(newPrecioVenta);
                          }}
                          className="w-full bg-yellow-50 p-1 rounded text-xs"
                        />
                      </td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(precioVenta.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* DEFICIT */}
                  <tr className="bg-blue-200">
                    <td className="border p-1">K</td>
                    <td className="border p-1 font-bold">DEFICIT</td>
                    <td className="border p-1">UF</td>
                    {deficit.map((valor, index) => (
                      <td key={index} className={`border p-1 font-bold ${valor > 0 ? 'text-red-500' : (valor < 0 ? 'text-green-500' : '')}`}>
                        {formatNumber(valor)}
                      </td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(deficit.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                  {/* MARGEN */}
                  <tr className="bg-blue-200">
                    <td className="border p-1">L</td>
                    <td className="border p-1 font-bold">MARGEN</td>
                    <td className="border p-1">UF</td>
                    {margen.map((valor, index) => (
                      <td key={index} className={`border p-1 font-bold ${valor > 0 ? 'text-green-500' : (valor < 0 ? 'text-red-500' : '')}`}>
                        {formatNumber(valor)}
                      </td>
                    ))}
                    <td className="border p-1 font-bold">{formatNumber(margen.reduce((a, b) => a + b, 0))}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* VAN y TIR */}
            <div className="mt-6 grid grid-cols-2 gap-4">
              <Card>
                <CardHeader className="py-2">
                  <h3 className="text-md font-semibold">VAN (Valor Actual Neto)</h3>
                </CardHeader>
                <CardContent>
                  <input
                    type="number"
                    value={van}
                    onChange={(e) => setVan(Number(e.target.value))}
                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </CardContent>
              </Card>
              <Card>
                <CardHeader className="py-2">
                  <h3 className="text-md font-semibold">TIR (Tasa Interna de Retorno)</h3>
                </CardHeader>
                <CardContent>
                  <input
                    type="number"
                    value={tir}
                    onChange={(e) => setTir(Number(e.target.value))}
                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </CardContent>
              </Card>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default EvaluacionTecnicoComercial;