import React, { useState, useEffect, useRef, useMemo, useCallback, RefObject } from 'react';
import { motion, AnimatePresence, progress, Reorder } from 'framer-motion';
import { AlertCircle, AlertTriangle, Check, CheckCircle2, ChevronDown, ChevronRight, ChevronUp, CircleDot, Clock, FileQuestion, X, Building } from 'lucide-react';
import { createPortal } from 'react-dom';



interface SubtaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  subtask: SubTask;
  onChangeSubtask: (updatedSubtask: SubTask) => void;
  onAddNewSubtask: (option: NewSubtaskOption) => void;
  backgroundColor: string;
  mainTask: Task;
  groupProgress: number;
}
interface NewSubtaskOption {
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  name: string;
  resolutionType: string;
  responsable: string;
}


interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: Task;
  onChangeTask: (updatedTask: Task) => void; 
  backgroundColor: string;
  allTasks: Task[];
  agrupadorId: number;
  daysDelayed: number;
  groupProgress: number;
}






// Helper function to determine next subtask options
const getNextSubtaskOptions = (
  currentType: string,
  parentTask: Task,
): NewSubtaskOption[] => {
  const generateName = (type: 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN'): string => {
    if (type === 'RESOLUCIÓN') return 'RESOLUCIÓN';
    
    const existingSubtasks = parentTask.subtasks || [];
    const currentNumber = existingSubtasks.length + 1;
    
    return type === 'OBSERVACIÓN' ? `OBSERVACIÓN ${currentNumber}` : `RESPUESTA ${currentNumber}`;
  };

  switch (currentType) {
    case 'INGRESO':
      return [
        {
          type: 'OBSERVACIÓN',
          name: generateName('OBSERVACIÓN'),
          resolutionType: '',
          responsable: parentTask.organismo,
        },
        {
          type: 'RESOLUCIÓN',
          name: 'RESOLUCIÓN',
          resolutionType: '',
          responsable: parentTask.organismo,
        }
      ];

    case 'OBSERVACIÓN':
      return [
        {
          type: 'RESPUESTA',
          name: generateName('RESPUESTA'),
          resolutionType: '',
          responsable: parentTask.responsible,
        },
        {
          type: 'RESOLUCIÓN',
          name: 'RESOLUCIÓN',
          resolutionType: '',
          responsable: parentTask.organismo,
        }
      ];

    case 'RESPUESTA':
      return [
        {
          type: 'OBSERVACIÓN',
          name: generateName('OBSERVACIÓN'),
          resolutionType: '',
          responsable: parentTask.organismo,
        },
        {
          type: 'RESOLUCIÓN',
          name: 'RESOLUCIÓN',
          resolutionType: '',
          responsable: parentTask.organismo,
        }
      ];

    default:
      return [];
  }
};



  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };
  


  

  const SubtaskModal: React.FC<SubtaskModalProps> = ({ 
    isOpen, 
    onClose, 
    subtask, 
    onChangeSubtask, 
    onAddNewSubtask,
    backgroundColor,
    mainTask,
    groupProgress
  }) => {
    const [showComments, setShowComments] = useState(false);
    const [newComment, setNewComment] = useState('');
    const [showNewSubtaskDialog, setShowNewSubtaskDialog] = useState(false);
    const [selectedResolutionType, setSelectedResolutionType] = useState('');
    const [selectedNewSubtaskType, setSelectedNewSubtaskType] = useState<NewSubtaskOption | null>(null);
    const [isLoading, setIsLoading] = useState(false);
  
    // Función para actualizar la subtarea en la base de datos
    const updateSubtaskInDB = async (subtaskData: any) => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/subtask_operations.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'update',
            subtask: subtaskData,
            resolucion_tipo: subtaskData.resolucion_tipo || null // Ensure we always send the field
  
          })
        });
        console.log(JSON.stringify(subtaskData))
        if (!response.ok) {
          throw new Error('Error al actualizar la subtarea');
        }
  
        return await response.json();
      } catch (error) {
        console.error('Error en updateSubtaskInDB:', error);
        throw error;
      }
    };
  
    // Función para crear una nueva subtarea en la base de datos
    const createSubtaskInDB = async (subtaskData: any) => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/subtask_operations.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'create',
            subtask: subtaskData
            
          })
        });
        console.log(JSON.stringify(subtaskData))
  
        if (!response.ok) {
          throw new Error('Error al crear la subtarea');
        }
  
        return await response.json();
      } catch (error) {
        console.error('Error en createSubtaskInDB:', error);
        throw error;
      }
    };
  
    let subtaskCounter = 0; // Variable global o de estado para mantener el contador de subtareas
  
  
  // Función auxiliar para extraer el número de una observación o respuesta
  const extractNumber = (name: string): number => {
    const match = name.match(/\d+$/);
    return match ? parseInt(match[0]) : 0;
  };
  
  
  const getResolutionResponsible = (resolutionType: string | undefined, organismo: string, parentResponsible: string): string => {
    if (resolutionType === 'DESISTIMIENTO') {
      return parentResponsible;
    }
    return organismo;
  };
  
  const getNextSubtaskOptions = (
    currentType: string,
    parentTask: Task,
    currentSubtask: SubTask
  ): NewSubtaskOption[] => {
    // Helper function to generate correct name
    const generateName = (type: 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN'): string => {
      if (type === 'RESOLUCIÓN') return 'RESOLUCIÓN';
      
      const existingSubtasks = parentTask.subtasks || [];
      const currentNumber = extractNumber(currentSubtask.name);
      
      if (type === 'OBSERVACIÓN') {
        return `OBSERVACIÓN ${currentNumber + 1}`;
      } else {
        return `RESPUESTA ${currentNumber}`;
      }
    };
  
    // Define available options based on current type
    switch (currentType) {
      case 'INGRESO':
        return [
          {
            type: 'OBSERVACIÓN',
            name: `OBSERVACIÓN 1`,
            resolutionType: '',
            responsable: `${currentSubtask.organismo}`,
          },
          {
            type: 'RESOLUCIÓN',
            name: 'RESOLUCIÓN',
            resolutionType: '',
            // Para RESOLUCIÓN, el responsable se determinará después basado en el tipo de resolución
            responsable: `${currentSubtask.organismo}`, // Valor inicial, se actualizará cuando se seleccione el tipo
          }
        ];
  
      case 'OBSERVACIÓN':
        const observationNumber = extractNumber(currentSubtask.name);
        return [
          {
            type: 'RESPUESTA',
            name: `RESPUESTA ${observationNumber}`,
            resolutionType: '',
            responsable: `${parentTask.responsible}`,
          },
          {
            type: 'RESOLUCIÓN',
            name: 'RESOLUCIÓN',
            resolutionType: '',
            responsable: `${currentSubtask.organismo}`,
          }
        ];
  
      case 'RESPUESTA':
        const responseNumber = extractNumber(currentSubtask.name);
        return [
          {
            type: 'OBSERVACIÓN',
            name: `OBSERVACIÓN ${responseNumber + 1}`,
            responsable: `${currentSubtask.organismo}`,
            resolutionType: '' 
          },
          {
            type: 'RESOLUCIÓN',
            name: 'RESOLUCIÓN',
            resolutionType: '',
            responsable: `${currentSubtask.organismo}`,
          }
        ];
  
      default:
        return [];
    }
  };
  
    
  const nextOptions = getNextSubtaskOptions(subtask.type, mainTask, subtask);
  
  
    const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      const updatedSubtask = { ...subtask, [name]: value };
       
      try {
        const subtaskData = {
          id: updatedSubtask.id,
          tarea_id: mainTask.id,
          nombre: name === 'name' ? value : updatedSubtask.name,
          responsable: name === 'responsible' ? value : updatedSubtask.responsible,
          tipo: name === 'type' ? value : updatedSubtask.type,
          isClosed: name === 'isClosed' ? value : updatedSubtask.isClosed,
          // Incluir otros campos necesarios...
        };
  
        await updateSubtaskInDB(subtaskData);
        onChangeSubtask(updatedSubtask);
      } catch (error) {
        console.error('Error al actualizar campo:', error);
        alert('Error al actualizar el campo. Por favor, intente nuevamente.');
      }
    };
  
    const handleDateChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (subtask.isClosed) return;
  
      const { name, value } = e.target;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      const [year, month, day] = value.split('-').map(Number);
      const selectedDate = new Date(year, month - 1, day);
      selectedDate.setHours(0, 0, 0, 0);
  
      if (selectedDate < today) {
        alert('La fecha no puede ser anterior a hoy.');
        return;
      }
  
      try {
        const updatedSubtask = { ...subtask, [name]: value };
        const subtaskData = {
          id: subtask.id,
          tarea_id: mainTask.id,
          fecha_inicio: name === 'start' ? value : updatedSubtask.start,
          fecha_termino: name === 'end' ? value : updatedSubtask.end,
          resolucion_tipo: subtask.resolutionType
          // Incluir otros campos necesarios...
        };
  
        await updateSubtaskInDB(subtaskData);
        onChangeSubtask(updatedSubtask);
      } catch (error) {
        console.error('Error al actualizar fecha:', error);
        alert('Error al actualizar la fecha. Por favor, intente nuevamente.');
      }
    };
  
    const handleAddComment = async () => {
      if (!newComment.trim()) return;
  
      try {
        const timestamp = new Date().toLocaleString('es-CL', { 
          year: 'numeric', 
          month: '2-digit', 
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
        
        const commentWithTimestamp = `[${timestamp}] User: ${newComment}`;
        let updatedComments = [];
        
        if (subtask.comments) {
          try {
            updatedComments = JSON.parse(subtask.comments);
          } catch (error) {
            console.error('Error parsing existing comments:', error);
            updatedComments = [];
          }
        }
        
        updatedComments.push(commentWithTimestamp);
        const newCommentsString = JSON.stringify(updatedComments);
  
        const subtaskData = {
          id: subtask.id,
          tarea_id: mainTask.id,
          comments: newCommentsString
        };
  
        await updateSubtaskInDB(subtaskData);
        
        onChangeSubtask({ 
          ...subtask, 
          comments: newCommentsString
        });
        
        setNewComment('');
      } catch (error) {
        console.error('Error al agregar comentario:', error);
        alert('Error al agregar el comentario. Por favor, intente nuevamente.');
      }
    };
  
    const handleCloseSubtask = async () => {
      try {
        setIsLoading(true);
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        
        // Crear el objeto de datos actualizado
        const updatedSubtaskData = {
          ...subtask,
          tarea_id: mainTask.id,
          id: subtask.id,
          type: subtask.type,
          isClosed: true,
          progress: 100,
          fecha_termino: formattedDate
        };
  
        // Actualizar en la base de datos
        await updateSubtaskInDB(updatedSubtaskData);
        onChangeSubtask(updatedSubtaskData);
        //alert(JSON.stringify(updatedSubtaskData)      )
        // Solo mostrar el diálogo de nueva subtarea si NO es una RESOLUCIÓN
        if (subtask.type !== 'RESOLUCIÓN') {
          setShowNewSubtaskDialog(true);
     
        } else { 
          // Si es RESOLUCIÓN, cerrar el modal directamente
          setShowNewSubtaskDialog(false);
          onClose();
        }
    
      } catch (error) {
        console.error('Error al cerrar subtarea:', error);
        alert('Error al cerrar la subtarea. Por favor, intente nuevamente.');
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleNewSubtaskSelection = async (option: NewSubtaskOption) => {
      if (option.type === 'RESOLUCIÓN') {
        setSelectedNewSubtaskType(option);
      
      } else {
        try {
          setIsLoading(true);
          const today = new Date();
          const formattedDate = today.toISOString().split('T')[0];
         
          const newSubtaskData = {
            tarea_id: mainTask.id,
            nombre: option.name,
            tipo: option.type,
            responsable: option.responsable,
            organismo: subtask.organismo,
            progreso: 0,
            fecha_inicio: formattedDate,
            fecha_termino: formattedDate,
            duracion: 1,
            dependencias: JSON.stringify([{
              groupId: mainTask.id,
              taskId: mainTask.id,
              subtaskId: subtask.id
            }]),
            enabled: true,
            orden: subtask.orden + 1
          };
  
          const result = await createSubtaskInDB(newSubtaskData);
          if (result.success) {
            // Create new subtask with the correct type
            const newSubtask: SubTask = {
              id: result.id,
              name: option.name,
              type: option.type,
              responsible: option.responsable,
              progress: 0,
              start: formattedDate,
              end: formattedDate,
              duration: 1,
              organismo: subtask.organismo,
           
              enabled: true,
              orden: subtask.orden + 1,
              comments: '',
              followUpDate: '',
              followUp: false,
              semaphoreStatus: SemaphoreStatus.Green,
              isClosed: false,
              delayInDays: 0,
            };
  
            // Call onAddNewSubtask with the properly typed option
            onAddNewSubtask(option);
          }
        } catch (error) {
          console.error('Error al crear nueva subtarea:', error);
          alert('Error al crear la subtarea. Por favor, intente nuevamente.');
        } finally {
          setIsLoading(false);
          setShowNewSubtaskDialog(false);
          onClose();
        }
      }
    };
  
  
    
  
    const handleOpenSubtask = () => {
      onChangeSubtask({
        ...subtask,
        isClosed: true,
        progress: subtask.progress < 100 ? subtask.progress : 99
      });
    };
  
    const createNewSubtask = (option: NewSubtaskOption) => {
      // En lugar de crear directamente la subtarea aquí,
      // llamamos a la función proporcionada por el padre
      onAddNewSubtask(option);
      
      // Cerrar los diálogos
      setShowNewSubtaskDialog(false);
      setSelectedResolutionType('');
      setSelectedNewSubtaskType(null);
      onClose();
    };
  
  
    const handleResolutionTypeSelection = async (resolutionType: string) => {
      try {
        setIsLoading(true);
        if (selectedNewSubtaskType) {
          const today = new Date();
          const formattedDate = today.toISOString().split('T')[0];
    
          // Determine the responsible based on resolution type
          const responsible = getResolutionResponsible(
            resolutionType,
            subtask.organismo,
            mainTask.responsible
          );
    
          // Create new subtask data with resolution type
          const newSubtaskData = {
            tarea_id: mainTask.id,
            nombre: selectedNewSubtaskType.name,
            tipo: selectedNewSubtaskType.type,
            responsable: responsible, // Use the determined responsible
            organismo: subtask.organismo,
            progreso: 0,
            fecha_inicio: formattedDate,
            fecha_termino: formattedDate,
            duracion: 1,
            dependencias: JSON.stringify([{
              groupId: mainTask.id,
              taskId: mainTask.id,
              subtaskId: subtask.id
            }]),
            enabled: true,
            resolucion_tipo: resolutionType,
            orden: subtask.orden + 1
          };
    
          const result = await createSubtaskInDB(newSubtaskData);
          if (result.success) {
            const newSubtask: SubTask = {
              id: result.id,
              name: selectedNewSubtaskType.name,
              type: 'RESOLUCIÓN',
              responsible: responsible, // Use the determined responsible
              progress: 0,
              start: formattedDate,
              end: formattedDate,
              duration: 1,
              organismo: subtask.organismo,
              enabled: true,
              orden: subtask.orden + 1,
              comments: '',
              followUpDate: '',
              followUp: false,
              semaphoreStatus: SemaphoreStatus.Green,
              isClosed: false,
              delayInDays: 0,
              resolutionType: resolutionType as 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO' | undefined,
            };
    
            onAddNewSubtask({
              type: 'RESOLUCIÓN',
              name: selectedNewSubtaskType.name,
              resolutionType: resolutionType,
              responsable: responsible // Use the determined responsible
            });
          }
        }
      } catch (error) {
        console.error('Error al crear subtarea de resolución:', error);
        alert('Error al crear la subtarea. Por favor, intente nuevamente.');
      } finally {
        setIsLoading(false);
        setSelectedResolutionType('');
        setSelectedNewSubtaskType(null);
        setShowNewSubtaskDialog(false);
        onClose();
      }
    };
    const handleSaveChanges = async () => {
      try {
        setIsLoading(true);
        const subtaskData = {
          id: subtask.id,
          tarea_id: mainTask.id,
          nombre: subtask.name,
          tipo: subtask.type,
          responsable: subtask.responsible,
          organismo: subtask.organismo,
          progreso: subtask.progress,
          fecha_inicio: subtask.start,
          fecha_termino: subtask.end,
          duracion: subtask.duration,
          dependencias: JSON.stringify(subtask.dependsOn),
          enabled: true,
          resolucion_tipo: subtask.resolutionType,
          orden: subtask.orden,
          comments: subtask.comments,
          isClosed: subtask.isClosed,
          semaphoreStatus: subtask.semaphoreStatus,
        };
  
        await updateSubtaskInDB(subtaskData);
        onClose();
      } catch (error) {
        console.error('Error al guardar cambios:', error);
        alert('Error al guardar los cambios. Por favor, intente nuevamente.');
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <AnimatePresence>
        {isOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <motion.div
              className="bg-white rounded-lg w-full max-w-2xl"
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <div className="text-white p-4 flex justify-between items-center rounded-t-lg" 
                   style={{ backgroundColor }}>
                <h2 className="text-xl font-bold">{subtask.name}</h2>
                <button onClick={onClose} className="text-white hover:text-gray-200">
                  <X size={24} />
                </button>
              </div>
              <form className="p-6">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Nombre</label>
                    <input
                      type="text"
                      name="name"
                      value={subtask.name}
                      onChange={handleInputChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Responsable</label>
                    <input
                      type="text"
                      name="responsible"
                      value={subtask.responsible}
                      onChange={handleInputChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Fecha de Inicio</label>
                    <input
                      type="date"
                      name="start"
                      value={subtask.start}
                      onChange={handleDateChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Fecha de Término</label>
                    <input
                      type="date"
                      name="end"
                      value={subtask.end}
                      onChange={handleDateChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
             {/*    <label className="block text-sm font-medium text-gray-700">Fecha de Seguimiento</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="date"
                      name="followUpDate"
                      value={subtask.followUpDate}
                      onChange={handleDateChange}
                      min={new Date().toISOString().split('T')[0]}
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                      disabled={subtask.isClosed}
                    />
                    <button
                      type="button"
                      onClick={handleResetFollowUpDate}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                      disabled={subtask.isClosed}
                    >
                      <X className='text-red-500' />
                    </button>
                  </div>*/}
                </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Tipo</label>
                    <select
                      name="type"
                      value={subtask.type}
                      onChange={handleInputChange}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="INGRESO">INGRESO</option>
                      <option value="OBSERVACIÓN">OBSERVACIÓN</option>
                      <option value="RESPUESTA">RESPUESTA</option>
                      <option value="RESOLUCIÓN">RESOLUCIÓN</option>
                      <option value="OTROS">OTROS</option>
                    </select>
                  </div>
                  
                </div>
                
                {/* Comentarios (similar al TaskModal) */}
                <div className="mt-4">
                  <div className="flex items-center mb-2">
                    <div
                      className="flex items-center cursor-pointer text-indigo-600 hover:text-indigo-800"
                      onClick={() => setShowComments(!showComments)}
                    >
                      <label className="cursor-pointer block text-sm font-medium text-gray-700 mr-2">
                        Comentarios
                      </label>
                      {showComments ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </div>
                  </div>
                </div>
                <AnimatePresence>
                  {showComments && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className="overflow-hidden"
                    >
                   <div className="mt-1 border border-gray-300 rounded-md p-2 max-h-40 overflow-y-auto">
    {subtask.comments && (
      (() => {
        try {
          const parsedComments = JSON.parse(subtask.comments);
          return Array.isArray(parsedComments) && parsedComments.length > 0 ? (
            parsedComments.map((comment, index) => {
              const [datePart, userPart] = comment.split('] User:');
              const date = datePart.replace('[', '').trim();
              const content = userPart.trim();
              
              return (
                <div key={index} className="mb-2">
                  <p className="text-xs text-gray-500">{date}</p>
                  <p className="text-sm">{content}</p>
                </div>
              );
            })
          ) : (
            <p>No hay comentarios</p>
          );
        } catch (error) {
          console.error('Error parsing comments:', error);
          return <p>Error al cargar comentarios</p>;
        }
      })()
    )}
  </div>
  
                      <div className="mt-2 flex">
                        <input
                          type="text"
                          value={newComment}
                          onChange={(e) => setNewComment(e.target.value)}
                          className="flex-grow border focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-l-md"
                          placeholder="Agregar comentario..."
                        />
                        <button
                          type="button"
                          onClick={handleAddComment}
                          className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Agregar
                        </button>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
                <div className="mt-4 flex justify-between p-6">
    <button
      type="button"
      className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none  bg-red-600 hover:bg-red-700 focus:ring-2 focus:ring-offset-2 `}
      onClick={subtask.isClosed ? handleOpenSubtask : handleCloseSubtask}
     // disabled={!subtask.isClosed && subtask.semaphoreStatus === SemaphoreStatus.Gray}
    >
      {subtask.isClosed ? "ABRIR SUBTAREA" : "CERRAR SUBTAREA"}
    </button>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleSaveChanges}
                >
                  Guardar Cambios
                </button>
              </div>
              </form>
            </motion.div>
          </div>
        )}
  
  
  {selectedNewSubtaskType?.type === 'RESOLUCIÓN' && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[70]">
      <motion.div
        className="bg-white rounded-lg p-6 w-full max-w-md relative" // "relative" para el contenedor del modal
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {/* Botón de cerrar con z-index */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-300 hover:text-gray-400 z-10" // Añado z-10 aquí
        >
          <X size={24} />
        </button>
  
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Seleccionar tipo de resolución</h3>
        <div className="space-y-2">
          {resolutionTypes.map((type) => (
            <button
              key={type.value}
              onClick={() => handleResolutionTypeSelection(type.value)}
              className={`w-full flex items-center p-3 rounded-lg transition-all duration-200 ${type.bgColor} hover:opacity-90`}
            > 
              <div className={`mr-3 ${type.color}`}>{type.icon}</div>
              <span className={`font-medium ${type.color}`}>{type.label}</span>
            </button>
          ))}
        </div>
      </motion.div>
    </div>
  )}
  
  
         {/* Modal para seleccionar nueva subtarea */}
         <AnimatePresence>
          {showNewSubtaskDialog && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
              <motion.div
                className="bg-white rounded-lg p-6 w-full max-w-md"
                variants={modalVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <h3 className="text-lg font-medium mb-4">Seleccionar siguiente subtarea</h3>
                <div className="space-y-4">
                {nextOptions.map((option) => (
                  <button
                    key={option.type}
                    className="w-full px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700"
                    onClick={() => handleNewSubtaskSelection(option)}
                  >
                    {option.name}
                  </button>
                ))}
                  <button
                    className="w-full px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                    onClick={() => setShowNewSubtaskDialog(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </AnimatePresence>
    );
  };




const TaskModal: React.FC<TaskModalProps> = ({ 
  isOpen, 
  onClose, 
  task, 
  onChangeTask, 
  backgroundColor,
  allTasks,
  agrupadorId,
  daysDelayed,
  groupProgress
}) => {

  const [comments, setComments] = useState<string>(task.comments || '');

  const [newComment, setNewComment] = useState('');
  const [showComments, setShowComments] = useState(false);
  const [followUpDate, setFollowUpDate] = useState<string>(task.followUpDate || ''); // Estado para la fecha de seguimiento
  const [showReminderPicker, setShowReminderPicker] = useState(false);

  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    onChangeTask({ ...task, [name]: value });
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (task.isClosed) return;
  
    // Establecer fecha actual a medianoche en zona horaria local
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Convertir la fecha seleccionada a objeto Date en zona horaria local
    const [yearSelected, monthSelected, daySelected] = value.split('-').map(Number);
    const selectedDate = new Date(yearSelected, monthSelected - 1, daySelected);  // Ajuste del mes (0-11)
    selectedDate.setHours(0, 0, 0, 0);
  
    // Convertir la fecha de término de la tarea a objeto Date en zona horaria local
    const [yearEnd, monthEnd, dayEnd] = task.end.split('-').map(Number);
    const taskEndDate = new Date(yearEnd, monthEnd - 1, dayEnd);
    taskEndDate.setHours(0, 0, 0, 0);
  
    if (name === 'followUpDate') {
      // Validar que la fecha no sea anterior a hoy
      if (selectedDate < today) {
        alert('La fecha de seguimiento no puede ser anterior a hoy.');
        return;
      }
      
      // Validar que la fecha no sobrepase la fecha de término
      if (selectedDate > taskEndDate) {
        alert('La fecha de seguimiento no puede ser posterior a la fecha de término de la tarea.');
        return;
      }
    }
  
    onChangeTask({ ...task, [name]: value });
    onChangeTask({ ...task, [name]: value });

  };
  
  const handleAddComment = () => {
    if (newComment.trim()) {
      const timestamp = new Date().toLocaleString('es-CL', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
      const commentWithTimestamp = `[${timestamp}] User: ${newComment}`;
      
      let updatedComments = [];
      if (task.comments) {
        try {
          updatedComments = JSON.parse(task.comments);
        } catch (error) {
          console.error('Error parsing existing comments:', error);
          updatedComments = [];
        }
      }
      
      updatedComments.push(commentWithTimestamp);
      const newCommentsString = JSON.stringify(updatedComments);
      
      onChangeTask({ ...task, comments: newCommentsString });
      setNewComment('');
    }
  };



  const [updateTrigger, setUpdateTrigger] = useState(0);
/*
  const handleCloseTask = async () => {
    if (task.semaphoreStatus === SemaphoreStatus.Gray) {
      alert("No se puede cerrar una tarea que aún no ha iniciado.");
      return;
    }
  
    // Crear fecha actual en zona horaria local
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Formatear la fecha a YYYY-MM-DD manteniendo la zona horaria local
    const formattedDate = today.getFullYear() + '-' + 
      String(today.getMonth() + 1).padStart(2, '0') + '-' + 
      String(today.getDate()).padStart(2, '0');
  
    const updatedTask = { 
      ...task, 
      isClosed: true, 
      progress: 100, 
      end: formattedDate 
    };
    
    try {
      // Actualizar la tarea en la base de datos
      await updateTaskInDatabase(updatedTask);
      onChangeTask(updatedTask);
      setUpdateTrigger(prev => prev + 1); // Forzar re-renderizado
  
      // Calcular y actualizar el progreso del agrupador
      // await updateAgrupadorProgress(task.id);
      
      onChangeTask(updatedTask);
    } catch (error) {
      console.error('Error al cerrar la tarea:', error);
      alert('Hubo un error al cerrar la tarea. Por favor, inténtelo de nuevo.');
    }
  };
*/


useEffect(() => {
  const checkAndCloseMainTask = async () => {
    if (
      task.name === 'TRAMITACIÓN' && 
      task.subtasks && 
      task.subtasks.length > 0 && 
      !task.isClosed
    ) {
      const allSubtasksClosed = task.subtasks.every(subtask => subtask.isClosed);
      
      if (allSubtasksClosed) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const formattedDate = today.getFullYear() + '-' + 
          String(today.getMonth() + 1).padStart(2, '0') + '-' + 
          String(today.getDate()).padStart(2, '0');

        const updatedTask = { 
          ...task, 
          isClosed: true, 
          progress: 100, 
          end: formattedDate 
        };
        
        try {
          await updateTaskInDatabase(updatedTask);
          onChangeTask(updatedTask);
          setUpdateTrigger(prev => prev + 1);
        } catch (error) {
          console.error('Error al cerrar la tarea automáticamente:', error);
        }
      }
    }
  };

  checkAndCloseMainTask();
}, [task.subtasks]);



const canCloseTramitacionTask = (task: Task): boolean => {
  if (task.name !== 'TRAMITACIÓN') return true;
  if (!task.subtasks || task.subtasks.length === 0) return true;
  
  return task.subtasks.every(subtask => subtask.isClosed);
};

// Función para actualizar las fechas de las subtareas
const updateSubtasksDates = (subtasks: SubTask[]): SubTask[] => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  return subtasks.map(subtask => ({
    ...subtask,
    end: subtask.isClosed ? subtask.end : formattedDate // Solo actualizar fechas de subtareas abiertas
  }));
};




  const handleResetFollowUpDate = () => {
    onChangeTask({ ...task, followUpDate: '' });
  };


  const handleCloseTask = async () => {
    if (task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed) {
      alert("No se puede cerrar una tarea que aún no ha iniciado.");
      return;
    }

    if (task.nombre === 'TRAMITACIÓN' && task.subtasks) {
      const hasOpenSubtasks = task.subtasks.some(subtask => !subtask.isClosed);
      if (hasOpenSubtasks) {
        alert("No se puede cerrar la tarea de TRAMITACIÓN mientras tenga subtareas abiertas.");
        return;
      }
    }

    try {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];
  
      const updatedTask = { 
        ...task, 
        isClosed: true, 
        progreso: 100,
        fecha_termino: formattedDate,
      };
  
      // Update local state immediately
      onChangeTask(updatedTask);
      // Update the task reference
      Object.assign(task, updatedTask);
      
    } catch (error) {
      console.error('Error al cerrar la tarea:', error);
      alert('Hubo un error al cerrar la tarea. Por favor, inténtelo de nuevo.');
    }
  };

  const handleOpenTask = async () => {
    try {
      const startDate = new Date(task.fecha_inicio);
      const newEndDate = new Date(startDate.getTime() + task.duracion * 7 * 24 * 60 * 60 * 1000);

      const updatedTask = { 
        ...task, 
        isClosed: false,
        progreso: 0,
        fecha_termino: newEndDate.toISOString().split('T')[0]
      };

      // Update local state immediately
      onChangeTask(updatedTask);
      // Update the task reference
      Object.assign(task, updatedTask);

    } catch (error) {
      console.error('Error al abrir la tarea:', error);
      alert('Hubo un error al abrir la tarea. Por favor, inténtelo de nuevo.');
    }
  };
  const handleSaveChanges = async () => {
    try {
      // Create a complete update object with all necessary fields
      const updatedTask = {
        ...task,
        comments,
        followUpDate,
        id: task.id,
        isClosed: task.isClosed,
        progress: task.progreso,
        end: task.fecha_termino,
        start: task.fecha_inicio,
        followUp: task.followUp,
        semaphoreStatus: task.semaphoreStatus
      };

      await updateTaskInDatabase(updatedTask);
      onChangeTask(updatedTask);
      onClose();
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
      alert('Hubo un error al guardar los cambios. Por favor, inténtelo de nuevo.');
    }
  };

  const updateTaskInDatabase = async (updatedTask: Task) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: updatedTask.id,
          isClosed: updatedTask.isClosed,
          progress: updatedTask.progreso,
          end: updatedTask.fecha_termino,
          start: updatedTask.fecha_inicio,
          followUpDate: updatedTask.followUpDate,
          comments: updatedTask.comments,
          semaphoreStatus: updatedTask.semaphoreStatus
        }),
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      const result = await response.json();
      console.log('Tarea actualizada con éxito:', result.message);
    } catch (error) {
      console.error('Error al actualizar la tarea:', error);
      throw error;
    }
  };

  

  const formatFollowUpDate = (date: string): string => {
    if (!date) return 'No establecida';
    const followUpDate = new Date(date);
    const today = new Date();
    const diffTime = followUpDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return `Hace ${Math.abs(diffDays)} días`;
    } else if (diffDays === 0) {
      return 'Hoy';
    } else if (diffDays === 1) {
      return 'Mañana';
    } else {
      return `En ${diffDays} días`;
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <motion.div
            className="bg-white rounded-lg w-full max-w-2xl"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="text-white p-4 flex justify-between items-center rounded-t-lg" style={{ backgroundColor }}>
              <h2 className="text-xl font-bold">{task.nombre}</h2>
              <button onClick={onClose} className="text-white hover:text-gray-200">
                <X size={24} />
              </button>
            </div>
            <form className="p-6">
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Estado</label>
                {daysDelayed > 0 && !task.isClosed ? (
                  <p className="text-red-600 font-bold">
                    Retrasada por {daysDelayed} día(s)
                  </p>
                ) : (
                  <p className="text-green-600 font-bold">A tiempo</p>
                )}
              </div>
              <div className="grid grid-cols-2 gap-4">
             
                <div>
                  
                  <label className="block text-sm font-medium text-gray-700">Tarea</label>
                  <input
                    type="text"
                    name="description"
                    value={task.nombre}
                    onChange={handleInputChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    disabled={task.isClosed}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Responsable</label>
                  <input
                    type="text"
                    name="responsible"
                    value={task.responsable}
                    onChange={handleInputChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    disabled={task.isClosed}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha Esperada de Inicio</label>
                  <input
                    type="date"
                    name="start"
                    value={task.fecha_inicio}
                    onChange={handleDateChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    disabled={task.isClosed}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha Esperada de Término</label>
                  <input
                    type="date"
                    name="end"
                    value={task.fecha_termino}
                    onChange={handleDateChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    disabled={task.isClosed}
                  />
                </div>
                <div>
                {/* <label className="block text-sm font-medium text-gray-700">Fecha de Seguimiento</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="date"
                      name="followUpDate"
                      value={task.followUpDate}
                      onChange={handleInputChange}
                      min={new Date().toISOString().split('T')[0]}
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                      disabled={task.isClosed}
                    />*/}
                    <button
                      type="button"
                      onClick={handleResetFollowUpDate}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                      disabled={task.isClosed}
                    >
                     <X className='text-red-500'></X>
                    </button>
                  </div>
                </div>
              {/*   <span className="text-sm text-gray-500 mt-5">
                  {formatFollowUpDate(task.followUpDate)}
                </span>
             </div>*/}
              <div className="mt-4">
                <div className="flex items-center mb-2">
                  <div
                    className="flex items-center cursor-pointer text-indigo-600 hover:text-indigo-800"
                    onClick={() => setShowComments(!showComments)}
                  >
                    <label className="cursor-pointer block text-sm font-medium text-gray-700 mr-2">
                      Comentarios
                    </label>
                    {showComments ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </div>
                </div>
              </div>
              <AnimatePresence>
                {showComments && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden"
                  >
<div className="mt-1 border border-gray-300 rounded-md p-2 max-h-40 overflow-y-auto">
  {task.comments && (
    (() => {
      try {
        const parsedComments = JSON.parse(task.comments);
        return Array.isArray(parsedComments) && parsedComments.length > 0 ? (
          parsedComments.map((comment, index) => {
            const [datePart, userPart] = comment.split('] User:');
            const date = datePart.replace('[', '').trim();
            const content = userPart.trim();
            
            return (
              <div key={index} className="mb-2">
                <p className="text-xs text-gray-500">{date}</p>
                <p className="text-sm">{content}</p>
              </div>
            );
          })
        ) : (
          <p>No hay comentarios</p>
        );
      } catch (error) {
        console.error('Error parsing comments:', error);
        return <p>Error al cargar comentarios</p>;
      }
    })()
  )}
</div>

                    <div className="mt-2 flex">
                      <input
                        type="text"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        className="flex-grow border focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-l-md"
                        placeholder="Agregar comentario..."
                        disabled={task.isClosed}
                      />
                      <button
                        type="button"
                        onClick={handleAddComment}
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled={task.isClosed}
                      >
                        Agregar
                      </button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="mt-4 flex justify-between">
              <button
  type="button"
  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
    Boolean(task.isClosed)
      ? "bg-green-600 hover:bg-green-700 focus:ring-green-500"
      : task.semaphoreStatus !== SemaphoreStatus.Green || 
        (task.name === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0)
      ? "bg-gray-400 cursor-not-allowed"
      : "bg-red-600 hover:bg-red-700 focus:ring-red-500"
  }`}
  onClick={Boolean(task.isClosed) ? handleOpenTask : handleCloseTask}
  disabled={
    !task.isClosed && 
    (task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed || 
     (task.name === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0))
  }
>
  {task.isClosed ? "ABRIR TAREA" : "CERRAR TAREA"}
</button>
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleSaveChanges}
              >
                Guardar Cambios
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      )}
      
    </AnimatePresence>
    
  );
};





interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[];
  plantilla: string;
}

interface Subproceso {
  id: number;
  subproceso: string;
  color: string;
}

interface Task {
  id: number;
  nombre: string;
  responsable: string;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  duracion: number;
  organismo: string;
  enabled: boolean;
  semaphoreStatus: SemaphoreStatus; // New property for semaphore status
  isClosed: boolean;
}

interface Task {
  id: number;
  name: string;
  description: string;
  responsible: string;
  progress: number;
  start: string;
  organismo: string;
  duracion: number;
  end: string;
  followUp: boolean;
  followUpDate: string; // New property for follow-up date
  dependsOn: { groupId: number; taskId: number }[];
  enabled: boolean; // Nueva propiedad
  status: TaskStatus;
  dependencia: string | number | null;
  descriptor: 'GESTIÓN' | 'PERMISOLOGÍA';
  comments: string; // Nueva propiedad para los comentarios
  semaphoreStatus: SemaphoreStatus; // New property for semaphore status
  isClosed: boolean;
  delayInDays?: number;
  orden: number;
  subtasks?: SubTask[];
}

enum TaskStatus {
  None = "none",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
}


interface SubTask {
  id?: number;
  name: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  duration: number;
  organismo: string;
  dependsOn?: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean;
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO';
  orden: number;
  comments: string;
  followUpDate: string;
  followUp: boolean;
  semaphoreStatus: SemaphoreStatus;
  isClosed: boolean;
  delayInDays: number;
  reminder_type?: 'specific-date' | 'relative-time' | 'progress-based' | null;
  reminder_value?: string | null;
}


const resolutionTypes = [
  {
    value: 'APROBADO',
    label: 'Aprobado',
    icon: <Check className="h-5 w-5" />,
    color: 'text-green-700',
    bgColor: 'bg-green-100'
  },
  {
    value: 'RECHAZADO',
    label: 'Rechazado',
    icon: <X className="h-5 w-5" />,
    color: 'text-red-700',
    bgColor: 'bg-red-100'
  },
  {
    value: 'DESISTIMIENTO',
    label: 'Desistimiento',
    icon: <AlertTriangle className="h-5 w-5" />,
    color: 'text-orange-700',
    bgColor: 'bg-orange-100'
  },
  {
    value: 'SILENCIO ADMINISTRATIVO POSITIVO',
    label: 'Silencio Administrativo Positivo',
    icon: <Clock className="h-5 w-5" />,
    color: 'text-blue-700',
    bgColor: 'bg-blue-100'
  },
  {
    value: 'SILENCIO ADMINISTRATIVO NEGATIVO',
    label: 'Silencio Administrativo Negativo',
    icon: <Clock className="h-5 w-5" />,
    color: 'text-purple-700',
    bgColor: 'bg-purple-100'
  }
];



interface Agrupador {
  id: number;
  nombre: string;
  fecha_inicio: string;
  fecha_termino: string;
  progreso: number;
  subproceso: string;
  enabled: boolean;
  descriptor: string;
  organismo: string;
  tareas: Task[];
  orden: number;
}
interface TaskItemProps {
  task: Task;
  status: 'inProgress' | 'pending' | 'warning' | 'success' | 'danger';
  onChangeTask: (updatedTask: Task) => void;
  onTaskClick: (task: Task) => void; // Nueva prop para manejar el clic
  color: string; // Add color prop

}

const TaskItem: React.FC<TaskItemProps> = React.memo(({ 
  task, 
  status, 
  onChangeTask, 
  onTaskClick, 
  color 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeSubtask, setActiveSubtask] = useState<SubTask | null>(null);

  // Función auxiliar para convertir fechas de string a Date
  const getDateFromString = (dateStr: string): Date => {
    const [year, month, day] = dateStr.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const calculateSemaphoreStatus = (
    start: string, 
    end: string, 
    isClosed: boolean,
    isSubtask: boolean = false,
    parentTask?: Task
  ): SemaphoreStatus => {
    if (isClosed) {
      return SemaphoreStatus.Gray;
    }
  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    // Validar y convertir `start` a Date
    let startDate = null;
    if (start) {
      const [startYear, startMonth, startDay] = start.split('-').map(Number);
      startDate = new Date(startYear, startMonth - 1, startDay);
      startDate.setHours(0, 0, 0, 0);
    }
  
    // Validar y convertir `end` a Date
    let endDate = null;
    if (end) {
      const [endYear, endMonth, endDay] = end.split('-').map(Number);
      endDate = new Date(endYear, endMonth - 1, endDay);
      endDate.setHours(0, 0, 0, 0);
    }
  
    // Si alguna fecha es inválida, devuelve `Gray` como predeterminado
    if (!startDate || !endDate) {
      return SemaphoreStatus.Gray;
    }
  
    // Para subtareas dentro de tareas como 'TRAMITACIÓN'
    if (parentTask) {
      if (parentTask.name === 'TRAMITACIÓN') {
        if (parentTask.semaphoreStatus === SemaphoreStatus.Gray || startDate > today) {
          return SemaphoreStatus.Gray;
        }
        
        const daysUntilEnd = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
  
        if (today > endDate) return SemaphoreStatus.Red;
        if (daysUntilEnd <= 2) return SemaphoreStatus.Orange;
        if (daysUntilEnd <= 5) return SemaphoreStatus.Yellow;
  
        return SemaphoreStatus.Green;
      }
    }
  
    // Para tareas principales
    if (today > endDate) {
      return SemaphoreStatus.Red;
    }
    if (startDate <= today) {
      const daysUntilEnd = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
      if (daysUntilEnd <= 2) return SemaphoreStatus.Orange;
      if (daysUntilEnd <= 5) return SemaphoreStatus.Yellow;
  
      return SemaphoreStatus.Green;
    }
  
    return SemaphoreStatus.Gray;
  };

 

  const handleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0) {
      setIsExpanded(!isExpanded);
    }
  };

  // Procesar las subtareas con el estado del semáforo actualizado
  const processedSubtasks = useMemo(() => 
    task.subtasks?.map(subtask => ({
      ...subtask,
      semaphoreStatus: calculateSemaphoreStatus(
        subtask.start,
        subtask.end,
        subtask.isClosed,
        true,
        task
      )
    })) || [], [task, task.subtasks]
  );


  const getSemaphoreConfig = () => {
    if (task.isClosed) {
      return {
        bg: 'relative',
        border: 'border-gray-300',
        text: 'text-gray-800',
        icon: <CheckCircle2 className="w-4 h-4 text-gray-600" />,
        label: 'Completada',
        style: () => ({
          backgroundImage: `repeating-conic-gradient(${color} 0% 25%, white 0% 50%)`,
          backgroundSize: '10px 10px',
          backgroundClip: 'border-box',
          padding: '15px',
          overflow: 'hidden',
        }),
        closedTextBackground: 'bg-white',
      };
    }


    switch (task.semaphoreStatus) {
      case 'green':
        return {
          bg: 'bg-green-100 hover:bg-green-200',
          border: 'border-green-300',
          text: 'text-green-800',
          icon: <Clock className="w-4 h-4 text-green-600" />,
          label: 'En progreso',
        };
      case 'yellow':
        return {
          bg: 'bg-yellow-100 hover:bg-yellow-200',
          border: 'border-yellow-300',
          text: 'text-yellow-800',
          icon: <AlertTriangle className="w-4 h-4 text-yellow-600" />,
          label: 'Por vencer',
        };
      case 'orange':
        return {
          bg: 'bg-orange-100 hover:bg-orange-200',
          border: 'border-orange-300',
          text: 'text-orange-800',
          icon: <AlertTriangle className="w-4 h-4 text-orange-600" />,
          label: 'Próxima a vencer',
        };
      case 'red':
        return {
          bg: 'bg-red-100 hover:bg-red-200',
          border: 'border-red-300',
          text: 'text-red-800',
          icon: <AlertCircle className="w-4 h-4 text-red-600" />,
          label: 'Vencida',
        };
      case 'gray':
      default:
        return {
          bg: 'bg-gray-100 hover:bg-gray-200',
          border: 'border-gray-300',
          text: 'text-gray-800',
          icon: <CircleDot className="w-4 h-4 text-gray-600" />,
          label: 'Pendiente',
        };
    }
  };

  const getSubtaskSemaphoreConfig = (subtask: SubTask) => {
    if (subtask.isClosed) {
      return {
        bg: 'relative bg-white',
        border: 'border-gray-300',
        text: 'text-gray-800',
        icon: <CheckCircle2 className="w-4 h-4 text-gray-600" />,
        label: 'Completada',
        style: {
          backgroundImage: `repeating-conic-gradient(${color} 0% 25%, white 0% 50%)`,
          backgroundSize: '10px 10px',
          backgroundClip: 'border-box',
          padding: '15px',
          overflow: 'hidden',
        },
        closedTextBackground: 'bg-white',
      };
    }
    switch (subtask.semaphoreStatus) {
      case 'green':
        return {
          bg: 'bg-green-100 hover:bg-green-200',
          border: 'border-green-300',
          text: 'text-green-800',
          icon: <Clock className="w-4 h-4 text-green-600" />,
          label: 'En progreso',
        };
      case 'yellow':
        return {
          bg: 'bg-yellow-100 hover:bg-yellow-200',
          border: 'border-yellow-300',
          text: 'text-yellow-800',
          icon: <AlertTriangle className="w-4 h-4 text-yellow-600" />,
          label: 'Por vencer',
        };
      case 'orange':
        return {
          bg: 'bg-orange-100 hover:bg-orange-200',
          border: 'border-orange-300',
          text: 'text-orange-800',
          icon: <AlertTriangle className="w-4 h-4 text-orange-600" />,
          label: 'Próxima a vencer',
        };
      case 'red':
        return {
          bg: 'bg-red-100 hover:bg-red-200',
          border: 'border-red-300',
          text: 'text-red-800',
          icon: <AlertCircle className="w-4 h-4 text-red-600" />,
          label: 'Vencida',
        };
      case 'gray':
      default:
        return {
          bg: 'bg-gray-100 hover:bg-gray-200',
          border: 'border-gray-300',
          text: 'text-gray-800',
          icon: <CircleDot className="w-4 h-4 text-gray-600" />,
          label: 'Pendiente',
        };
    }
  };
  

  const config = getSemaphoreConfig();





  // Add handler for subtask click
  const handleSubtaskClick = (subtask: SubTask, e: React.MouseEvent) => {
    e.stopPropagation();
    setActiveSubtask(subtask);
  };

  // Add handler for subtask changes
const handleChangeSubtask = (updatedSubtask: SubTask) => {
  if (!task.subtasks) return;
  
  const updatedSubtasks = task.subtasks.map(st => 
    st.id === updatedSubtask.id ? updatedSubtask : st
  );

  const updatedTask = {
    ...task,
    subtasks: updatedSubtasks
  };

  onChangeTask(updatedTask);
};

  // Add handler for new subtasks
  const handleAddNewSubtask = async (option: NewSubtaskOption) => {
    if (!task.subtasks) return;

    try {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];
      
      const newSubtaskData = {
        tarea_id: task.id,
        nombre: option.name,
        tipo: option.type,
        responsable: option.responsable,
        organismo: task.organismo,
        progreso: 0,
        fecha_inicio: formattedDate,
        fecha_termino: formattedDate,
        duracion: 1,
        enabled: true,
        orden: task.subtasks.length + 1
      };

      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/subtask_operations.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'create',
          subtask: newSubtaskData
        })
      });

      if (!response.ok) throw new Error('Failed to create subtask');

      const result = await response.json();
      
      if (result.success) {
        const newSubtask: SubTask = {
          id: result.id,
          name: option.name,
          type: option.type,
          responsible: option.responsable,
          progress: 0,
          start: formattedDate,
          end: formattedDate,
          duration: 1,
          organismo: task.organismo,
          enabled: true,
          orden: task.subtasks.length + 1,
          comments: '',
          followUpDate: '',
          followUp: false,
          semaphoreStatus: SemaphoreStatus.Green,
          isClosed: false,
          delayInDays: 0,
          resolutionType: option.resolutionType as any
        };

        const updatedTask = {
          ...task,
          subtasks: [...task.subtasks, newSubtask]
        };

        onChangeTask(updatedTask);
      }
    } catch (error) {
      console.error('Error creating subtask:', error);
    }
  };


  const getResolutionTypeStyle = (resolutionType?: string) => {
    switch (resolutionType) {
      case 'APROBADO':
        return 'bg-green-100 text-green-800';
      case 'RECHAZADO':
        return 'bg-red-100 text-red-800';
      case 'DESISTIMIENTO':
        return 'bg-orange-100 text-orange-800';
      case 'SILENCIO ADMINISTRATIVO POSITIVO':
        return 'bg-blue-100 text-blue-800';
      case 'SILENCIO ADMINISTRATIVO NEGATIVO':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  


  return (
    <>
      <motion.div
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2 }}
        className={`p-4 mb-3 rounded-lg border ${config.bg} ${config.border} transition-all duration-200 cursor-pointer group`}
        onClick={(e) => {
          e.preventDefault();
          onTaskClick(task);
        }}
        style={task.isClosed && config.style ? config.style() : {}}
      >
        <div className={`flex items-center p-3 rounded-xl ${task.isClosed ? 'bg-white' : ''}`}>
        {task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0 && (
            <button 
              onClick={handleExpand} 
              className="mr-2 hover:bg-gray-200 rounded-full p-1 transition-colors"
            >
              {isExpanded ? 
                <ChevronDown size={30} className="text-gray-600" /> : 
                <ChevronRight size={30} className="text-gray-600" />
              }
            </button>
          )}
          <div className={`mr-2 ${config.text}`}>{config.icon}</div>
          <div className="flex-1">
            <div className={`font-medium ${config.text} text-sm`}>{task.nombre}</div>
            <div className="flex items-center justify-between mt-1">
              <span className={`text-xs ${config.text} opacity-75`}>{config.label}</span>
              <span className={`text-xs ${config.text} opacity-75`}>{task.progreso}%</span>
            </div>
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        {isExpanded && processedSubtasks && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="ml-8 space-y-2"
          >
{processedSubtasks.map((subtask, index) => {
  const subtaskConfig = getSubtaskSemaphoreConfig(subtask);

  return (
    <motion.div
      key={subtask.id || index}
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -20, opacity: 0 }}
      className={`p-3 rounded-lg border transition-all duration-300 cursor-pointer transform hover:scale-[1.02] hover:shadow-md`}
      style={{
        backgroundImage: `repeating-conic-gradient(${color} 0% 25%, white 0% 50%)`,
        backgroundSize: '10px 10px',
      }}
    >
      <div 
        className={`p-3 rounded-xl ${subtaskConfig.bg} ${subtaskConfig.border} ${subtaskConfig.text}`}
        style={{ backgroundColor: 'white' }}
        onClick={(e) => handleSubtaskClick(subtask, e)}
      >
        <div className="flex items-center px-2 space-x-2">
          <div className={`mr-2 ${subtaskConfig.text}`}>
            {subtaskConfig.icon}
          </div>
          <div className="flex items-center space-x-2">
            <span className={`font-medium ${subtaskConfig.text} text-sm`}>
              {subtask.name}
            </span>
            {subtask.resolutionType && (
              <span className={`px-1 py-0.5 rounded text-xs ${getResolutionTypeStyle(subtask.resolutionType)} whitespace-nowrap`}>
                {subtask.resolutionType}
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-between mt-1 px-5">
          <span className={`text-xs ${subtaskConfig.text} opacity-75`}>
            {subtaskConfig.label}
          </span>
          <span className={`text-xs ${subtaskConfig.text} opacity-75`}>
            {subtask.progress}%
          </span>
        </div>
      </div>
    </motion.div>
  );
})}


          </motion.div>
        )}
      </AnimatePresence>
      {activeSubtask && createPortal(
        <SubtaskModal
          isOpen={activeSubtask !== null}
          onClose={() => setActiveSubtask(null)}
          subtask={activeSubtask}
          onChangeSubtask={handleChangeSubtask}
          onAddNewSubtask={handleAddNewSubtask}
          backgroundColor={color}
          mainTask={task}
          groupProgress={task.progress}
        />,   document.body

      )}
    </>
    
  );
 
});


const getTaskStatus = (task: Task): 'inProgress' | 'pending' | 'warning' | 'success' | 'danger' => {
  if (task.isClosed) return 'success';
  if (task.progreso > 0) return 'inProgress';
  return 'pending';
};


const convertSemaphoreStatus = (status: SemaphoreStatus): 'inProgress' | 'pending' | 'warning' | 'success' | 'danger' => {
  switch (status) {
    case SemaphoreStatus.Green:
      return 'inProgress';
    case SemaphoreStatus.Yellow:
      return 'warning';
    case SemaphoreStatus.Orange:
      return 'warning';
    case SemaphoreStatus.Red:
      return 'danger';
    case SemaphoreStatus.Gray:
    default:
      return 'pending';
  }
};




interface TaskColumnProps {
  title: string;
  tasks: Task[];
  onChangeTask: (task: Task) => void;
  color: string;
  onTaskClick: (task: Task) => void; // Nueva prop

}





const EmptySubprocessMessage: React.FC<{ subprocessName: string }> = ({ subprocessName }) => {
  return (
    <div className="flex flex-col items-center justify-center p-10 bg-white rounded-lg">
      <FileQuestion size={60} className="text-teal-500 mb-4" />
      <h3 className="text-2xl font-bold text-teal-700 mb-2">Proceso sin contenido</h3>
      <p className="text-teal-600 text-center mb-6">
        Este proceso no contiene información, por favor revise la planificación.
      </p>
    </div>
  );
};




const PlanningPanel: React.FC<ProjectTimelineProps> = ({ 
  selectedProcess, 
  setSelectedProcess, 
  processes, 
  subprocessColor
}) => {
  const [selectedNombreProyecto, setSelectedNombreProyecto] = useState('');
  const [selectedCodigoProyecto, setSelectedCodigoProyecto] = useState('');
  const [subprocesos, setSubprocesos] = useState<Subproceso[]>([]);
  const [isProcessLoaded, setIsProcessLoaded] = useState(false);
  const [activeSubprocess, setActiveSubprocess] = useState<number>(0);
  const [allAgrupadores, setAllAgrupadores] = useState<Agrupador[]>([]);
  const [currentAgrupadores, setCurrentAgrupadores] = useState<Agrupador[]>([]);
  const [filteredSubprocesos, setFilteredSubprocesos] = useState<Subproceso[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const previousProcessRef = useRef<string | null>(null);
  const [draggedColumnId, setDraggedColumnId] = useState<string | null>(null);



  const calculateSemaphoreStatus = (
    start: string, 
    end: string, 
    taskId: number, 
    dependencia: string | number | null, 
    isClosed: boolean,
    allTasks?: Task[],
    parentTask?: Task
  ): SemaphoreStatus => {
    // Si la tarea está cerrada, retornar gris
    if (isClosed) {
      return SemaphoreStatus.Gray;
    }
  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    // Convertir fechas de string a Date
    const [startYear, startMonth, startDay] = start? (start.split('-').map(Number)): [0, 0 ,0];
    const startDate = new Date(startYear, startMonth - 1, startDay);
    startDate.setHours(0, 0, 0, 0);
  
    const [endYear, endMonth, endDay] = end? (end.split('-').map(Number)): [0, 0 ,0];
    const endDate = new Date(endYear, endMonth - 1, endDay);
    endDate.setHours(0, 0, 0, 0);
  
    // Para subtareas
    if (parentTask) {
      // Si es una subtarea de TRAMITACIÓN
      if (parentTask.name === 'TRAMITACIÓN') {
        // Si el padre está en gris o la fecha de inicio es futura, la subtarea está en gris
        if (parentTask.semaphoreStatus === SemaphoreStatus.Gray || startDate > today) {
          return SemaphoreStatus.Gray;
        }
        
        // Si el padre está activo (verde, amarillo o naranja)
        if (startDate <= today) {
          const daysUntilEnd = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
          
          if (today > endDate) {
            return SemaphoreStatus.Red; // Vencida
          } else if (daysUntilEnd <= 2) {
            return SemaphoreStatus.Orange; // Por vencer (2 días o menos)
          } else if (daysUntilEnd <= 5) {
            return SemaphoreStatus.Yellow; // Próxima a vencer (5 días o menos)
          }
          return SemaphoreStatus.Green; // En tiempo
        }
        return SemaphoreStatus.Gray; // No iniciada
      }
    }
  
    // Para tareas principales y otras subtareas
    if (today > endDate) {
      return SemaphoreStatus.Red; // Vencida
    }
  
    if (startDate <= today) {
      const daysUntilEnd = Math.ceil((endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
      
      if (daysUntilEnd <= 2) {
        return SemaphoreStatus.Orange; // Por vencer (2 días o menos)
      } else if (daysUntilEnd <= 5) {
        return SemaphoreStatus.Yellow; // Próxima a vencer (5 días o menos)
      }
      return SemaphoreStatus.Green; // En tiempo
    }
  
    // Si la fecha de inicio aún no llega
    return SemaphoreStatus.Gray;
  };
  const handleColumnDragStart = (agrupadorId: string) => {
    setDraggedColumnId(agrupadorId);
  };

  const handleColumnDragEnter = (targetAgrupadorId: string) => {
    if (draggedColumnId && draggedColumnId !== targetAgrupadorId) {
      const updatedAgrupadores = [...currentAgrupadores];
      const draggedIndex = updatedAgrupadores.findIndex(ag => ag.id.toString() === draggedColumnId);
      const targetIndex = updatedAgrupadores.findIndex(ag => ag.id.toString() === targetAgrupadorId);
      
      if (draggedIndex !== -1 && targetIndex !== -1) {
        // Intercambiar posiciones
        [updatedAgrupadores[draggedIndex], updatedAgrupadores[targetIndex]] = 
        [updatedAgrupadores[targetIndex], updatedAgrupadores[draggedIndex]];
        
        setCurrentAgrupadores(updatedAgrupadores);
      }
    }
  };

  const handleColumnDragEnd = () => {
    setDraggedColumnId(null);
  };

  const updateAgrupadorOrder = async (agrupadorId: number, newOrder: number) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_agrupador_order.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: agrupadorId,
          orden: newOrder
        }),
      });
      console.log(2)
      if (!response.ok) {
        throw new Error('Error al actualizar el orden');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error al actualizar el orden del agrupador:', error);
      throw error;
    }
  };
  
  const ColumnsContainer: React.FC<{
    agrupadores: Agrupador[];
    onReorder: (newOrder: Agrupador[]) => void;
    color: string;
    onTaskClick: (task: Task) => void;
    onChangeTask: (task: Task) => void;
  }> = ({ agrupadores, onReorder, color, onTaskClick, onChangeTask }) => {
    const [orderedAgrupadores, setOrderedAgrupadores] = useState(

      [...agrupadores].sort((a, b) => a.orden - b.orden)
    );
  
    const previousOrderRef = useRef<Agrupador[]>(orderedAgrupadores);

    
    // Función para obtener el color del semáforo
    const getSemaphoreColor = (status: SemaphoreStatus): string => {
      switch (status) {
        case SemaphoreStatus.Green:
          return 'bg-green-100 hover:bg-green-200 border-green-300 text-green-800';
        case SemaphoreStatus.Yellow:
          return 'bg-yellow-100 hover:bg-yellow-200 border-yellow-300 text-yellow-800';
        case SemaphoreStatus.Orange:
          return 'bg-orange-100 hover:bg-orange-200 border-orange-300 text-orange-800';
        case SemaphoreStatus.Red:
          return 'bg-red-100 hover:bg-red-200 border-red-300 text-red-800';
        case SemaphoreStatus.Gray:
        default:
          return 'bg-gray-100 hover:bg-gray-200 border-gray-300 text-gray-800';
      }
    };
  
    const updateOrdersInDatabase = async (updatedAgrupadores: Agrupador[]) => {
      try {
        // Preparar los datos para enviar en un solo request
        const orderUpdates = updatedAgrupadores.map((agrupador, index) => ({
          id: agrupador.id,
          orden: index + 1
        }));
  
        const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_agrupador_order.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ updates: orderUpdates })
        });
       
        if (!response.ok) {
          throw new Error('Failed to update orders');
        }
  
        const result = await response.json();
        console.log('Órdenes actualizados en BD:', orderUpdates);
        
      } catch (error) {
        console.error('Error al actualizar los órdenes:', error);
      }
    };
  
    // Handler para el reordenamiento visual mientras se arrastra
    const handleReorder = (reorderedItems: Agrupador[]) => {
      setOrderedAgrupadores(reorderedItems);
      
      // Comparar el orden actual con el anterior
      const hasOrderChanged = JSON.stringify(reorderedItems.map(a => a.id)) !== 
                            JSON.stringify(previousOrderRef.current.map(a => a.id));
  
      if (hasOrderChanged) {
        // Actualizar el orden en la base de datos solo si ha cambiado
        const updatedAgrupadores = reorderedItems.map((agrupador, index) => ({
          ...agrupador,
          orden: index + 1
        }));
  
        updateOrdersInDatabase(updatedAgrupadores);
        onReorder(updatedAgrupadores);
        previousOrderRef.current = reorderedItems;
      }
    };
  
    // Handler para cuando se suelta el elemento
    const handleDragEnd = () => {
      const updatedAgrupadores = orderedAgrupadores.map((agrupador, index) => ({
        ...agrupador,
        orden: index + 1
      }));
  console.log(updatedAgrupadores)
      // Actualizar estado local con los nuevos órdenes
      setOrderedAgrupadores(updatedAgrupadores);
      onReorder(updatedAgrupadores);
      
      // Actualizar la base de datos
      updateOrdersInDatabase(updatedAgrupadores);
    };

    const lightenColor = (color: string, amount: number): string => {
      return '#' + color.replace(/^#/, '').replace(/../g, color => 
        ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
      );
    };
  
    return (
      <Reorder.Group
      as="div"
      axis="x"
      values={orderedAgrupadores}
      onReorder={handleReorder}
      className="flex gap-4 p-4 overflow-x-auto"
      layoutScroll
      transition={{ duration: 0.3 }}
    >
      {orderedAgrupadores.map((agrupador) => (
        <Reorder.Item
          key={agrupador.id}
          value={agrupador}
          className="flex"
          layoutId={`agrupador-${agrupador.id}`}
          transition={{
            type: "tween",
            duration: 0.3
          }}
        >
            <motion.div
              className="w-80 p-4 shadow-xl rounded-xl border my-1"
              style={{
                backgroundColor: lightenColor(color,120),
                borderColor: color,
                borderWidth: '1px',
              }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 30
              }}
            >

<motion.div layout className="mb-4">
<h3 className="text-lg font-semibold mb-4 text-gray-700">
                {agrupador.nombre}
              </h3>
              <span className="text-sm text-gray-500 flex items-center gap-1">
      <Building size={14} /> {/* Puedes importar el ícono Building de lucide-react */}
      {agrupador.organismo}
    </span>
  </motion.div>
              <div className="space-y-3">
                {agrupador.tareas.map((task) => {
                  const semaphoreStatus = calculateSemaphoreStatus(
                    task.fecha_inicio,
                    task.fecha_termino,
                    task.id,
                    task.dependencia,
                    task.isClosed,
                    orderedAgrupadores.flatMap(ag => ag.tareas)
                  );
                  
                  return (
                    <TaskItem
                      key={task.id}
                      task={{
                        ...task,
                        semaphoreStatus
                      }}
                      status={convertSemaphoreStatus(semaphoreStatus)}
                      onChangeTask={onChangeTask}
                      onTaskClick={onTaskClick}
                      color={color}
                    />
                  );
                })}
              </div>
            </motion.div>
          </Reorder.Item>
        ))}
      </Reorder.Group>
    );
 
  };
  
  
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
  };


  // Agregar estados para el modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  const handleTaskChange = (updatedTask: Task) => {
    const newAgrupadores = currentAgrupadores.map(agrupador => ({
      ...agrupador,
      tareas: agrupador.tareas.map(tarea => 
        tarea.id === updatedTask.id ? updatedTask : tarea
      )
    }));
    
    setCurrentAgrupadores(newAgrupadores);
  };

  
  // Agregar manejadores para el modal
  const handleTaskClick = (task: Task) => {
    // Encontrar el agrupador que contiene la tarea
    const agrupador = currentAgrupadores.find(ag => 
      ag.tareas.some(t => t.id === task.id)
    );
    
    // Calcular el progreso del grupo
    const groupProgress = calculateAverageProgress(agrupador?.tareas || []);
    
    // Calcular días de retraso
    const daysDelayed = calculateDaysDelayed(task);

    // Obtener todas las tareas para las dependencias
    const allTasks = currentAgrupadores.flatMap(ag => ag.tareas);

    // Configurar la tarea seleccionada
    setSelectedTask(task);
    setIsModalOpen(true);
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTask(null);
  };



  // Función para cargar los datos del subproceso seleccionado
  const loadSubprocessData = useCallback((subprocess: Subproceso) => {
    if (!subprocess || !allAgrupadores) return;
    
    const agrupadoresForSubprocess = allAgrupadores.filter(
      agrupador => agrupador.subproceso === subprocess.subproceso
    );
    setCurrentAgrupadores(agrupadoresForSubprocess);
  }, [allAgrupadores]);

  // Efecto para inicializar los datos cuando se cargan los subprocesos
  useEffect(() => {
    if (!filteredSubprocesos || !allAgrupadores) return;
    if (filteredSubprocesos.length === 0 || allAgrupadores.length === 0) return;
  
    try {
      // Intentar recuperar el subproceso guardado
      const savedSubprocess = localStorage.getItem('selectedSubprocess');
      let targetIndex = 0;
      
      if (savedSubprocess) {
        const foundIndex = filteredSubprocesos.findIndex(sp => 
          sp && sp.subproceso && sp.subproceso === savedSubprocess
        );
        if (foundIndex !== -1) {
          targetIndex = foundIndex;
        }
      }
  
      // Validar que el subproceso objetivo existe
      const targetSubprocess = filteredSubprocesos[targetIndex];
      if (!targetSubprocess || !targetSubprocess.subproceso) {
        console.error('Subproceso objetivo no válido');
        return;
      }
  
      // Filtrar agrupadores válidos
      const agrupadoresForSubprocess = allAgrupadores.filter(
        agrupador => agrupador && agrupador.subproceso === targetSubprocess.subproceso
      );
  
      // Actualizar estados
      setActiveSubprocess(targetIndex);
      setCurrentAgrupadores(agrupadoresForSubprocess);
  
      // Guardar en localStorage solo si es válido
      if (targetSubprocess.subproceso) {
        localStorage.setItem('selectedSubprocess', targetSubprocess.subproceso);
      }
    } catch (error) {
      console.error('Error al inicializar subproceso:', error);
    }
  }, [filteredSubprocesos, allAgrupadores]);
  // Efecto para guardar el subproceso seleccionado


  useEffect(() => {
    if (selectedProcess) {
      loadProcessData(selectedProcess);
    }
  }, [selectedProcess]);

  const resetSubprocessState = () => {
    setActiveSubprocess(0);
    setCurrentAgrupadores([]);
    localStorage.removeItem('selectedSubprocessId');
  };


  const loadProcessData = async (processName: string) => {
    setIsLoading(true);
  
    if (processName !== previousProcessRef.current) {
      if (previousProcessRef.current !== null) {
        resetSubprocessState();
      }
    } else {
      setIsLoading(false);
      return;
    }
  
    if (processName === "") {
      resetState();
      setIsLoading(false);
      return;
    }
  
    const selectedProcess = processes.find(p => p.nombreProceso === processName);
    if (selectedProcess) {
      setSelectedNombreProyecto(selectedProcess.nombreProceso);
      setSelectedCodigoProyecto(selectedProcess.codigo);
      
      try {
        const response = await fetch(`http://localhost:3000/php/pages/proceso/get_process_data.php?codigo=${selectedProcess.codigo}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        // Filtrar solo los subprocesos con agrupadores habilitados
        const fetchedSubprocesos: Subproceso[] = data.subprocesos
          .filter((sp: any) => sp.agrupadores.some((agr: any) => agr.enabled))
          .map((sp: any) => ({
            id: sp.id,
            subproceso: sp.nombre,
            color: sp.color || getDefaultColor(sp.id)
          }));
        setSubprocesos(fetchedSubprocesos);
  
        // Procesar los agrupadores
        const allAgrupadores: Agrupador[] = data.subprocesos.flatMap((subproceso: any, spIndex: number) =>
          subproceso.agrupadores
            .filter((agrupador: any) => agrupador.enabled)
            .map((agrupador: any, agrIndex: number) => ({
              id: agrupador.id || (spIndex * 1000 + agrIndex),
              nombre: agrupador.nombre,
              fecha_inicio: formatDate(getEarliestStartDate(agrupador.tareas)),
              fecha_termino: formatDate(getLatestEndDate(agrupador.tareas)),
              progreso: calculateAverageProgress(agrupador.tareas),
              subproceso: subproceso.nombre,
              enabled: agrupador.enabled,
              descriptor: agrupador.descriptor,
              organismo: agrupador.organismo,
              tareas: agrupador.tareas.map((tarea: any) => ({
                ...tarea,
                nombre: tarea.nombre,
                responsable: tarea.responsable,
                progreso: tarea.progreso,
                fecha_inicio: tarea.fecha_inicio,
                fecha_termino: tarea.fecha_termino,
                duracion: tarea.duracion,
                organismo: tarea.organismo,
                enabled: tarea.enabled,
                isClosed: Boolean(tarea.isClosed),
                dependencia: tarea.dependencia,
                subtasks: tarea.subtasks ? tarea.subtasks.map((subtask: any) => ({
                  id: subtask.id,
                  name: subtask.nombre,
                  responsible: subtask.responsable,
                  progress: subtask.progreso,
                  start: subtask.fecha_inicio,
                  end: subtask.fecha_termino,
                  duration: subtask.duracion,
                  organismo: tarea.organismo,
                  enabled: subtask.enabled,
                  type: subtask.tipo,
                  resolutionType: subtask.resolucion_tipo,
                  orden: subtask.orden,
                  comments: subtask.comments || '',
                  followUpDate: subtask.followUpDate || '',
                  followUp: Boolean(subtask.followUp),
                  isClosed: Boolean(subtask.isClosed),
                  delayInDays: 0,
                  semaphoreStatus: SemaphoreStatus.Gray
                })) : []
              })),
              orden: agrupador.orden
            }))
        );
  
        console.log(JSON.stringify(allAgrupadores))
        setAllAgrupadores(allAgrupadores);
  
        // Filtrar los subprocesos que tienen agrupadores
        const filteredSubs = fetchedSubprocesos.filter(subproceso => 
          allAgrupadores.some(agrupador => agrupador.subproceso === subproceso.subproceso)
        );
        setFilteredSubprocesos(filteredSubs);
  
        // Ahora el useEffect se encargará de inicializar los datos correctamente
        setIsProcessLoaded(true);
  
      } catch (error) {
        console.error('Error al cargar el proceso:', error);
        resetState();
      }
    }
    previousProcessRef.current = processName;
    setIsLoading(false);
  };
  
  const getDefaultColor = (id: number): string => {
    const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
    return colors[id % colors.length];
  };

  const getEarliestStartDate = (tareas: Task[]): string => {
    return tareas.reduce((earliest, tarea) => 
      tarea.fecha_inicio < earliest ? tarea.fecha_inicio : earliest
    , tareas[0]?.fecha_inicio || '');
  };

  const getLatestEndDate = (tareas: Task[]): string => {
    return tareas.reduce((latest, tarea) => 
      tarea.fecha_termino > latest ? tarea.fecha_termino : latest
    , tareas[0]?.fecha_termino || '');
  };

  const calculateAverageProgress = (tareas: Task[]): number => {
    if (tareas.length === 0) return 0;
    const totalCompletedTasks = tareas.reduce((sum, tarea) => sum + (tarea.isClosed ? 1 : 0), 0);
    return Math.round((totalCompletedTasks / tareas.length) * 100);
  };

  const resetState = () => {
    setSelectedCodigoProyecto('');
    setSubprocesos([]);
    setFilteredSubprocesos([]);
    setAllAgrupadores([]);
    setCurrentAgrupadores([]);
    setIsProcessLoaded(false);
  };
  
  const calculateDaysDelayed = (task: Task): number => {
    const endDate = new Date(task.end);
    const today = new Date();
    return Math.max(0, Math.floor((today.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24)));
  };
  
  const calculateGroupProgress = (task: Task): number => {
    const agrupador = currentAgrupadores.find(a => a.tareas.some(t => t.id === task.id));
    return agrupador ? agrupador.progreso : 0;
  };
  
  const onChangeTask = (updatedTask: Task) => {
    // Aquí puedes actualizar el estado de la tarea o manejar otros cambios
    console.log("Tarea actualizada:", updatedTask);
  };
  
  const allTasks = currentAgrupadores.flatMap(agrupador => agrupador.tareas);

  const daysDelayed = selectedTask ? calculateDaysDelayed(selectedTask) : 0;

  const inputColor = filteredSubprocesos[activeSubprocess]?.color || subprocessColor;
  const agrupadorId = allAgrupadores.find(agrupador => 
    agrupador.tareas.some(t => t.id === selectedTask?.id)
  )?.id || 0; // Devuelve 0 si no se encuentra el agrupador
  
  const groupProgress = selectedTask ? calculateGroupProgress(selectedTask) : 0;


const handleSubprocessChange = (index: number) => {
  const selectedSubprocess = filteredSubprocesos[index];
  if (!selectedSubprocess) return;

  // Obtener agrupadores para el subproceso seleccionado
  const agrupadoresForSubprocess = allAgrupadores.filter(
    agrupador => agrupador.subproceso === selectedSubprocess.subproceso
  );

  // Actualizar estados
  setActiveSubprocess(index);
  setCurrentAgrupadores(agrupadoresForSubprocess);

  // Guardar en localStorage
  localStorage.setItem('selectedSubprocess', selectedSubprocess.subproceso);
};

  // Mantiene las columnas originales pero ahora puede usar los datos de la BD
  const getTaskStatus = (tarea: Task): 'inProgress' | 'pending' | 'warning' | 'success' | 'danger' => {
    if (tarea.isClosed) return 'success';
    if (tarea.progreso > 0) return 'inProgress';
    return 'pending';
  };

  const tableVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    },
    exit: { 
      opacity: 0, 
      y: -50, 
      transition: { 
        duration: 0.3,
        when: "afterChildren",
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    }
  };


  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="bg-white w-full rounded-lg p-3 mx-auto"
    >
      {isLoading ? (
        <div>Cargando...</div>
      ) : isProcessLoaded && filteredSubprocesos.length > 0 ? (
        <>
          <div className="flex mb-8 ml-8 mt-3">
            {filteredSubprocesos.map((subprocess, index) => (
              <SubprocessTab
                key={index}
                label={subprocess.subproceso}
                isActive={activeSubprocess === index}
                onClick={() => handleSubprocessChange(index)}
                color={subprocess.color}
                index={index}
                totalTabs={filteredSubprocesos.length}
                activeIndex={activeSubprocess}
              />
            ))}
          </div>
          
          {currentAgrupadores.length > 0 ? (
  <Reorder.Group
    as="div"
    axis="x"
    values={currentAgrupadores.map(ag => ag.id.toString())}
    onReorder={(newOrder) => {
      const reorderedAgrupadores = newOrder.map(id => 
        currentAgrupadores.find(ag => ag.id.toString() === id)!
      );
      setCurrentAgrupadores(reorderedAgrupadores);
    }}
  
    className="overflow-x-auto w-full"
  >
   <ColumnsContainer
      agrupadores={currentAgrupadores}
      onReorder={setCurrentAgrupadores}
      color={filteredSubprocesos[activeSubprocess]?.color || subprocessColor}
      onTaskClick={handleTaskClick}
      onChangeTask={handleTaskChange}
    />
  </Reorder.Group>
) : (
  <EmptySubprocessMessage subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso || ""} />
)}
        </>
      ) : (
        <EmptySubprocessMessage subprocessName={filteredSubprocesos[activeSubprocess]?.subproceso || ""} />
      )}
       {/* Agregar el modal */}
       {selectedTask && (
  <TaskModal 
  isOpen={isModalOpen}
  onClose={() => {
    setIsModalOpen(false);
    setSelectedTask(null);
  }}
  task={selectedTask}
  onChangeTask={handleTaskChange}

  backgroundColor={filteredSubprocesos[activeSubprocess]?.color || subprocessColor}
  allTasks={currentAgrupadores.flatMap(ag => ag.tareas)}
  agrupadorId={currentAgrupadores.findIndex(ag => 
    ag.tareas.some(t => t.id === selectedTask.id)
  )}
  daysDelayed={calculateDaysDelayed(selectedTask)}
  groupProgress={calculateAverageProgress(
    currentAgrupadores.find(ag => 
      ag.tareas.some(t => t.id === selectedTask.id)
    )?.tareas || []
  )}
    />
      )}
    </motion.div>
  );
};

enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray"
  
}
  
  interface SubprocessTabProps {
    label: string;
    isActive: boolean;
    onClick: () => void;
    color: string;
    index: number;
    totalTabs: number;
    activeIndex: number;
  }
  
  const SubprocessTab: React.FC<SubprocessTabProps> = ({ 
    label, 
    isActive, 
    onClick, 
    color, 
    index, 
    totalTabs
  }) => {
    const [scale, setScale] = useState(1);
    const activeScale = 1.185;
    const fixedOffset = 20;
  
    useEffect(() => {
      if (isActive) {
        setScale(activeScale);
      } else {
        setScale(1);
      }
    }, [isActive]);
  
    const buttonStyle: React.CSSProperties = {
      position: 'relative',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '40px',
      padding: '0 30px 0 30px',
      fontSize: '14px',
      fontWeight: 'medium',
      color: isActive ? '#ffffff' : '#115E59',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      overflow: 'visible',
      transition: 'all 0.3s',
      marginRight: `-${fixedOffset}px`,
      zIndex: isActive ? totalTabs : index,
      whiteSpace: 'nowrap',
    };
  
    const shapeStyle: React.CSSProperties = {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: color,
      opacity: isActive ? 1 : 0.3,
      transition: 'opacity 0.3s',
      clipPath: `polygon(0 0, calc(100% - ${fixedOffset}px) 0, 100% 50%, calc(100% - ${fixedOffset}px) 100%, 0 100%, ${fixedOffset}px 50%)`,
    };
  
    return (
      <motion.button
        onClick={onClick}
        className="relative"
        animate={{ 
          scale,
          zIndex: isActive ? totalTabs : index,
        }}
        whileHover={{ scale: scale * 1.02 }}
        whileTap={{ scale: scale * 0.98 }}
        transition={{ duration: 0.2 }}
        style={buttonStyle}
      >
        <div style={shapeStyle} />
        <span className="relative z-10 px-2">{label}</span>
      </motion.button>
    );
  };
  
  interface ProjectTimelineProps {
    selectedProcess: string;
    setSelectedProcess: React.Dispatch<React.SetStateAction<string>>;
    processes: Process[];
    subprocessColor: string;
  }
  
  export default PlanningPanel;