import React, { useState, useRef, ChangeEvent, FormEvent, useCallback, useEffect } from 'react';
import { PlusCircle, Save, MinusCircle, RotateCw, Trash, List, ArrowUp, ArrowDown, X } from 'lucide-react';

import Papa from 'papaparse';
import * as XLSX from 'xlsx';

interface Subproceso {
  id: number;
  nombre: string;
  color: string;
  orden: number; // Add orden property

}

interface User {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  departamento: string;
}

interface MultiUserAutocompleteProps {
  users: User[];
  selectedUsers: User[];
  onSelect: (users: User[]) => void;
  placeholder?: string;
}


const MultiUserAutocomplete: React.FC<MultiUserAutocompleteProps> = ({ 
  users, 
  selectedUsers, 
  onSelect, 
  placeholder 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredUsers(users.filter(user => 
        !selectedUsers.some(selected => selected.id === user.id)
      ));
    } else {
      const filtered = users.filter(user => 
        !selectedUsers.some(selected => selected.id === user.id) &&
        (`${user.firstname} ${user.lastname}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.departamento.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredUsers(filtered);
    }
  }, [searchTerm, users, selectedUsers]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleSelect = (user: User) => {
    const newSelectedUsers = [...selectedUsers, user];
    onSelect(newSelectedUsers);
    setSearchTerm('');
    setIsOpen(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleRemoveUser = (userId: number) => {
    const newSelectedUsers = selectedUsers.filter(user => user.id !== userId);
    onSelect(newSelectedUsers);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && searchTerm === '' && selectedUsers.length > 0) {
      handleRemoveUser(selectedUsers[selectedUsers.length - 1].id);
    }
  };

  return (
    <div className="relative" ref={wrapperRef}>
      <div className="p-2 border border-gray-300 rounded min-h-[42px] flex flex-wrap gap-2 bg-white">
        {selectedUsers.map((user) => (
          <div
            key={user.id}
            className="inline-flex items-center gap-1 bg-blue-100 text-blue-800 rounded px-2 py-1 text-sm"
          >
            <span>{`${user.firstname} ${user.lastname}`}</span>
            <button
              type="button"
              onClick={() => handleRemoveUser(user.id)}
              className="text-blue-600 hover:text-blue-800"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        ))}
        <input
          ref={inputRef}
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyDown}
          placeholder={selectedUsers.length === 0 ? placeholder : ''}
          className="flex-grow outline-none text-sm min-w-[120px]"
        />
      </div>

      {isOpen && filteredUsers.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {filteredUsers.map((user) => (
            <div
              key={user.id}
              onClick={() => handleSelect(user)}
              className="p-2 hover:bg-gray-100 cursor-pointer text-sm"
            >
              <div className="font-medium">{`${user.firstname} ${user.lastname}`}</div>
              <div className="text-xs text-gray-600">
                <span className="mr-2">{user.email}</span>
                <span className="text-gray-500">{user.departamento}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};





// Definición de los tipos
interface FormData {
  codigo: string;
  nombreProceso: string;
  anio: string;
  region: string;
  numeroRegion: string;
  comuna: string;
  numeroProceso: string;
  etapa: string;
  responsables_ids: number[]; // Asegúrate de que sea responsables_ids y no responsable_ids
  subprocesos: Subproceso[];
}


interface Region {
  region: string;
  numero: string;
  comunas: string[];
}


// Primero añadimos el componente LabelMessage al inicio del archivo
const LabelMessage: React.FC<{ type: 'success' | 'error'; message: string }> = ({ type, message }) => {
  const [progress, setProgress] = useState(100);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevProgress - 1;
      });
    }, 31);

    return () => clearInterval(timer);
  }, []);

  const bgColor = type === 'success' ? 'bg-green-500' : 'bg-red-500';
  const borderColor = type === 'success' ? 'border-green-600' : 'border-red-600';
  const textColor = 'text-white';

  return (
    <div className={`${bgColor} ${borderColor} ${textColor} px-4 py-3 rounded relative mb-4 border-l-4 overflow-hidden`} role="alert">
      <span className="block sm:inline">{message}</span>
      <div 
        className={`absolute bottom-0 left-0 h-1 ${type === 'success' ? 'bg-green-700' : 'bg-red-700'}`} 
        style={{ width: `${progress}%`, transition: 'width 0.1s linear' }}
      ></div>
    </div>
  );
};



const AdminModule = () => {
  const [formData, setFormData] = useState<FormData>({
    codigo: '',
    nombreProceso: '',
    anio: '',
    region: '',
    numeroRegion: '',
    comuna: '',
    numeroProceso: '',
    etapa: '',
    responsables_ids: [],
    subprocesos: [{ id: 1, nombre: '', color: '#000000', orden: 1 }],
    
  });

  const [deletedSubprocesos, setDeletedSubprocesos] = useState<Subproceso[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [message, setMessage] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const [regions, setRegions] = useState<Region[]>([]);
  const [loadingRegions, setLoadingRegions] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [selectedComuna, setSelectedComuna] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  
  const resetForm = () => {
    setFormData({
    codigo: '',
    nombreProceso: '',
    anio: '',
    region: '',
    numeroRegion: '',
    comuna: '',
    numeroProceso: '',
    etapa: '',
    responsables_ids: [], // Cambiado de responsable_id a responsables_ids
    subprocesos: [{ id: 1, nombre: '', color: '#000000', orden: 1 }],
  });
  setSelectedComuna('');
  setSelectedRegion('');
  setDeletedSubprocesos([]); // Si estás rastreando subprocesos eliminados
};


  // Add function to fetch users
  const fetchUsers = async () => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/users/get_users.php');
      if (!response.ok) throw new Error('Error fetching users');
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      showLabelMessage('error', 'Error al cargar la lista de usuarios');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

const showLabelMessage = (type: 'success' | 'error', message: string, duration = 3500) => {
  setMessage({ type, message });
  setTimeout(() => {
    setMessage(null);
  }, duration);
};


  const decimalToRoman = (num: number): string => {
    const romanNumerals = [
      { value: 1000, symbol: 'M' },
      { value: 900, symbol: 'CM' },
      { value: 500, symbol: 'D' },
      { value: 400, symbol: 'CD' },
      { value: 100, symbol: 'C' },
      { value: 90, symbol: 'XC' },
      { value: 50, symbol: 'L' },
      { value: 40, symbol: 'XL' },
      { value: 10, symbol: 'X' },
      { value: 9, symbol: 'IX' },
      { value: 5, symbol: 'V' },
      { value: 4, symbol: 'IV' },
      { value: 1, symbol: 'I' }
    ];

    let roman = '';
    for (const { value, symbol } of romanNumerals) {
      while (num >= value) {
        roman += symbol;
        num -= value;
      }
    }
    return roman;
  };

  

  const generateCodigo = useCallback((data: FormData): string => {
    const { nombreProceso, anio, numeroRegion, numeroProceso, etapa } = data;
    const nombreProcesoAbreviada = nombreProceso ? nombreProceso.substring(0, 3).toUpperCase() : '';
    const anioAbreviado = anio ? anio.substring(2) : '';
    const etapaRoman = decimalToRoman(parseInt(etapa, 10) || 0);
    return `${anioAbreviado}${numeroRegion}${numeroProceso}.${etapaRoman}`;
  }, []);

  // Función para obtener el número de proceso desde el backend
  const fetchNumeroProceso = useCallback(async () => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/proceso/consultarnoproceso.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          region: formData.region,
          anio: formData.anio,
        }),
      });
  
      const result = await response.json();
      if (result.success) {
        setFormData(prevData => {
          const updatedData = {
            ...prevData,
            numeroProceso: "0" + result.numeroProceso, // Asignar el número correlativo
          };
          // Actualiza el código después de establecer el número de proceso
          updatedData.codigo = generateCodigo(updatedData);
          return updatedData;
        });
      } else {
        console.error('Error fetching numeroProceso:', result.error);
      }
    } catch (error) {
      console.error('Error fetching numeroProceso:', error);
    }
  }, [formData.region, formData.anio, generateCodigo]);
  


  
  useEffect(() => {
    if (formData.region && formData.anio) {
      fetchNumeroProceso();
    }
  }, [formData.region, formData.anio, fetchNumeroProceso]);
  
  
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await import('../../data/regiones.json');
        const data = response.default as { regiones: Region[] };
        setRegions(data.regiones);
        setLoadingRegions(false);
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };

    fetchRegions();
  }, []);


  useEffect(() => {
    // Actualiza el formulario si cambia la comuna seleccionada
    const region = regions.find(region => region.comunas.includes(selectedComuna));
    if (region) {
      setFormData(prevData => ({
        ...prevData,
        comuna: selectedComuna,
        region: region.region,
        numeroRegion: region.numero
      }));
    }
  }, [selectedComuna, regions]);
  
  useEffect(() => {
    // Actualiza la comuna seleccionada si cambia la región
    if (selectedRegion) {
      const region = regions.find(r => r.numero === selectedRegion);
      if (region) {
        setFormData(prevData => ({
          ...prevData,
          region: region.region,
          numeroRegion: region.numero
        }));
      }
    }
  }, [selectedRegion, regions]);

  const handleComunaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const comuna = event.target.value;
    setSelectedComuna(comuna);

    // Encuentra la región correspondiente a la comuna seleccionada
    const region = regions.find(region => region.comunas.includes(comuna));
    if (region) {
        setSelectedRegion(region.numero); // Asigna el número de la región
        setFormData(prevData => {
            const updatedData = {
                ...prevData,
                comuna: comuna,
                region: region.region,
                numeroRegion: region.numero // Actualiza el número de la región
            };
            if (updatedData.nombreProceso || updatedData.anio || updatedData.numeroRegion || updatedData.numeroProceso || updatedData.etapa) {
                updatedData.codigo = generateCodigo(updatedData);
            }
            if (updatedData.region && updatedData.anio) {
              fetchNumeroProceso();
            }
            return updatedData;
        });
    } else {
        // Si la comuna no está en la lista, limpia la región y número de región
        setSelectedRegion('');
        setFormData(prevData => {
            const updatedData = {
                ...prevData,
                comuna: comuna,
                region: '',
                numeroRegion: ''
            };
            if (updatedData.nombreProceso || updatedData.anio || updatedData.numeroRegion || updatedData.numeroProceso || updatedData.etapa) {
                updatedData.codigo = generateCodigo(updatedData);
            }
            return updatedData;
        });
    }
};




  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const regionNumero = event.target.value;
    setSelectedRegion(regionNumero);
  
    const region = regions.find(r => r.numero === regionNumero);
    if (region) {
      setFormData(prevData => ({
        ...prevData,
        region: region.region,
        numeroRegion: region.numero
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        region: '',
        numeroRegion: ''
      }));
    }
  };
 const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  const { name, value } = e.target;

  setFormData(prevData => {
    const updatedData = { ...prevData, [name]: value };

    console.log("Datos actualizados:", updatedData); // Verifica los datos actualizados

    // Genera el número de proceso solo si la región y el año están presentes
    if (name === 'region' || name === 'anio') {
      if (updatedData.region && updatedData.anio) {
        fetchNumeroProceso(); // Asegúrate de definir esta función para obtener el número de proceso
      }
    }

    // Actualiza el código cuando cambian ciertos campos
    if (name === 'nombreProceso' || name === 'anio' || name === 'numeroRegion' || name === 'numeroProceso' || name === 'etapa') {
      updatedData.codigo = generateCodigo(updatedData);
    }

    return updatedData;
  });
}, [fetchNumeroProceso, generateCodigo]);

  
const handleAddSubproceso = () => {
  setFormData(prevData => ({
    ...prevData,
    subprocesos: [
      ...prevData.subprocesos,
      { 
        id: prevData.subprocesos.length + 1, 
        nombre: '', 
        color: '#000000', 
        orden: prevData.subprocesos.length + 1 // Set orden to the new length
      }
    ],
  }));
};

const handleRemoveSubproceso = (id: number) => {
  setFormData(prevData => {
    const subprocesoToRemove = prevData.subprocesos.find(sp => sp.id === id);
    if (subprocesoToRemove) {
      setDeletedSubprocesos(prev => [...prev, subprocesoToRemove]);
    }
    return {
      ...prevData,
      subprocesos: prevData.subprocesos.filter(sp => sp.id !== id),
    };
  });
};

const handleUsersSelect = (users: User[]) => {
  setSelectedUsers(users);
  setFormData(prev => ({
    ...prev,
    responsables_ids: users.map(user => user.id)
  }));
};

const handleSubprocesoChange = (id: number, field: 'nombre' | 'color', value: string) => {
  setFormData(prevData => ({
    ...prevData,
    subprocesos: prevData.subprocesos.map(sp =>
      sp.id === id ? { ...sp, [field]: value } : sp
    ),
  }));
};

const handleUndoDelete = useCallback(() => {
  if (deletedSubprocesos.length > 0) {
    const lastDeleted = deletedSubprocesos[deletedSubprocesos.length - 1];
    setFormData(prevData => ({
      ...prevData,
      subprocesos: [...prevData.subprocesos, lastDeleted],
    }));
    setDeletedSubprocesos(prev => prev.slice(0, -1));
  }
}, [deletedSubprocesos]);


 
  const handleTemplate1 = useCallback(() => {
    setFormData(prevData => ({
      ...prevData,
      subprocesos: [
        { id: 1, nombre: 'Terreno', color: '#FF6B6B', orden: 1 },
        { id: 2, nombre: 'Permiso de Edificación', color: '#4ECDC4', orden: 2 },
        { id: 3, nombre: 'Proyectos Ingeniería', color: '#45B7D1', orden: 3 },
        { id: 4, nombre: 'Licitación', color: '#FFA07A', orden: 4 },
        { id: 5, nombre: 'Contrato', color: '#98D8C8', orden: 5 },
        { id: 6, nombre: 'Obra', color: '#F7DC6F', orden: 6 },
        { id: 7, nombre: 'Post Venta', color: '#C39BD3', orden: 7 }
      ]
    }));
  }, []);


  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (!formData.nombreProceso || !formData.anio || !formData.region || !formData.numeroRegion || !formData.numeroProceso || !formData.etapa) {
      showLabelMessage('error', 'Por favor, complete todos los campos requeridos.');
      return;
    }
    
    const dataToSend = {
      ...formData,
      responsables_ids: selectedUsers.map(user => user.id), // Asegúrate de incluir esto
      subprocesos: formData.subprocesos
        .filter(sp => sp.nombre.trim() !== '')
        .map((sp, index) => ({
          ...sp,
          orden: index + 1
        }))
    };
    
    console.log("Datos que se envían:", dataToSend);
  
    const endpoint = 'http://localhost:3000/php/pages/proceso/crearproceso.php';
  
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        showLabelMessage('success', 'Datos guardados exitosamente');
        resetForm();
        setSelectedUsers([]); // Limpiar usuarios seleccionados
      } else {
        showLabelMessage('error', `Hubo un error al guardar los datos: ${data.error}`);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      showLabelMessage('error', 'Hubo un error al guardar los datos');
    });
}, [formData, selectedUsers, resetForm]);


  const handleMoveSubproceso = (index: number, direction: 'up' | 'down') => {
    setFormData(prevData => {
      const newSubprocesos = [...prevData.subprocesos];
      if (direction === 'up' && index > 0) {
        [newSubprocesos[index - 1], newSubprocesos[index]] = [newSubprocesos[index], newSubprocesos[index - 1]];
        // Update orden
        newSubprocesos[index - 1].orden--;
        newSubprocesos[index].orden++;
      } else if (direction === 'down' && index < newSubprocesos.length - 1) {
        [newSubprocesos[index], newSubprocesos[index + 1]] = [newSubprocesos[index + 1], newSubprocesos[index]];
        // Update orden
        newSubprocesos[index].orden++;
        newSubprocesos[index + 1].orden--;
      }
      return { ...prevData, subprocesos: newSubprocesos };
    });
  };

  const renderSubprocesoFields = () => {
    return formData.subprocesos.map((subproceso, index) => (
      <div key={subproceso.id} className="flex items-center mb-2">
           <div className="flex ml-2">
           <div className="flex space-x-2">
  <button 
    type="button" 
    onClick={() => handleMoveSubproceso(index, 'up')}
    disabled={index === 0}
    className={`flex items-center justify-center w-10 h-10 border border-gray-300 rounded-md bg-white text-gray-500 hover:text-gray-700 transition-colors duration-200 ${index === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    <ArrowUp className="w-5 h-5" />
  </button>
  <button 
    type="button" 
    onClick={() => handleMoveSubproceso(index, 'down')}
    disabled={index === formData.subprocesos.length - 1}
    className={`flex items-center justify-center w-10 h-10 border border-gray-300 rounded-md bg-white text-gray-500 hover:text-gray-700 transition-colors duration-200 ${index === formData.subprocesos.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    <ArrowDown className="w-5 h-5" />
  </button>
</div>

        </div>
        <div className="relative ml-5">
          <input
            type="color"
            value={subproceso.color}
            onChange={e => handleSubprocesoChange(subproceso.id, 'color', e.target.value)}
            className="absolute top-0 left-0 opacity-0 w-6 h-6 cursor-pointer"
          />
          <div 
            className="w-6 h-6 rounded-full mr-2 border border-gray-300 cursor-pointer" 
            style={{ backgroundColor: subproceso.color }}
          ></div>
        </div>
        <input
          type="text"
          value={subproceso.nombre}
          onChange={e => handleSubprocesoChange(subproceso.id, 'nombre', e.target.value)}
          className="flex-grow p-2 border border-gray-300 rounded text-sm"
          placeholder="Nombre del subproceso"
        />
     
        <button 
          type="button" 
          onClick={() => handleRemoveSubproceso(subproceso.id)} 
          className="ml-2 text-red-500 hover:text-red-700 transition-colors duration-200"
        >
          <MinusCircle className="w-5 h-5" />
        </button>
      </div>
    ));
  };



  return (
    <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-md">

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 mb-6">
          <div className="flex flex-col">
            <label htmlFor="codigo" className="text-sm text-lg font-semibold mb-2">Código</label>
            <input
              id="codigo"
              name="codigo"
              type="text"
              value={formData.codigo}
              readOnly
              className="p-2 border text-sm border-gray-300 rounded bg-gray-100 cursor-not-allowed"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="nombreProceso" className="text-sm font-semibold mb-2">Nombre del proceso</label>
            <input
              id="nombreProceso"
              name="nombreProceso"
              type="text"
              value={formData.nombreProceso}
              onChange={handleInputChange}
              className="p-2 text-sm border border-gray-300 rounded"
            />
          </div>

          <div className="flex flex-col ">
            <label htmlFor="anio" className="text-sm font-semibold mb-2">Año</label>
            <input
              id="anio"
              name="anio"
              type="text"
              value={formData.anio}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="comuna" className="text-sm font-semibold mb-2">Comuna</label>
            <select
              value={selectedComuna}
              onChange={handleComunaChange}
              className='p-2  border  border-gray-300 rounded'
            >
              <option value="">Seleccione una comuna</option>
              {regions.flatMap(region => region.comunas).map((comuna, index) => (
                <option key={index} value={comuna}>{comuna}</option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label htmlFor="region" className="text-sm font-semibold mb-2">Región</label>
            <select
              value={selectedRegion}
              onChange={handleRegionChange}
              className='p-2 border border-gray-300 bg-gray-100 rounded  cursor-not-allowed'
              disabled
            >
              <option value="" disabled>Región</option>
              {regions.map((region) => (
                <option key={region.numero} value={region.numero}>{region.region}</option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label htmlFor="numeroProceso" className="text-sm font-semibold mb-2">Número de proceso</label>
            <input
              id="numeroProceso"
              name="numeroProceso"
              type="text"
              value={formData.numeroProceso}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded bg-gray-100  cursor-not-allowed"
              disabled
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="etapa" className="text-sm font-semibold mb-2">Etapa</label>
            <input
              id="etapa"
              name="etapa"
              min={1}
              type="number"
              value={formData.etapa}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded"
            />
      </div>
    {/*  <div className="flex flex-col">
          <label className="text-sm font-semibold mb-2">
            Responsable
          </label>
          <MultiUserAutocomplete
            users={users}
            selectedUsers={selectedUsers}
            onSelect={handleUsersSelect}
            placeholder="Buscar responsables..."
          />
        </div>*/}
    </div>

    {/* Subprocesos */}
    <div className="mb-4">
      <div className="flex justify-between items-center">
        <label className="text-sm font-semibold">Subprocesos</label>
        <button type="button" onClick={handleTemplate1} className="text-blue-500 text-sm flex items-center ml-4">
          <List className="mr-1" /> Cargar Lista Predefinida
        </button>
      </div>

   
      <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            {renderSubprocesoFields()}
            <button 
              type="button" 
              onClick={handleAddSubproceso} 
              className="mt-2 flex items-center text-blue-500 hover:text-blue-700 transition-colors duration-200"
            >
              <PlusCircle className="w-5 h-5 mr-1" /> 
              <span>Añadir subproceso</span>
            </button>
          </div>
      {deletedSubprocesos.length > 0 && (
        <div className="flex items-center mb-1">
          <button
            type="button"
            onClick={handleUndoDelete}
            className="text-yellow-500 text-sm flex items-center"
          >
            <RotateCw className="mr-1" /> Deshacer Eliminación
          </button>
        </div>
      )}

    </div>

    <div className="flex justify-center">
      <button
        type="submit"
        className="p-3 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm flex items-center"
      >
        <Save className="mr-1" /> Guardar Proceso
      </button>
    </div>

    {message && (
  <LabelMessage type={message.type} message={message.message} />
)}
  </form>
</div>

  );
};

export default AdminModule;


/*
 <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold text-center bg-custom-header text-white py-2 mb-6 rounded">ADMINISTRACIÓN</h1>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 mb-6">
          <div className="flex flex-col">
            <label htmlFor="codigo" className="text-lg font-semibold mb-2">Código</label>
            <input
              id="codigo"
              name="codigo"
              type="text"
              value={formData.codigo}
              readOnly
              className="p-2 border border-gray-300 rounded bg-gray-100 cursor-not-allowed"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="nombreProceso" className="text-lg font-semibold mb-2">Nombre del proceso</label>
            <input
              id="nombreProceso"
              name="nombreProceso"
              type="text"
              value={formData.nombreProceso.toUpperCase()}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="anio" className="text-lg font-semibold mb-2">Año</label>
            <input
              id="anio"
              name="anio"
              type="text"
              value={formData.anio}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded"
            />
          </div>

           <div className="flex flex-col">
            <label htmlFor="comuna" className="text-lg font-semibold mb-2">Comuna</label>
            <select
        value={selectedComuna}
        onChange={handleComunaChange}
        className='p-2 border border-gray-300 rounded'
      >
        
        <option value="">Seleccione una comuna</option>
        {regions.flatMap(region => region.comunas).map((comuna, index) => (
          <option key={index} value={comuna}>{comuna}</option>
        ))}
      </select>
          </div>

     
          <div className="flex flex-col">
            <label htmlFor="region" className="text-lg font-semibold mb-2">Región</label>
            <select
  value={selectedRegion}
  onChange={(e) => {
    const regionNumero = e.target.value;
    setSelectedRegion(regionNumero);

    const region = regions.find(r => r.numero === regionNumero);
    if (region) {
      setFormData(prevData => ({
        ...prevData,
        region: region.region,
        numeroRegion: region.numero
      }));
    }
  }}
  className='p-2 border border-gray-300 bg-gray-100 rounded  cursor-not-allowed'
  disabled
>
  <option value="" disabled>Región</option>
  {regions.map((region) => (
    <option key={region.numero} value={region.numero}>{region.region}</option>
  ))}
</select>

          </div>

          <div className="flex flex-col">
          <label htmlFor="numeroProceso" className="text-lg font-semibold mb-2">Número de proceso</label>
      <input
        id="numeroProceso"
        name="numeroProceso"
        type="text"
        value={formData.numeroProceso}
        onChange={handleInputChange}
        className="p-2 border border-gray-300 rounded bg-gray-100  cursor-not-allowed"
        disabled // Haz el campo solo lectura si no se debe editar manualmente
      />
          </div>
          <div className="flex flex-col">
          <label htmlFor="etapa" className="text-lg font-semibold mb-2">Etapa</label>
          <input
              id="etapa"
              name="etapa"
              min={1}
              type="number"
              value={formData.etapa}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded"
            />
        
      </div>
        </div>

        <div className="mb-6">
          <div className="flex justify-between items-center">
            <label className="text-lg font-semibold mb-2">Subprocesos</label>

            <button type="button" onClick={handleTemplate1} className="text-blue-500 flex items-center ml-4">
              <List className="mr-1" /> Cargar Lista Predefinida
            </button>
          </div>
          {formData.subprocesos.map((subproceso, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={subproceso}
                onChange={(e) => handleSubprocesoChange(index, e.target.value)}
                className="p-2 border border-gray-300 rounded flex-grow"
              />
              <button
                type="button"
                onClick={() => handleSubprocesoRemove(index)}
                className="text-red-500 ml-2"
              >
                <MinusCircle />
              </button>
            </div>
            
          ))}
          {deletedSubprocesos.length > 0 && (
            <div className="flex items-center mb-2">
              <button
                type="button"
                onClick={handleUndoDelete}
                className="text-yellow-500 flex items-center"
              >
                <RotateCw className="mr-1" /> Deshacer Eliminación
              </button>
              
            </div>
            
          )}
                      <button type="button" onClick={handleSubprocesoAdd} className="text-blue-500 flex items-center">
              <PlusCircle className="mr-1" /> Añadir Subproceso
            </button>
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center"
          >
            <Save className="mr-1" /> Guardar Proceso
          </button>
        </div>

        {message && (
          <div className={`mt-4 p-2 text-center rounded ${
            message.type === 'success'
              ? 'bg-green-200 text-green-800'
              : message.type === 'error'
              ? 'bg-red-200 text-red-800'
              : ''
          }`}>
            {message.text}
          </div>
        )}
      </form>
    </div>}*/


