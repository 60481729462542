const dateUtils = {
    // Convert a date string (YYYY-MM-DD) to local Date object
    parseLocalDate(dateString: string): Date {
      if (!dateString) return new Date();
      const [year, month, day] = dateString.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      date.setHours(0, 0, 0, 0);
      return date;
    },
  
    // Format a Date object to YYYY-MM-DD string in local timezone
    formatLocalDate(date: Date): string {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    },
  
    // Get today's date at midnight in local timezone
    getTodayLocal(): Date {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
  
    // Get today's date as YYYY-MM-DD string in local timezone
    getTodayString(): string {
      return this.formatLocalDate(this.getTodayLocal());
    },
  
    // Calculate days difference between two dates in local timezone
    calculateDaysDifference(endDateStr: string, startDateStr: string): number {
      const endDate = this.parseLocalDate(endDateStr);
      const startDate = this.parseLocalDate(startDateStr);
      const diffTime = endDate.getTime() - startDate.getTime();
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
  
    // Calculate days delayed from today
    calculateDaysDelayed(endDateStr: string): number {
      const endDate = this.parseLocalDate(endDateStr);
      const today = this.getTodayLocal();
      if (endDate >= today) return 0;
      
      const diffTime = today.getTime() - endDate.getTime();
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
  
    // Add days to a date and return YYYY-MM-DD string
    addDaysToDate(dateStr: string, days: number): string {
      const date = this.parseLocalDate(dateStr);
      date.setDate(date.getDate() + days);
      return this.formatLocalDate(date);
    }
  };
export default dateUtils;