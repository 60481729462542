import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { ScrollArea } from '../ui/scroll-area';
import { FileText } from 'lucide-react';

interface Process {
  id: number;
  nombre: string;
  codigo: string;
  comuna: string;
}

interface TemplateSelectionProps {
  onProcessSelected: (processId: number, processName: string, processCode: string) => void;
}

const TemplateSelectionInterface: React.FC<TemplateSelectionProps> = ({ onProcessSelected }) => {
  const [processes, setProcesses] = useState<Process[]>([]);
  const [selectedProcess, setSelectedProcess] = useState<Process | null>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchProcesses = async () => {
      try {
        const response = await fetch('http://localhost:3000/php/pages/proceso/get_processes_with_structure.php');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Process[] = await response.json();
        setProcesses(data);
      } catch (error) {
        console.error('Error fetching processes:', error);
      }
    };

    fetchProcesses();
  }, []);

  const handleProcessClick = (process: Process) => {
    setSelectedProcess(process);
  };

  const handleLoadProcess = () => {
    if (selectedProcess) {
      
      onProcessSelected(selectedProcess.id, selectedProcess.nombre, selectedProcess.codigo);
    }
  };

  return (
    <Card className="w-full mt-6 border-none shadow-lg rounded-lg bg-white">
      <CardContent className="flex flex-col space-y-6 p-6">
        <div className="w-full">
          <h3 className="text-lg font-semibold mb-2">Planificaciones Disponibles</h3>
          <Card className="border border-gray-200">
            <ScrollArea className="h-48 rounded-md">
              <div className="p-2" ref={tableRef}>
                <table className="w-full">
                  <thead>
                    <tr className="text-left text-sm font-medium text-gray-500">
                      <th className="p-2">Nombre</th>
                      <th className="p-2">Código</th>
                      <th className="p-2">Comuna</th>
                    </tr>
                  </thead>
                  <tbody className='cursor-pointer'>
                    {processes.map((process) => (
                      <tr
                        key={process.id}
                        className={`hover:bg-gray-100 ${
                          selectedProcess?.id === process.id ? 'bg-teal-100 hover:bg-teal-200' : ''
                        }`}
                        onClick={() => handleProcessClick(process)}
                      >
                        <td className="p-2">
                          <div className="flex items-center">
                            <FileText className="mr-2 h-4 w-4 text-gray-500" />
                            {process.nombre}
                          </div>
                        </td>
                        <td className="p-2">{process.codigo}</td>
                        <td className="p-2">{process.comuna}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </ScrollArea>
          </Card>
          <div className="mt-4">
            <Button
              onClick={handleLoadProcess}
              className="bg-teal-500 hover:bg-teal-600 transition-colors text-white"
              size="lg"
              disabled={!selectedProcess}
            >
              Cargar planificación
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default TemplateSelectionInterface;