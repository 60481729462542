import React, { useState } from 'react';
import { Zap, Users, BarChart2, Activity, Database, Clock, Rocket, Star, TrendingUp, Target, Award, ChevronDown, ChevronUp, AlertTriangle, FileSearch } from 'lucide-react';
import { LucideIcon } from 'lucide-react';
import logoImage from '../img/logo3.png';
import videoSrc from '../assets/videos/simagi-video.mp4';
import videoSrc2 from '../assets/videos/simagi-video2.mp4';
import ContactForm from './ContactForm';
import backgroundImage from '../assets/img/background-moneda.jpg';
import { Separator } from '../components/ui/separator';


interface CardProps {
  title: string;
  description: string;
  icon: LucideIcon;
  tooltip: string;
  isTopRow: boolean;

}

interface VideoWrapperProps {
  videoSrc: string;
}


const VideoWrapper: React.FC<VideoWrapperProps> = ({ videoSrc }) => {
  return (
    <div className="relative w-full max-w-4xl mx-auto my-12 ">
      {/* Outer frame */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#73DBCC] to-[#6ECFD6] rounded-3xl transform rotate-1 scale-105 shadow-lg"></div>
      
      {/* Inner frame */}
      <div className="relative bg-white p-4 rounded-2xl shadow-inner">
        {/* Decorative elements */}
        <div className="absolute top-2 left-2 w-3 h-3 bg-[#73DBCC] rounded-full"></div>
        <div className="absolute top-2 left-7 w-3 h-3 bg-[#6ECFD6] rounded-full"></div>
        <div className="absolute top-2 left-12 w-3 h-3 bg-[#73DBCC] rounded-full"></div>
        
        {/* Video container */}
        <div className="relative pt-[56.25%]"> {/* 16:9 aspect ratio */}
          <video 
            className="absolute rounded-xl top-0 left-0 w-full h-full object-cover rounded-lg"
            controls
          >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      
      {/* Caption */}
      <div className="mt-4 text-center text-gray-600 italic">
        Descubre cómo SIMAGI revoluciona la gestión inmobiliaria
      </div>
    </div>
  );
};

const Card: React.FC<CardProps> = ({ title, description, icon: Icon, tooltip, isTopRow }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="relative p-6 bg-white rounded-xl shadow-lg transition-all duration-300 hover:shadow-2xl hover:bg-gradient-to-br from-cyan-50 to-blue-50 group cursor-pointer transform hover:-translate-y-1"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div className="flex items-center mb-3">
        <Icon className="w-8 h-8 text-cyan-600 mr-3 transition-all duration-300 group-hover:scale-110 group-hover:text-cyan-700" />
        <h3 className="text-xl font-bold text-cyan-800 transition-all duration-300 group-hover:text-cyan-900">{title}</h3>
      </div>
      <p className="text-sm text-gray-600 transition-all duration-300 group-hover:text-cyan-700">{description}</p>
      {showTooltip && (
        <div className={`absolute z-50 p-3 bg-white border border-cyan-200 rounded-lg shadow-xl text-sm text-gray-700 w-64 left-1/2 transform -translate-x-1/2 ${
          isTopRow ? '-top-2 -translate-y-full' : '-bottom-2 translate-y-full'
        } opacity-0 group-hover:opacity-100 transition-opacity duration-300`}>
          {tooltip}
          <div className={`absolute ${
            isTopRow ? 'bottom-0 translate-y-1/2' : 'top-0 -translate-y-1/2'
          } left-1/2 transform -translate-x-1/2 rotate-45 w-3 h-3 bg-white z-100 border-cyan-200`}></div>
        </div>
      )}
    </div>
  );
};

const PlanTable = () => (
  <div className="overflow-x-auto mt-8 rounded-xl shadow-lg">
    <table className="w-full text-sm text-left text-gray-500 bg-white">
      <thead className="text-xs text-white uppercase bg-gradient-to-r from-cyan-600 to-blue-600">
        <tr>
          <th scope="col" className="px-6 py-4">Plan</th>
          <th scope="col" className="px-6 py-4">Usuarios Máx.</th>
          <th scope="col" className="px-6 py-4">Procesos Máx.</th>
          <th scope="col" className="px-6 py-4">Costo (UF/mes)</th>
        </tr>
      </thead>
      <tbody>
        {[
          { name: "Plan Arranque", users: 10, processes: 2, cost: 30 },
          { name: "Plan Base", users: 45, processes: 7, cost: 95 },
          { name: "Plan Consolidación", users: 150, processes: 20, cost: 150 },
          { name: "Plan Despegue", users: 200, processes: 30, cost: 180 },
          { name: "Plan Excelencia", users: ">200", processes: "Sin límite", cost: 200 }
        ].map((plan, index) => (
          <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-cyan-50 transition-colors duration-200`}>
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{plan.name}</th>
            <td className="px-6 py-4">{plan.users}</td>
            <td className="px-6 py-4">{plan.processes}</td>
            <td className="px-6 py-4">{plan.cost}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const SimagiBrochure = () => {
  const [isTableVisible, setIsTableVisible] = useState(false);
  
const [showContactForm, setShowContactForm] = useState(false);


  const offerings = [
    { title: "Gestión Intuitiva", description: "Interfaz fácil de usar que simplifica la gestión de proyectos complejos.", icon: Zap, tooltip: "Simplifique sus procesos con nuestra interfaz fácil de usar, diseñada para hacer que la gestión de proyectos complejos sea intuitiva y eficiente." },
    { title: "Gestión Integrada", description: "Planificación unificada desde la compra del terreno hasta la recepción municipal.", icon: Users, tooltip: "Experimente una planificación unificada que abarca desde la adquisición del terreno hasta la recepción municipal, todo en una sola plataforma." },
    { title: "Optimización del Organigrama", description: "Estructura clara de procesos y responsabilidades.", icon: BarChart2, tooltip: "Clarifique roles y responsabilidades con nuestra estructura organizacional optimizada, mejorando la eficiencia en cada etapa del proyecto." },
    { title: "Metodologías Ágiles", description: "Evaluaciones técnico-comerciales eficientes.", icon: Activity, tooltip: "Acelere sus evaluaciones técnico-comerciales con nuestras herramientas ágiles, permitiendo una toma de decisiones más rápida y fundamentada." },
    { title: "Base de Datos Inteligente", description: "Centralización de información de proyectos inmobiliarios.", icon: Database, tooltip: "Centralice toda la información de sus proyectos inmobiliarios en un solo lugar, facilitando el acceso y análisis de datos cruciales." },
    { title: "Analíticas en Tiempo Real", description: "Toma de decisiones basada en datos actualizados.", icon: Clock, tooltip: "Tome decisiones informadas con nuestros paneles de control en tiempo real, ofreciendo una visión clara del progreso y rendimiento del proyecto." },
    { title: "Matriz de Riesgo", description: "Evaluación y gestión integral de riesgos en proyectos inmobiliarios.", icon: AlertTriangle, tooltip: "Identifique, evalúe y mitigue proactivamente los riesgos potenciales en cada fase de su proyecto inmobiliario." },
    { title: "Due Diligence", description: "Análisis exhaustivo para decisiones inmobiliarias informadas.", icon: FileSearch, tooltip: "Realice una auditoría completa y meticulosa de sus proyectos inmobiliarios en etapas clave." },
  ];

  const subscriptionPlans = [
    { title: "Plan Arranque", description: "Integración gradual para empresas que inician con SIMAGI.", icon: Rocket, tooltip: "Perfecto para empresas que están comenzando su viaje con SIMAGI. Ofrece una integración gradual con funcionalidades básicas." },
    { title: "Plan Base", description: "Para empresas que buscan afianzar su gestión con SIMAGI.", icon: Star, tooltip: "Ideal para empresas que ya están familiarizadas con SIMAGI y buscan expandir su uso con funcionalidades más avanzadas." },
    { title: "Plan Consolidación", description: "Mayor alcance operativo para empresas en crecimiento.", icon: TrendingUp, tooltip: "Diseñado para empresas en crecimiento que necesitan un mayor alcance operativo y funcionalidades más robustas." },
    { title: "Plan Despegue", description: "Expansión del alcance con integración completa de SIMAGI.", icon: Target, tooltip: "Para empresas listas para una integración completa de SIMAGI en sus operaciones, con acceso a todas las funcionalidades avanzadas." },
    { title: "Plan Excelencia", description: "Alcance total para grandes corporaciones.", icon: Award, tooltip: "Nuestro plan más completo, diseñado para grandes corporaciones que requieren un alcance total y personalización avanzada de SIMAGI." },
  ];

  
  return (
 <div>
     <img
      className="fixed top-0 left-0 w-full h-full object-cover z-0 opacity-70"
      src={backgroundImage} // Asegúrate de definir `backgroundImage` con la URL de tu imagen
      alt="Fondo"
      ></img>
  <div className="relative  min-h-screen p-4 sm:p-8">
    <div className="max-w-full mx-40 bg-white rounded-2xl shadow-2xl overflow-hidden"> {/* Cambia de max-w-7xl a max-w-full */}
      <header className="bg-gradient-to-r from-[#73DBCC] to-[#6ECFD6] text-white p-12 text-center relative">
        <img src={logoImage} alt="Simagi.cl" className="z-20 relative animate-float w-64 mx-auto mb-8" />
        <h1 className="text-4xl sm:text-5xl font-bold mb-4 relative z-10 animate-float">Revolucionando la Gestión Inmobiliaria</h1>
        <p className="text-xl sm:text-2xl relative z-10 animate-float-delayed">Transformando la estructura organizacional del Desarrollo Inmobiliario</p>
      </header>

      <main className="p-8 sm:p-12">
        <section className="mb-16">
          <h2 className="text-3xl sm:text-4xl font-bold mb-8 text-[#6ECFD6] text-center">Nuestra Visión</h2>
          <p className="text-xl text-gray-700 text-center max-w-4xl mx-auto">
            Integrar la gestión de proyectos y procesos empresariales a través de una plataforma impulsada por IA, 
            diseñada especialmente para el control y seguimiento de la <span className="font-bold text-[#73DBCC]">Permisología</span> como nunca antes.
          </p>
        </section>
        <Separator className="my-8 bg-coral-300" />
          <div className="text-center ">
          <VideoWrapper videoSrc={videoSrc} />

          </div>

          <Separator className="my-8 bg-coral-300" />
        <section className="mb-16">
          <h2 className="text-3xl sm:text-4xl font-bold mb-8 text-[#6ECFD6] text-center">Qué Ofrecemos</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {offerings.map((offering, index) => (
               <Card 
               key={index} 
               {...offering} 
               isTopRow={index < offerings.length / 2}
             />
            ))}
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl sm:text-4xl font-bold mb-8 text-[#6ECFD6] text-center">Beneficios Clave</h2>
          <div className="bg-gradient-to-r from-cyan-50 to-blue-50 p-8 rounded-xl shadow-inner">
            <p className="text-xl text-gray-700 leading-relaxed">
              SIMAGI agiliza con precisión la gestión del desarrollo inmobiliario al eliminar intermediarios y obtener informes 
              automáticos directos de especialistas. Promueve la colaboración, facilita decisiones en tiempo real basadas 
              en datos, ofrece claridad organizacional y asegura la trazabilidad completa de los proyectos.
            </p>
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl sm:text-4xl font-bold mb-8 text-[#6ECFD6] text-center">Planes de Suscripción</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
            {subscriptionPlans.map((plan, index) => (
               <Card 
               key={index} 
               {...plan} 
               isTopRow={index < subscriptionPlans.length / 2}
             />
            ))}
          </div>
          {isTableVisible && <PlanTable />}
        </section>
      </main>

      <footer className="z-0 bg-gradient-to-r from-[#73DBCC] to-[#6ECFD6] text-white p-12 text-center ">
        <h2 className="text-3xl sm:text-4xl font-bold mb-6 relative z-10 animate-float">Comience Hoy</h2>
        <p className="text-xl sm:text-2xl mb-8 relative z-10 animate-float-delayed">
          Descubra cómo SIMAGI puede transformar la estructura y eficiencia de su empresa de desarrollo inmobiliario.
        </p>
        <button 
          onClick={() => setShowContactForm(true)} 
          className="bg-white text-[#73DBCC] font-bold py-4 px-10 rounded-full text-xl hover:bg-[#73DBCC] hover:text-white transition-all duration-300 hover:shadow-xl hover:scale-105 relative z-10"
        >
          ¡Inicie su transformación ahora!
        </button>
        
        {showContactForm && (
          <div className="mt-8">
            <ContactForm onClose={() => setShowContactForm(false)} />
          </div>
        )}
      </footer>
    </div>
  </div>
</div>
  );
}

export default SimagiBrochure;