import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, AlertCircle, CheckCircle, ChevronDown, ChevronUp, X, Bell, Check, Loader2, Search, UserIcon, Users, Clock, Calendar } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../ui/dialog';
import toast, { Toaster } from 'react-hot-toast';
import { Button } from '../ui/button';
import { MdBusiness } from 'react-icons/md'; // Importing a Material Design icon
import dateUtils from './dateUtils';



interface User { 
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  is_admin: boolean;
  is_active: boolean;
  departamento: string;
}

interface UserSelectorModalProps {
  users: User[];
  selectedUsers: User[];
  onUsersChange: (users: User[]) => void;
  enabled?: boolean;
  taskId: number;
  groupId: number;
}

const UserSelectorModal: React.FC<UserSelectorModalProps> = ({
  users: initialUsers = [],
  selectedUsers: initialSelectedUsers = [],
  onUsersChange,
  enabled = true,
  taskId,
  groupId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localSelectedUsers, setLocalSelectedUsers] = useState<User[]>(initialSelectedUsers);
  const [allUsers, setAllUsers] = useState<User[]>(initialUsers);
  const isNewTask = taskId < 0;

  // Cargar todos los usuarios cuando se abre el modal
  const loadAllUsers = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('http://localhost:3000/php/pages/users/get_users.php');
      if (!response.ok) throw new Error('Error fetching users');
      const data = await response.json();
      setAllUsers(data);
    } catch (error) {
      console.error('Error loading users:', error);
      toast.error('Error al cargar la lista de usuarios');
    } finally {
      setIsLoading(false);
    }
  };

  // Cargar usuarios asignados
  const loadAssignedUsers = async () => {
    if (isNewTask || initialSelectedUsers.length > 0) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(
        `http://localhost:3000/php/pages/adm_planificacion/get_task_users.php?taskId=${taskId}&groupId=${groupId}`
      );

      if (!response.ok) {
        throw new Error('Error al cargar los usuarios asignados');
      }

      const data = await response.json();

      if (data.success && Array.isArray(data.users)) {
        
        setLocalSelectedUsers(data.users);
        onUsersChange(data.users);
      }
    } catch (error) {
      console.error('Error loading assigned users:', error);
      toast.error('Error al cargar los usuarios asignados');
    } finally {
      setIsLoading(false);
    }
  };

  // Efecto para cargar datos cuando se abre el modal
  useEffect(() => {
    if (isOpen) {
      loadAllUsers();
      loadAssignedUsers();
    }
  }, [isOpen]);

  // Mantener localSelectedUsers sincronizado con initialSelectedUsers
  useEffect(() => {
    if (Array.isArray(initialSelectedUsers)) {
      setLocalSelectedUsers(initialSelectedUsers);
    }
  }, [initialSelectedUsers]);

  const handleSave = async () => {
    try {
      setIsSaving(true);

      if (isNewTask) {
        onUsersChange(localSelectedUsers);
        handleCloseModal();
        return;
      }

      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task_users.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          taskId,
          groupId,
          userIds: localSelectedUsers.map(user => user.id)
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Error al guardar los responsables');
      }

      toast.success('Responsables actualizados exitosamente');
      onUsersChange(localSelectedUsers);
      handleCloseModal();
    } catch (error) {
      console.error('Error saving users:', error);
      toast.error(error instanceof Error ? error.message : 'Error al guardar los responsables');
    } finally {
      setIsSaving(false);
    }
  };

  const handleOpenModal = () => {
    if (!enabled) return;
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setSearchTerm('');
  };

  // Filtrado de usuarios mejorado
  const filteredUsers = allUsers.filter(user => {
    const searchString = `${user.firstname} ${user.lastname} ${user.email} ${user.departamento}`.toLowerCase();
    const searchTerms = searchTerm.toLowerCase().split(' ');
    return searchTerms.every(term => searchString.includes(term));
  });

  const hasSelectedUsers = localSelectedUsers.length > 0;

  return (
    <>
      <div 
        onClick={handleOpenModal} 
        className={`cursor-pointer flex items-center ml-8 ${enabled ? 'hover:opacity-80' : 'opacity-50 cursor-not-allowed'}`}
        title={hasSelectedUsers
          ? `Responsables: ${localSelectedUsers.map(u => `${u.firstname} ${u.lastname}`).join(', ')}` 
          : 'Sin responsables asignados'}
      >
        <UserIcon 
          className={`w-5 h-5 mr-2 ${hasSelectedUsers ? 'text-green-500' : 'text-gray-400'}`}
        />
      </div>

      <Dialog isOpen={isOpen} onClose={handleCloseModal} width="600px">
        <div className="overflow-hidden">
          <div className="bg-gradient-to-r from-teal-500 to-teal-600 p-6">
            <div className="flex items-center space-x-2">
              <Users className="w-6 h-6 text-white" />
              <DialogTitle>
                <span className="text-xl font-semibold text-white">
                  Seleccionar Responsables
                </span>
              </DialogTitle>
            </div>
            <p className="text-teal-100 mt-2 text-sm">
              {localSelectedUsers.length} usuario(s) seleccionado(s)
            </p>
          </div>

          <DialogContent className="px-6 py-4">
            {isLoading ? (
              <div className="flex items-center justify-center py-8">
                <Loader2 className="w-8 h-8 animate-spin text-teal-500" />
                <span className="ml-2">Cargando usuarios...</span>
              </div>
            ) : (
              <>
                <div className="mb-6">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Buscar por nombre, email o departamento..."
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                    />
                  </div>
                </div>

                <div className="bg-teal-50 p-4 rounded-lg border border-teal-100 mb-4">
                  <h3 className="text-sm font-medium text-teal-800 mb-2">Usuarios Seleccionados</h3>
                  <div className="flex flex-wrap gap-2">
                    {localSelectedUsers.map((user) => (
                      <div
                        key={user.id}
                        className="inline-flex items-center gap-2 bg-white text-teal-700 px-3 py-1 rounded-full text-sm border border-teal-200 hover:bg-teal-50 transition-colors"
                      >
                        <UserIcon className="w-4 h-4" />
                        <span>{`${user.firstname} ${user.lastname}`}</span>
                        <button
                          onClick={() => setLocalSelectedUsers(prev => prev.filter(u => u.id !== user.id))}
                          className="ml-1 text-teal-400 hover:text-teal-600"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                    {localSelectedUsers.length === 0 && (
                      <p className="text-sm text-teal-600 italic">No hay usuarios seleccionados</p>
                    )}
                  </div>
                </div>

                <div className="space-y-2">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">
                    {filteredUsers.length} usuario(s) encontrado(s)
                  </h3>
                  <div className="grid grid-cols-1 gap-2 max-h-[300px] overflow-y-auto">
                    {filteredUsers.map((user) => (
                      <button
                        key={user.id}
                        onClick={() => {
                          const isSelected = localSelectedUsers.some(u => u.id === user.id);
                          if (isSelected) {
                            setLocalSelectedUsers(prev => prev.filter(u => u.id !== user.id));
                          } else {
                            setLocalSelectedUsers(prev => [...prev, user]);
                          }
                        }}
                        className={`flex items-center justify-between w-full p-3 rounded-lg border transition-all ${
                          localSelectedUsers.some(u => u.id === user.id)
                            ? 'bg-teal-50 border-teal-200 text-teal-700'
                            : 'bg-white border-gray-200 hover:bg-gray-50'
                        }`}
                      >
                        <div className="flex items-center gap-3">
                          <div className="w-8 h-8 rounded-full bg-teal-100 flex items-center justify-center">
                            <UserIcon className="w-4 h-4 text-teal-600" />
                          </div>
                          <div className="flex flex-col items-start">
                            <span className="font-medium">{`${user.firstname} ${user.lastname}`}</span>
                            <span className="text-sm text-gray-500">{user.email}</span>
                          </div>
                        </div>
                        <span className="text-sm text-gray-500">{user.departamento}</span>
                      </button>
                    ))}
                  </div>
                </div>
              </>
            )}
          </DialogContent>

          <DialogFooter>
            <div className="flex justify-end gap-3 px-6 py-4 bg-gray-50 border-t border-gray-200">
              <Button
                onClick={handleCloseModal}
                variant="outline"
                className="px-4 py-2"
                disabled={isSaving}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleSave}
                className="bg-teal-500 text-white hover:bg-teal-600 px-4 py-2"
                disabled={isSaving || isLoading}
              >
                {isSaving ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Guardando...
                  </>
                ) : (
                  'Confirmar Selección'
                )}
              </Button>
            </div>
          </DialogFooter>
        </div>
      </Dialog>
    </>
  );
};


interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
  backgroundColor: string;
  allTasks: Task[];
  agrupadorId: number;
  daysDelayed: number;
  groupProgress: number;
}



interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: Task;
  onChangeTask: (updatedTask: Task) => void;
  backgroundColor: string;
  allTasks: Task[];
  agrupadorId: number;
  daysDelayed: number;
  groupProgress: number;
  processName: string | undefined;          // Nombre del proceso
  subprocessName: string | undefined;       // Nombre del subproceso
  agrupadorName: string | undefined;  
}

interface TaskUpdateData {
  id: number;
  tarea_id?: number;
  isClosed: boolean;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  followUpDate: string;
  comments: string;
  semaphoreStatus: SemaphoreStatus;
  delayInDays?: number;
}



interface SubtaskUpdateData {
  id: number;
  tarea_id: number;
  nombre: string;
  tipo: string;
  responsible: User[] | string;
  organismo: string;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  duracion: number;
  dependencias: string;
  enabled: boolean;
  resolucion_tipo?: string;
  orden: number;
  comments: string;
  isClosed: boolean;
  followUpDate: string;
  semaphoreStatus: SemaphoreStatus;
}

interface CommentData {
  id: number;          // ID de la tarea o subtarea
  comments: string;    // Comentarios en formato JSON string
  type: 'task' | 'subtask'; // Indica si es tarea principal o subtarea
  tarea_id?: number;   // ID de la tarea padre (solo para subtareas)
}


interface CommentResponse {
  success: boolean;
  comments?: string[];
  message?: string;
}


const TaskModal: React.FC<TaskModalProps> = ({ 
  isOpen, 
  onClose, 
  task, 
  onChangeTask, 
  backgroundColor,
  allTasks,
  agrupadorId,
  daysDelayed,
  groupProgress,
  processName,         // Prop: Nombre del proceso
  subprocessName,      // Prop: Nombre del subproceso
  agrupadorName,  
}) => {
 
  const [followUpDate, setFollowUpDate] = useState<string>(task.followUpDate || '');
  const [showReminderPicker, setShowReminderPicker] = useState(false);
  const storedFirstName = sessionStorage.getItem('firstName');
  const storedTitle = sessionStorage.getItem('title');
  const storedLastName = sessionStorage.getItem('lastName');
  const [comments, setComments] = useState<string[]>([]);
  const [newComment, setNewComment] = useState('');
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const commentsContainerRef = useRef<HTMLDivElement>(null);
  

  const formatDateDDMMYYYY = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };



  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
    }
  }, [task.comments, isOpen]); // Agrega isOpen como dependencia

  


  // Función para cargar comentarios de una tarea
const loadTaskComments = async (taskId: number): Promise<string[]> => {
  try {
    const response = await fetch(`http://localhost:3000/php/pages/adm_planificacion/get_task_comments.php?taskId=${taskId}`);
    
    if (!response.ok) {
      throw new Error('Error loading task comments');
    }

    const data: CommentResponse = await response.json();
    if (data.success && data.comments) {
      return data.comments;
    }
    return [];
  } catch (error) {
    console.error('Error loading task comments:', error);
    throw error;
  }
};
  const loadComments = useCallback(async () => {
    if (!task.id) return;
    
    try {
      setIsLoadingComments(true);
      const response = await fetch(`http://localhost:3000/php/pages/adm_planificacion/get_task_comments.php?taskId=${task.id}`);
      
      if (!response.ok) {
        throw new Error('Error loading comments');
      }

      const data = await response.json();
      const loadedComments = data.success && data.comments ? data.comments : [];
      setComments(loadedComments);
      
      // Actualizar la tarea con los comentarios como string
      onChangeTask({
        ...task,
        comments: JSON.stringify(loadedComments)
      });
    } catch (error) {
      console.error('Error loading comments:', error);
      toast.error('Error al cargar los comentarios');
      setComments([]);
    } finally {
      setIsLoadingComments(false);
    }
  }, [task.id]);



// Cargar comentarios al abrir el modal
useEffect(() => {
  if (isOpen) {
    loadComments();
  }
}, [isOpen, loadComments]);

  const handleTaskUpdate = async (task: Task, updatedData: Partial<Task>): Promise<void> => {
    try {
      // Actualizar la tarea principal
      const taskUpdateData: TaskUpdateData = {
        id: task.id,
        isClosed: updatedData.isClosed ?? task.isClosed,
        progreso: updatedData.progreso ?? task.progreso,
        fecha_inicio: updatedData.fecha_inicio ?? task.fecha_inicio,
        fecha_termino: updatedData.fecha_termino ?? task.fecha_termino,
        followUpDate: updatedData.followUpDate ?? task.followUpDate,
        comments: updatedData.comments ?? task.comments,
        semaphoreStatus: updatedData.semaphoreStatus ?? task.semaphoreStatus,
      };
  
      await updateTaskInDatabase(taskUpdateData);
  
      // Si hay subtareas actualizadas, procesarlas
      if (updatedData.subtasks && task.subtasks) {
        for (const subtask of updatedData.subtasks) {
          const subtaskUpdateData: SubtaskUpdateData = {
            id: subtask.id!,
            tarea_id: task.id,
            nombre: subtask.name,
            tipo: subtask.type,
            responsible: subtask.responsible,
            organismo: subtask.organismo,
            progreso: subtask.progreso,
            fecha_inicio: subtask.fecha_inicio,
            fecha_termino: subtask.fecha_termino,
            duracion: subtask.duration,
            dependencias: JSON.stringify(subtask.dependsOn),
            enabled: subtask.enabled,
            resolucion_tipo: subtask.resolutionType,
            orden: subtask.orden,
            comments: subtask.comments,
            isClosed: subtask.isClosed,
            followUpDate: subtask.followUpDate,
            semaphoreStatus: subtask.semaphoreStatus
          };
  
        }
      }
    } catch (error) {
      console.error('Error in handleTaskUpdate:', error);
      throw error;
    }
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    onChangeTask({ ...task, [name]: value });
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (task.isClosed) return;
  
    const { name, value } = e.target;
    const today = dateUtils.getTodayLocal();
    const selectedDate = dateUtils.parseLocalDate(value);
  
    if (selectedDate < today) {
      alert('La fecha no puede ser anterior a hoy.');
      return;
    }
  
    onChangeTask({ ...task, [name]: value });
  };
  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    try {
      setIsLoadingComments(true);
      
      const timestamp = new Date().toLocaleString('es-CL', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });

      const commentWithTimestamp = `[${timestamp}] ${storedFirstName} ${storedLastName}: ${newComment}`;
      const updatedComments = [...comments, commentWithTimestamp];

      // Actualizar estado local
      setComments(updatedComments);
      
      // Convertir a string para la tarea y la base de datos
      const commentsString = JSON.stringify(updatedComments);

      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task_comments.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: task.id,
          comments: commentsString
        }),
      });

      if (!response.ok) {
        throw new Error('Error al actualizar comentarios');
      }

      // Actualizar la tarea con los nuevos comentarios como string
      const updatedTask = {
        ...task,
        comments: commentsString
      };

      // Actualizar en la base de datos
      await updateTaskInDatabase({
        id: task.id,
        comments: commentsString,
        isClosed: task.isClosed,
        progreso: task.progreso,
        fecha_inicio: task.fecha_inicio,
        fecha_termino: task.fecha_termino,
        followUpDate: task.followUpDate,
        semaphoreStatus: task.semaphoreStatus,
      });

      onChangeTask(updatedTask);
      setNewComment('');

      // Scroll al último comentario
      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
      }

    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Error al agregar el comentario');
      await loadComments();
    } finally {
      setIsLoadingComments(false);
    }
  };


  const renderComments = () => {
    if (comments.length === 0) {
      return <p className="text-gray-500 italic">No hay comentarios</p>;
    }

    return comments.map((comment, index) => {
      const [datePart, userAndContent] = comment.split('] ');
      const date = datePart.replace('[', '').trim();
      const [user, ...contentParts] = userAndContent.split(':');
      const content = contentParts.join(':').trim();

      return (
        <div key={index} className="mb-3 last:mb-0">
          <div className="bg-green-100 p-3 rounded-lg shadow-sm">
            <div className="flex items-center gap-2 text-xs text-gray-600 mb-1">
              <span className="font-medium">{user}</span>
              <span className="text-gray-400">•</span>
              <span className="text-gray-400">{date}</span>
            </div>
            <p className="text-sm text-gray-800">{content}</p>
          </div>
        </div>
      );
    });
  };


  // Add useEffect to reload comments when the modal opens
  useEffect(() => {
    if (isOpen) {
      loadComments();
    }
  }, [isOpen, loadComments]);


  // Función para actualizar comentarios de una tarea principal
const updateTaskComments = async (taskData: CommentData): Promise<void> => {
  try {
    const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task_comments.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: taskData.id,
        comments: taskData.comments
      }),
    });

    if (!response.ok) {
      throw new Error('Error updating task comments');
    }

    const result = await response.json();
    if (!result.success) {
      throw new Error(result.message || 'Error updating task comments');
    }
  } catch (error) {
    console.error('Error in updateTaskComments:', error);
    throw error;
  }
};




  const [updateTrigger, setUpdateTrigger] = useState(0);
/*
  const handleCloseTask = async () => {
    if (task.semaphoreStatus === SemaphoreStatus.Gray) {
      alert("No se puede cerrar una tarea que aún no ha iniciado.");
      return;
    }
  
    // Crear fecha actual en zona horaria local
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Formatear la fecha a YYYY-MM-DD manteniendo la zona horaria local
    const formattedDate = today.getFullYear() + '-' + 
      String(today.getMonth() + 1).padStart(2, '0') + '-' + 
      String(today.getDate()).padStart(2, '0');
  
    const updatedTask = { 
      ...task, 
      isClosed: true, 
      progreso: 100, 
      fecha_termino: formattedDate 
    };
    
    try {
      // Actualizar la tarea en la base de datos
      await updateTaskInDatabase(updatedTask);
      onChangeTask(updatedTask);
      setUpdateTrigger(prev => prev + 1); // Forzar re-renderizado
  
      // Calcular y actualizar el progreso del agrupador
      // await updateAgrupadorProgress(task.id);
      
      onChangeTask(updatedTask);
    } catch (error) {
      console.error('Error al cerrar la tarea:', error);
      alert('Hubo un error al cerrar la tarea. Por favor, inténtelo de nuevo.');
    }
  };
*/


useEffect(() => {
  const checkAndCloseMainTask = async () => {
    if (
      task.nombre === 'TRAMITACIÓN' && 
      task.subtasks && 
      task.subtasks.length > 0 && 
      !task.isClosed
    ) {
      const allSubtasksClosed = task.subtasks.every(subtask => subtask.isClosed);
      
      if (allSubtasksClosed) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const formattedDate = today.getFullYear() + '-' + 
          String(today.getMonth() + 1).padStart(2, '0') + '-' + 
          String(today.getDate()).padStart(2, '0');

        const updatedTask = { 
          ...task, 
          isClosed: true, 
          progreso: 100, 
          fecha_termino: formattedDate 
        };
        
        try {
          
          await updateTaskInDatabase(updatedTask);
          onChangeTask(updatedTask);
          setUpdateTrigger(prev => prev + 1);
        } catch (error) {
          console.error('Error al cerrar la tarea automáticamente:', error);
        }
      }
    }
  };

  checkAndCloseMainTask();
}, [task.subtasks]);

const handleCloseTask = async () => {
  // Verify task can be closed
  if (task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed) {
    alert("No se puede cerrar una tarea que aún no ha iniciado.");
    return;
  }

  // Verify if TRAMITACIÓN task with subtasks can be closed
  if (task.nombre === 'TRAMITACIÓN' && task.subtasks) {
    const hasOpenSubtasks = task.subtasks.some(subtask => !subtask.isClosed);
    if (hasOpenSubtasks) {
      alert("No se puede cerrar la tarea de TRAMITACIÓN mientras tenga subtareas abiertas.");
      return;
    }
  }

  // Get current date in local timezone
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Ajustar a medianoche en zona horaria local
  
  // Formatear la fecha a YYYY-MM-DD manteniendo la zona horaria local
  const formattedDate = today.getFullYear() + '-' + 
    String(today.getMonth() + 1).padStart(2, '0') + '-' + 
    String(today.getDate()).padStart(2, '0');

  // Create updated task object with all necessary fields
  const updatedTask = { 
    ...task, 
    isClosed: true, 
    progreso: 100, 
    fecha_termino: formattedDate,
    fecha_inicio: task.fecha_inicio,
    subtasks: task.subtasks?.map(subtask => ({
      ...subtask,
      fecha_inicio: subtask.fecha_inicio,
      fecha_termino: subtask.isClosed ? subtask.fecha_termino : formattedDate
    }))
  };
  
  try {
    // Update task in database
    await updateTaskInDatabase({
      ...task,
      id: updatedTask.id,
      isClosed: updatedTask.isClosed,
      progreso: updatedTask.progreso,
      fecha_inicio: updatedTask.fecha_inicio,
      fecha_termino: updatedTask.fecha_termino,
      
      followUpDate: updatedTask.followUpDate,
      comments: updatedTask.comments,
      semaphoreStatus: updatedTask.semaphoreStatus
    });

    // Update local state
    onChangeTask(updatedTask);

  } catch (error) {
    console.error('Error al cerrar la tarea:', error);
    alert('Hubo un error al cerrar la tarea. Por favor, inténtelo de nuevo.');
  }
};

const canCloseTramitacionTask = (task: Task): boolean => {
  if (task.nombre !== 'TRAMITACIÓN') return true;
  if (!task.subtasks || task.subtasks.length === 0) return true;
  
  return task.subtasks.every(subtask => subtask.isClosed);
};

// Función para actualizar las fechas de las subtareas
/*const updateSubtasksDates = (subtasks: SubTask[]): SubTask[] => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  return subtasks.map(subtask => ({
    ...subtask,
    fecha_termino: subtask.isClosed ? subtask.fecha_termino : formattedDate // Solo actualizar fechas de subtareas abiertas
  }));
};
*/

// Función para abrir una tarea
const handleOpenTask = async () => {
  try {
    // Usar dateUtils para parsear la fecha de inicio
    const startDate = dateUtils.parseLocalDate(task.fecha_inicio);
    
    // Calcular la nueva fecha de término usando dateUtils
    const newEndDate = new Date(startDate);
    newEndDate.setDate(startDate.getDate() + (task.duracion * 7));
    const formattedEndDate = dateUtils.formatLocalDate(newEndDate);

    const updatedTask = { 
      ...task, 
      isClosed: false,
      progreso: 0,
      fecha_termino: formattedEndDate
    };

    // Actualizar la tarea en la base de datos
    await updateTaskInDatabase(updatedTask);
    // Actualizar el estado local
    onChangeTask(updatedTask);
    setUpdateTrigger(prev => prev + 1);
    onChangeTask(updatedTask);

  } catch (error) {
    console.error('Error al abrir la tarea:', error);
    alert('Hubo un error al abrir la tarea. Por favor, inténtelo de nuevo.');
  }
};

  const handleResetFollowUpDate = () => {
    onChangeTask({ ...task, followUpDate: '' });
  };
  const handleSaveChanges = async () => {
    try {
      const updatedTask = {
        ...task,
        followUpDate, // Añadir fecha de seguimiento
        
      };
      await updateTaskInDatabase(updatedTask); // Función que guardará los datos en la base de datos

      onChangeTask(updatedTask);
      onClose();
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
      alert('Hubo un error al guardar los cambios. Por favor, inténtelo de nuevo.');
    }
  };




// Función para actualizar una tarea principal
const updateTaskInDatabase = async (taskData: TaskUpdateData): Promise<void> => {
  try {
    const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(taskData),
    });

    if (!response.ok) {
      throw new Error('Error updating task');
    }

    const result = await response.json();
    if (!result.success) {
      throw new Error(result.message || 'Error updating task');
    }
  } catch (error) {
    console.error('Error in updateTaskInDatabase:', error);
    throw error;
  }
};

  

  

  const formatFollowUpDate = (date: string): string => {
    if (!date) return 'No establecida';
    const followUpDate = new Date(date);
    const today = new Date();
    const diffTime = followUpDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return `Hace ${Math.abs(diffDays)} días`;
    } else if (diffDays === 0) {
      return 'Hoy';
    } else if (diffDays === 1) {
      return 'Mañana';
    } else {
      return `En ${diffDays} días`;
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <motion.div className="bg-white rounded-lg w-full max-w-2xl shadow-xl">
          <div className="bg-teal-600 text-white px-6 py-4 flex justify-between items-center shadow-md rounded-t-lg"> 
  <div>
    <h3 className="text-base font-semibold flex items-center space-x-2">
      <MdBusiness className="text-white w-5 h-5" /> 
      <span className="text-sm opacity-80 tracking-wide">
        {`${processName?.toUpperCase()} / ${subprocessName?.toUpperCase()} / ${agrupadorName?.toUpperCase()}`}
      </span>
    </h3>
    <div className="flex items-center mt-1">
      <span className="text-lg font-bold text-white">{task.nombre}</span>
    </div>
    <div className="flex items-center gap-4 text-white/70 text-xs mt-1">
      <span className="flex items-center gap-1">
        <Calendar className="w-4 h-4" />
        {formatDateDDMMYYYY(task.fecha_inicio)}
      </span>
      <span className="flex items-center gap-1">
        <Calendar className="w-4 h-4" />
        {formatDateDDMMYYYY(task.fecha_termino)}
      </span>
    </div>
  </div>
  <button onClick={onClose} className="text-white/80 hover:text-white focus:outline-none">
    <X size={20} />
  </button>
</div>



            {/* Contenido principal */}
            <div className="p-4">
              {/* Estado de la tarea */}
              <div className="mb-3 flex items-center">
                <div className={`px-3 py-1 rounded-full text-sm font-medium ${
                  daysDelayed > 0 && !task.isClosed 
                    ? 'bg-red-100 text-red-700' 
                    : 'bg-green-100 text-green-700'
                }`}>
                  {daysDelayed > 0 && !task.isClosed 
                    ? `Retrasada por ${daysDelayed} día(s)` 
                    : 'A tiempo'}
                </div>
              </div>

              {/* Grid de campos */}
              <div className="grid grid-cols-2 gap-3 mb-4">
                <div>
                  <label className="block text-xs font-medium text-gray-700 mb-1">Descripción</label>
                  <input
                    type="text"
                    name="description"
                    value={task.nombre}
                    onChange={handleInputChange}
                    className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    disabled={task.isClosed}
                  />
                </div>
              <div></div>

                <div>
                  <label className="block text-xs font-medium text-gray-700 mb-1">Fecha de Inicio</label>
                  <input
                    type="date"
                    name="fecha_inicio"
                    value={task.fecha_inicio}
                    onChange={handleDateChange}
                    className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    disabled={task.isClosed}
                  />
                </div>
                <div>
                  <label className="block text-xs font-medium text-gray-700 mb-1">Fecha de Término</label>
                  <input
                    type="date"
                    name="fecha_termino"
                    value={task.fecha_termino}
                    onChange={handleDateChange}
                    className="w-full text-sm rounded border-gray-300 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    disabled={task.isClosed}
                  />
                </div>
              </div>
  {/* Actualizar sección de comentarios */}
  <div className="comments-section">
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-lg font-medium">Comentarios</h3>
                {isLoadingComments && <Loader2 className="animate-spin h-4 w-4" />}
              </div>
              
              <div 
                ref={commentsContainerRef}
                className="max-h-60 overflow-y-auto border rounded-md p-4 bg-gray-50"
              >
                {task.comments ? (
                  (() => {
                    try {
                      const parsedComments = JSON.parse(task.comments);
                      return Array.isArray(parsedComments) && parsedComments.length > 0 ? (
                        parsedComments.map((comment, index) => {
                          const [datePart, userAndContent] = comment.split('] ');
                          const date = datePart.replace('[', '').trim();
                          const [user, ...contentParts] = userAndContent.split(':');
                          const content = contentParts.join(':').trim();

                          return (
                            <div key={index} className="mb-3 last:mb-0 flex justify-fecha_inicio">
                              <div className="max-w-xs p-3 rounded-lg shadow-md bg-green-100 text-green-900">
                                <div className="flex items-center gap-2 text-xs text-gray-600 mb-1">
                                  <span className="font-medium">{user}</span>
                                  <span className="text-gray-400">•</span>
                                  <span className="text-gray-400">{date}</span>
                                </div>
                                <p className="text-sm">{content}</p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-gray-500 italic">No hay comentarios</p>
                      );
                    } catch (error) {
                      return <p className="text-red-500">Error al cargar comentarios</p>;
                    }
                  })()
                ) : (
                  <p className="text-gray-500 italic">Cargando comentarios...</p>
                )}
              </div>

              <div className="mt-4 flex gap-2">
                <input
                  type="text"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Escribir un comentario..."
                  className="flex-1 rounded-md border border-gray-300 px-3 py-2"
                  disabled={task.isClosed}
                />
                <button
                  onClick={async () => {
                    await handleAddComment();
                    setNewComment(''); // Limpiar input después de agregar el comentario
                  }}
                  disabled={task.isClosed || !newComment.trim() || isLoadingComments}
                  className="px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700 disabled:opacity-50"
                >
                  Agregar
                </button>
              </div>
            </div>
</div>


              {/* Botones de acción */}
              <div className="flex justify-between mt-4 pt-3 border-t">
                <button
                  type="button"
                  className={`px-3 py-1.5 rounded text-sm font-medium ${
                    task.isClosed
                      ? 'bg-green-600 hover:bg-green-700 text-white'
                      : task.semaphoreStatus !== SemaphoreStatus.Green ||
                        (task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0)
                      ? 'bg-gray-400 text-white cursor-not-allowed'
                      : 'bg-red-600 hover:bg-red-700 text-white'
                  }`}
                  onClick={task.isClosed ? handleOpenTask : handleCloseTask}
                  disabled={
                    !task.isClosed &&
                    (task.semaphoreStatus === SemaphoreStatus.Gray ||
                     (task.nombre === 'TRAMITACIÓN' && task.subtasks && task.subtasks.length > 0))
                  }
                >
                  {task.isClosed ? "ABRIR TAREA" : "CERRAR TAREA"}
                </button>

                <button
                  type="button"
                  onClick={handleSaveChanges}
                  className="px-3 py-1.5 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700"
                >
                  Guardar Cambios
                </button>
              </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};


interface Task {
  id: number;
  proceso: string;
  codigo: string; // New field
  comuna: string; // New field
  subproceso: string;
  agrupador: string;
  nombre: string;
  responsable: string;
  fecha_inicio: string;
  fecha_termino: string;
  progreso: number;
  descriptor: string;
  organismo: string;
  duracion: number;
  dependencia: string | number | null;
  enabled: boolean;
  isClosed: boolean;
  comments: string;
  orden: number;
  followUp: boolean;
  followUpDate: string;
  semaphoreStatus: SemaphoreStatus;
  subtasks?: SubTask[];
}

interface SubTask {
  id?: number;
  name: string;
  responsible: User[] | string; // Puede ser un array de Users o un string
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  duration: number;
  organismo: string;
  dependsOn?: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean;
  type: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO';
  orden: number;
  comments: string;
  followUpDate: string;
  followUp: boolean;
  semaphoreStatus: SemaphoreStatus;
  isClosed: boolean;
  delayInDays: number;
  reminder_type?: 'specific-date' | 'relative-time' | 'progreso-based' | null;
  reminder_value?: string | null;
}

enum SemaphoreStatus {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Green = "green",
  Gray = "gray"
}

const ProcessStatusTable = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupedTasks, setGroupedTasks] = useState<{[key: string]: Task[]}>({});
  const [updateTrigger, setUpdateTrigger] = useState(0); // Agregar el estado updateTrigger
  const [users, setUsers] = useState<User[]>([]); // Initialize users state


 
  
  const renderFollowUpStatus = (task: Task) => {
    return task.followUpDate ? (
      <div className="w-6 h-6 rounded-full bg-red-500 mx-auto flex items-center justify-center">
        <Bell className="w-4 h-4 text-white" />
      </div>
    ) : (
      <div className="w-6 h-6 rounded-full bg-gray-200 mx-auto" />
    );
  };

  const getStatusColor = (status: SemaphoreStatus): string => {
    const colors = {
      [SemaphoreStatus.Red]: '#EF4444',
      [SemaphoreStatus.Orange]: '#FB923C',
      [SemaphoreStatus.Yellow]: '#FDE047',
      [SemaphoreStatus.Green]: '#4ADE80',
      [SemaphoreStatus.Gray]: '#E5E7EB'
    };
    return colors[status];
  };


  
const calculateTaskStatus = (task: Task): SemaphoreStatus => {
  const currentDate = dateUtils.getTodayLocal();

  const startDate = dateUtils.parseLocalDate(task.fecha_inicio);
  const endDate = dateUtils.parseLocalDate(task.fecha_termino);

  if (task.isClosed) return SemaphoreStatus.Gray;
  if (startDate > currentDate) return SemaphoreStatus.Gray;
  if (currentDate > endDate) return SemaphoreStatus.Red;

  const daysUntilEnd = dateUtils.calculateDaysDifference(task.fecha_termino, dateUtils.getTodayString());
  
  if (daysUntilEnd <= 2) return SemaphoreStatus.Orange;
  if (daysUntilEnd <= 5) return SemaphoreStatus.Yellow;
  return SemaphoreStatus.Green;
};

 // Fetch users from the server
 const fetchUsers = async () => {
  try {
    const response = await fetch('http://localhost:3000/php/pages/users/get_users.php');
    if (!response.ok) throw new Error('Error fetching users');
    const data: User[] = await response.json();
    setUsers(data);
  } catch (error) {
    console.error('Error fetching users:', error);
    alert('Error al cargar la lista de usuarios');
  }
};

const fetchTasks = async () => {
  try {
    const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/get_all_tasks.php');
    if (!response.ok) throw new Error('Error fetching tasks');

    const text = await response.text();
    if (!text) throw new Error('Empty response from server');

    let data: Task[];
    try {
      data = JSON.parse(text);
      console.log("Tareas recibidas:", data); // Agrega este log para verificar los datos
    } catch (jsonError) {
      throw new Error('Invalid JSON response');
    }

    // Procesar y agrupar las tareas recibidas
    const sortedData = data.sort((b, a) => {
      if (a.subproceso === b.subproceso) return a.orden - b.orden;
      return a.subproceso.localeCompare(b.subproceso);
    });

    const grouped = sortedData.reduce((acc, task) => {
      if (!acc[task.proceso]) acc[task.proceso] = [];
      acc[task.proceso].push(task);
      return acc;
    }, {} as { [key: string]: Task[] });

    console.log("Tareas agrupadas:", grouped); // Verificar agrupación

    setGroupedTasks(grouped); // Guardar el resultado agrupado en el estado

    setTasks(data); // Guardar las tareas en el estado si es necesario
  } catch (error) {
    console.error('Error fetching tasks:', error);
  } finally {
    setIsLoading(false);
  }
};



    // Load users and tasks on component mount
    useEffect(() => {
      const loadData = async () => {
        await fetchUsers();
        await fetchTasks();
        setUpdateTrigger(prev => prev + 1);
      };
      loadData();
    }, []);
  
  // Render component conditionally based on loading state
  if (isLoading) {
    return <div className="flex justify-center items-center h-64">Loading...</div>;
  }

  // Render responsible cell based on loaded users and tasks
  const renderResponsibleCell = (task: Task) => {
    const isActive = calculateTaskStatus(task) !== SemaphoreStatus.Gray && !task.isClosed;
    if (!isActive) return null;

    let responsableIds: number[] = [];
    try {
      if (typeof task.responsable === 'string') {
        if (task.responsable.startsWith('[')) {
          responsableIds = JSON.parse(task.responsable);
        } else if (task.responsable.includes(',')) {
          responsableIds = task.responsable.split(',').map(id => parseInt(id.trim()));
        } else if (task.responsable) {
          responsableIds = [parseInt(task.responsable)];
        }
      }
    } catch (error) {
      console.error('Error parsing responsables:', error);
      responsableIds = [];
    }

    return (
      <div className="flex justify-center">
        <UserSelectorModal
          users={users}
          selectedUsers={users.filter(user => responsableIds.includes(user.id))}
          onUsersChange={(newUsers: User[]) => {
            const updatedTask = {
              ...task,
              responsable: JSON.stringify(newUsers.map(user => user.id)),
            };
            handleTaskUpdate(updatedTask);
          }}
          enabled={!task.isClosed}
          taskId={task.id}
          groupId={task.id}
        />
      </div>
    );
  };

  
  const handleTaskUpdate = (updatedTask: Task) => {
    setTasks(prevTasks =>
      prevTasks.map(task => (task.id === updatedTask.id ? updatedTask : task))
    );
  };

  const handleTaskClick = (task: Task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const formatDate = (dateString: string): string => {
    const [year, month, day] = dateString ? dateString.split('-').map(Number) : [0, 0, 0];
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString('es-CL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
  };

  const getRowStyle = (task: Task, index: number) => {
    const baseStyle = "transition-all duration-200 hover:bg-opacity-90";
    return task.nombre === 'TRAMITACIÓN' ? `${baseStyle} bg-teal-50` : `${baseStyle} ${index % 2 === 0 ? 'bg-opacity-5' : 'bg-opacity-10'}`;
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-64">Loading...</div>;
  }
  
  if (Object.keys(groupedTasks).length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-96 text-center text-gray-600">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
          className="mb-4"
        >
          <AlertCircle className="w-16 h-16 text-gray-400" />
        </motion.div>
        <h2 className="text-xl font-semibold mb-2">No hay tareas disponibles</h2>
        <p className="text-gray-500">Por favor, agregue nuevas tareas o revise los filtros aplicados.</p>
      </div>
    );
  }
  

  return (
    <div className="overflow-x-auto">
      {Object.entries(groupedTasks).map(([proceso, processTasks]) => (
        <div key={proceso} className="mb-8">
          <div className="bg-teal-600 text-white px-6 py-4">
          <h3 className="text-lg font-semibold flex items-center space-x-2">
    <MdBusiness className="text-white w-6 h-6" /> {/* Icon on the same line */}
    <span>{processTasks[0].codigo} - {proceso} - {processTasks[0].comuna}</span>
  </h3>  </div>
 
          <table className="min-w-full bg-white rounded-lg shadow-sm">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider">Subproceso</th>
                <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider">Agrupador</th>
                <th className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider">Tarea</th>
                <th className="px-2 py-3 text-center text-xs font-medium uppercase tracking-wider">Responsable</th>
                <th className="px-2 py-3 text-center text-xs font-medium uppercase tracking-wider">Inicio</th>
                <th className="px-2 py-3 text-center text-xs font-medium uppercase tracking-wider">Término</th>
                <th className="px-2 py-3 text-center text-xs font-medium uppercase tracking-wider">Seguimiento</th>
                <th className="px-2 py-3 text-center text-xs font-medium uppercase tracking-wider">Estado</th>
              </tr>
            </thead>

            <tbody>
              {processTasks
                .filter(task => calculateTaskStatus(task) !== SemaphoreStatus.Gray) // Only show tasks that are not gray
                .map((task, index) => (
                  <tr
                    key={task.id}
                    className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} cursor-pointer hover:bg-gray-100`}
                    onClick={() => handleTaskClick(task)}
                    style={{
                      backgroundColor: task.nombre === 'TRAMITACIÓN' 
                        ? '#E6FFFA' 
                        : task.subproceso 
                          ? `${task.subproceso}10` 
                          : 'white'
                    }}
                  >
                    <td className="px-2 py-3 text-sm">{task.subproceso}</td>
                    <td className="px-2 py-2 text-sm">{task.agrupador}</td>
                    <td className="px-2 py-3 text-sm font-medium">
                      {task.nombre}
                      {task.isClosed && (
                        <Check className="inline-block ml-2 text-green-500 w-4 h-4" />
                      )}
                    </td>
                    <td className="px-2 py-3">{renderResponsibleCell(task)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {formatDate(task.fecha_inicio)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {formatDate(task.fecha_termino)}
                    </td>
                    <td className="px-2 py-2 text-center">{renderFollowUpStatus(task)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <div className="flex justify-center">
                        <div
                          className="w-6 h-6 rounded-full"
                          style={{ backgroundColor: getStatusColor(calculateTaskStatus(task)) }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ))}

      {selectedTask && (
       <TaskModal 
       isOpen={isModalOpen} 
       onClose={() => setIsModalOpen(false)}
       task={selectedTask}
       onChangeTask={handleTaskUpdate}
       backgroundColor="#4B5563"
       allTasks={tasks}
       agrupadorId={selectedTask.id}
       groupProgress={selectedTask.progreso}
       processName={selectedTask.proceso}
       subprocessName={selectedTask.subproceso}
       agrupadorName={selectedTask.agrupador}
       daysDelayed={0}

     />
      )}
    </div>
  );
};

export default ProcessStatusTable;
