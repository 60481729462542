import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Gantt, Task, ViewMode, EventOption, StylingOption, DisplayOption } from 'gantt-task-react-lite';
import "gantt-task-react-lite/dist/index.css";
import { Button } from '../ui/button';
import { ZoomIn, ZoomOut, Maximize, Minimize, Layers, ChevronRight, ChevronDown, Shrink, Expand, LucideZoomIn, LucideZoomOut, X, ChevronUp } from 'lucide-react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { motion, AnimatePresence, progress } from 'framer-motion';



interface ExtendedTask extends Task {
  comments?: string;
  description?: string;
  responsible?: string;
  followUpDate?: string;
  isClosed?: boolean;
  semaphoreStatus?: string;
  duracion?: number;
  subtasks?: any[];
}

enum SemaphoreStatus {
  Gray = "Gray",
  Green = "Green",
  Yellow = "Yellow",
  Red = "Red"
}

const ProgressSlider: React.FC<{
  value: number;
  onChange: (value: number) => void;
  style?: React.CSSProperties;
  ariaLabel?: string;
}> = ({ value, onChange, style, ariaLabel }) => {
  return (
    <Slider
      value={value}
      min={0}
      max={100}
      step={1}
      railStyle={{
        height: 8,
        backgroundColor: '#e0e0e0',
      }}
      handleStyle={{
        width: 16,
        height: 16,
        marginLeft: -8,
        marginTop: -4,
        backgroundColor: '#0044ff',
        border: 'none',
      }}
      trackStyle={{
        backgroundColor: '#0044ff',
      }}
      style={{
        width: '100%',
        ...style,
      }}
    />
  );
};



interface TaskInput {
  id: number;
  name: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  dependsOn: { groupId: number; taskId: number }[];
  color?: string; // Añadido para el color de la tarea
  subtasks?: SubTask[];
  enabled?: boolean;
  isTramitacion?: boolean;
}

interface SubTask {
  id: number;
  name: string;
  responsible: string;
  progress: number;
  start: string;
  end: string;
  duration: number;
  organism: string;
  dependsOn: { groupId: number; taskId: number; subtaskId?: number }[];
  enabled: boolean;
}



interface Group {
  agrupador: string;
  tasks: TaskInput[];
  subprocess: string; // Añadido para el nombre del subproceso
  color?: string; // Añadido para el color del grupo/subproceso
}

interface TasksState {
  name: string;
  groups: Group[];
}
interface GanttChartViewProps {
  tasks: TasksState;
  colors?: { [key: string]: string };  // Nuevo prop para colores personalizados
}


const CustomHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}> = ({ headerHeight, rowWidth, fontFamily, fontSize }) => {
  
  return (
    <div
      className="gantt-task-list-header"
      style={{
        height: headerHeight,
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ height: `${headerHeight}px` }}>
           {/* Columna de posición */}
           <th style={{
              width: '40px',  // Ancho de la columna de posición
              textAlign: 'right',
              paddingLeft: '5px',
              paddingRight: '5px',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              #
            </th>
            {/* Columna de agrupador/tarea con espacio extra para el chevron */}
            <th style={{
  width: `calc(${rowWidth} - 100px)`, // Ajuste para espacio extra
  textAlign: 'center',
              paddingLeft: '5px',
              paddingRight: '20px', // Alineación igual que en las celdas
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              Agrupador/Tarea
            </th>
            {/* Columna de dependencia */}
            <th style={{
              width: '120px',
              textAlign: 'left',
              paddingLeft: '5px',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              Dependencia
            </th>
            {/* Columna desde */}
            <th style={{
              width: '80px',
              textAlign: 'left',
              paddingLeft: '5px',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              Desde
            </th>
            {/* Columna hasta */}
            <th style={{
              width: '120px',
              textAlign: 'left',
              paddingLeft: '25px',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
            }}>
              Hasta
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
};



interface DateSetup {
  dates: Date[];
  // Añade otras propiedades si son necesarias
}
const CustomTaskListTable: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
}> = ({
  rowHeight,
  rowWidth,
  fontFamily,
  fontSize,
  locale,
  tasks,
  selectedTaskId,
  setSelectedTask,
  onExpanderClick,
}) => {
  const [hoveredTaskId, setHoveredTaskId] = useState<string | null>(null);

  // Función para obtener el número de tarea dentro de su grupo
  const getTaskNumberInGroup = (task: Task): number => {
    if (!task.project?.startsWith('group-')) return 0;
    
    const groupId = task.project;
    let currentNumber = 0;
    
    // Recorrer todas las tareas hasta encontrar la actual
    for (let i = 0; i < tasks.length; i++) {
      const currentTask = tasks[i];
      
      // Si encontramos la tarea que estamos buscando, devolver el número actual
      if (currentTask.id === task.id) {
        return currentNumber + 1;
      }
      
      // Incrementar el contador solo para tareas regulares del mismo grupo
      if (currentTask.project === groupId && 
          !currentTask.type?.includes('project') && 
          !currentTask.project?.includes('-')) {
        currentNumber++;
      }
    }
    return currentNumber;
  };

  // Función para generar la numeración jerárquica
  const getTaskNumber = (task: Task, index: number): string => {
    // Si es un grupo principal, no mostrar número
    if (task.type === 'project' && !task.project) {
      return '';
    }

    // Para subtareas de TRAMITACIÓN
    if (task.project && task.project.includes('-')) {
      const [groupId, taskId, subtaskId] = task.id.split('-').map(Number);
      const parentTask = tasks.find(t => t.id === task.project);
      if (parentTask && parentTask.name === 'TRAMITACIÓN') {
        // Obtener el número de la tarea TRAMITACIÓN dentro de su grupo
        const parentNumber = taskId;
        return `${parentNumber}.${subtaskId}`;
      }
    }

    // Para tareas regulares dentro de un grupo
    if (task.project?.startsWith('group-')) {
      const [_, taskId] = task.id.split('-').map(Number);
      return taskId.toString();
    }

    return '';
  };

  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Función para generar colores para los agrupadores
  const getGroupColor = (index: number): string => {
    const colors = ['#FFD700', '#98FB98', '#87CEFA', '#FFA07A', '#DDA0DD'];
    return colors[index % colors.length];
  };

  const darkenColor = (color: string, amount: number = 0.2): string => {
    // Convertir el color hexadecimal a RGB
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    
    // Oscurecer los valores RGB
    r = Math.max(0, Math.min(255, r - Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g - Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b - Math.round(255 * amount)));
    
    // Convertir de vuelta a hexadecimal
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };


  const lightenColor = (color: string, amount: number = 0.2): string => {
    // Convertir el color hexadecimal a RGB
    let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
    
    // Aumentar los valores RGB para aclarar el color
    r = Math.max(0, Math.min(255, r + Math.round(255 * amount)));
    g = Math.max(0, Math.min(255, g + Math.round(255 * amount)));
    b = Math.max(0, Math.min(255, b + Math.round(255 * amount)));
    
    // Convertir de vuelta a hexadecimal
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };
  
  

  const removeAlpha = (color: string): string => {
    // Si el color tiene 8 caracteres (incluyendo alpha), recortar los últimos 2.
    return color.length === 9 ? color.slice(0, 7) : color;
  };
  const formatDependencies = (dependencies: string[] | undefined): string => {
    if (!dependencies || dependencies.length === 0) return '';
    
    return dependencies.map(dep => {
      const parts = dep.split('-');
      const depTask = tasks.find(t => t.id === dep);
      if (!depTask) return '';

      // Si es una subtarea de TRAMITACIÓN
      if (parts.length === 3) {
        const parentTask = tasks.find(t => t.id === depTask.project);
        if (parentTask) {
          const parentNumber = getTaskNumberInGroup(parentTask);
          const subtaskIndex = parseInt(parts[2]);
          return `${parentNumber}.${subtaskIndex}`;
        }
      }
      
      return getTaskNumber(depTask, tasks.indexOf(depTask));
    }).filter(Boolean).join(', ');
  };
  const getTaskBackgroundColor = (task: Task, isHovered: boolean, isSelected: boolean): string => {
    let color;
    
    // Verifica si la tarea es "TRAMITACIÓN"
    if (task.name === 'TRAMITACIÓN') {
        // Establece un color neutro (blanco) para tareas de "TRAMITACIÓN"
        color = '#FFFFFF';
    } else if (task.type === 'project') {
        // Mantiene el color de fondo para encabezados de grupo (proyectos)
        color = task.styles?.backgroundColor || getGroupColor(parseInt(task.id.split('-')[1]));
    } else {
        // Por defecto, color blanco para tareas regulares
        color = '#FFFFFF';
    }

    // Solo aplica estilos de selección y hover a tareas que no sean de tipo "project" ni "TRAMITACIÓN"
    if (task.type !== 'project' && task.name !== 'TRAMITACIÓN') {
        if (isSelected) {
            return darkenColor(lightenColor(color, 0.2), 0.1); // Ajuste para selección
        } else if (isHovered) {
            return darkenColor(lightenColor(color, 0.3), 0.1); // Ajuste para hover
        }
    }

    return color;
};





  let currentGroupIndex = -1;
  let taskIndexInGroup = 0;
  let overallTaskIndex = 0;

  return (
    <div className="gantt-task-list-wrapper" style={{ fontFamily, fontSize }}>
      {tasks.map((task, index) => {
        const isHovered = hoveredTaskId === task.id;
        const isSelected = selectedTaskId === task.id;
        const isTramitacion = task.name === 'TRAMITACIÓN';
        const taskNumber = getTaskNumber(task, index);
        const isGroupTask = task.type === 'project' && !task.project;
        
        // Calcular el padding basado en el nivel de jerarquía
        let paddingLeft = '5px';
        if (task.project?.includes('-') || task.name === 'TRAMITACIÓN') {
          paddingLeft = '45px';
        } else if (task.type !== 'project') {
          paddingLeft = '25px';
        }
        

        return (
          <div
            key={task.id}
            className="gantt-task-list-row"
            style={{
              height: rowHeight,
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #e0e0e0',
              backgroundColor: getTaskBackgroundColor(task, isHovered, isSelected),
              transition: 'background-color 0.3s ease',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              const clickedExpander = (e.target as HTMLElement).closest('.gantt-task-list-expander');
              if (isTramitacion && clickedExpander) {
                onExpanderClick(task);
              } else {
                setSelectedTask(task.id);
                if (task.type === 'project' && !isTramitacion) {
                  onExpanderClick(task);
                }
              }
            }}
            onMouseEnter={() => setHoveredTaskId(task.id)}
            onMouseLeave={() => setHoveredTaskId(null)}
          >
            <div
              className="gantt-task-list-cell"
              style={{
                width: rowWidth,
                display: 'flex',
                alignItems: 'center',
                paddingLeft,
                paddingRight: '20px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              {(task.type === 'project' || task.name === 'TRAMITACIÓN') && (
                <div
                  className="gantt-task-list-expander"
                  style={{ 
                    marginRight: '5px', 
                    cursor: 'pointer',
                    flexShrink: 0 // Evita que el expander se encoja
                  }}
                >
                  {task.hideChildren ? <ChevronRight size={16} /> : <ChevronDown size={16} />}
                </div>
              )}
              <div
                className="gantt-task-list-cell"
                style={{
                  width: '40px',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  flexShrink: 0 // Evita que el número se encoja
                }}
              >
                {taskNumber}
              </div>
              <div
                style={{ 
                  fontWeight: isGroupTask ? 'bold' : 'normal',
                  marginLeft: isGroupTask ? '0' : '10px',
                  whiteSpace: isGroupTask ? 'nowrap' : 'normal',
                  overflow: 'hidden',
                  textOverflow: isGroupTask ? 'ellipsis' : 'clip',
                  flex: 1,
                  // Mostrar el texto completo en tareas normales usando múltiples líneas si es necesario
                  display: isGroupTask ? 'block' : '-webkit-box',
                  WebkitLineClamp: isGroupTask ? 1 : 2,
                  WebkitBoxOrient: 'vertical',
                  lineHeight: isGroupTask ? `${rowHeight}px` : '1.2em',
                  maxHeight: isGroupTask ? rowHeight : (rowHeight - 10), // Dejar un pequeño margen
                }}
                title={task.name} // Mostrar tooltip con el nombre completo
              >
                {task.name}
              </div>
            </div>
            <div 
              className="gantt-task-list-cell" 
              style={{ 
                width: '120px',
                flexShrink: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {formatDependencies(task.dependencies)}
            </div>
            <div 
              className="gantt-task-list-cell" 
              style={{ 
                width: '120px',
                flexShrink: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {formatDate(task.start)}
            </div>
            <div 
              className="gantt-task-list-cell" 
              style={{ 
                width: '120px',
                flexShrink: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {formatDate(task.end)}
            </div>
          </div>
        );
      })}
    </div>
  );
};



const CustomTimelineHeader: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  tickWidth: number;
  startDate: Date;
  endDate: Date;
  dateSetup: DateSetup;
  locale: string;
  headerColor: string;
}> = ({
  headerHeight,
  fontFamily,
  fontSize,
  tickWidth,
  startDate,
  endDate,
  dateSetup,
  locale,
}) => {

  const getWeekNumber = (date: Date): number => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
  };
  
  const getWeekNumberLabel = (date: Date) => {
    const weekNumber = getWeekNumber(date);
    return `${weekNumber}`;
  };

  return (
    <div
      className="gantt-timeline-header"
      style={{
        fontFamily,
        fontSize,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
      }}
    >
      {/* Semanas */}
      <div style={{ display: 'flex', height: headerHeight / 2 }}>
        {dateSetup.dates.map((date: Date, i: number) => (
          <div
            key={date.getTime()}
            style={{
              width: tickWidth,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid #e0e0e0',
              fontWeight: 'bold',
            }}
          >
            {i === 0 || date.getDay() === 1 ? getWeekNumberLabel(date) : ''}
          </div>
        ))}
      </div>
      {/* Días */}
      <div style={{ display: 'flex', height: headerHeight / 2 }}>
        {dateSetup.dates.map((date: Date) => (
          <div
            key={date.getTime()}
            style={{
              width: tickWidth,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid #e0e0e0',
              color: date.getDay() === 0 || date.getDay() === 6 ? '#999' : '#000',
            }}
          >
            {date.getDate()}
          </div>
        ))}
      </div>
    </div>
  );
};


const getTodayStyle = () => {
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0);
  const todayEnd = new Date(todayStart);
  todayEnd.setDate(todayEnd.getDate() + 1);

  return {
    backgroundColor: 'rgba(255, 0, 0, 0.3)',
    width: '2px',
    height: '100%',
    position: 'absolute' as 'absolute',
    zIndex: 1,
    left: 'calc(50% - 1px)',
  };
};


interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  task: ExtendedTask;
  onChangeTask: (task: ExtendedTask) => void;
  backgroundColor: string;
  daysDelayed?: number;
}



const TaskModal: React.FC<TaskModalProps> = ({
  isOpen,
  onClose,
  task,
  onChangeTask,
  backgroundColor,
  daysDelayed = 0
}) => {
  const [comments, setComments] = useState<string>(task.comments || '');
  const [newComment, setNewComment] = useState('');
  const [showComments, setShowComments] = useState(false);
  const [followUpDate, setFollowUpDate] = useState<string>(task.followUpDate || '');
  const [updateTrigger, setUpdateTrigger] = useState(0);


  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { type: 'spring', stiffness: 500, damping: 25 }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: { duration: 0.2 }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    onChangeTask({ ...task, [name]: value });
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (task.isClosed) return;
    onChangeTask({ ...task, [name]: value });
  };

  const handleAddComment = () => {
    if (newComment.trim()) {
      const timestamp = new Date().toLocaleString('es-CL', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
      const commentWithTimestamp = `[${timestamp}] User: ${newComment}`;
      
      let updatedComments = [];
      if (comments) {
        try {
          updatedComments = JSON.parse(comments);
        } catch (error) {
          updatedComments = [];
        }
      }
      
      updatedComments.push(commentWithTimestamp);
      const newCommentsString = JSON.stringify(updatedComments);
      
      setComments(newCommentsString);
      onChangeTask({ ...task, comments: newCommentsString });
      setNewComment('');
    }
  };




  const updateTaskInDatabase = async (updatedTask: ExtendedTask) => {
    try {
      const response = await fetch('http://localhost:3000/php/pages/adm_planificacion/update_task.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: updatedTask.id,
          isClosed: updatedTask.isClosed, // Convertir booleano a número (0 o 1)
          progress: updatedTask.progress,
          end: updatedTask.end,
          start: updatedTask.start, // Aseguramos que se incluya la fecha de inicio
          followUpDate: updatedTask.followUpDate, // Incluir la fecha de seguimiento si existe
          comments: updatedTask.comments, // Asegurar que los comentarios también se guarden
          semaphoreStatus: updatedTask.semaphoreStatus // Aseguramos que se envíe el estado del semáforo
        }),
      });
  
      if (!response.ok) {
        const errorMessage = await response.text(); // Obtener el mensaje de error si la respuesta no es OK
        throw new Error(`Error en la actualización: ${errorMessage}`);
      }
  
      const result = await response.json(); // Asumimos que el backend responde con JSON
      console.log('Tarea actualizada con éxito:', result.message);
  
    } catch (error) {
      console.error('Error al actualizar la tarea:', error);
      throw error; // Volver a lanzar el error para manejarlo en otros niveles si es necesario
    }
  };
  

  
  
  const handleOpenTask = async () => {
    try {
      const startDate = new Date(task.start);
      const duration = task.duracion || 7; // Default duration if not specified
      const newEndDate = new Date(startDate.getTime() + duration * 7 * 24 * 60 * 60 * 1000);

      const updatedTask: ExtendedTask = {
        ...task,
        isClosed: false,
        progress: 0,
        end: newEndDate,
        start: startDate
      };

      await updateTaskInDatabase(updatedTask);
      onChangeTask(updatedTask);
      setUpdateTrigger(prev => prev + 1);
    } catch (error) {
      console.error('Error al abrir la tarea:', error);
      alert('Hubo un error al abrir la tarea. Por favor, inténtelo de nuevo.');
    }
  };
const handleCloseTask = async () => {
  if (task.semaphoreStatus === SemaphoreStatus.Gray && !task.isClosed) {
    alert("No se puede cerrar una tarea que aún no ha iniciado.");
    return;
  }

  if (task.name === 'TRAMITACIÓN' && task.subtasks) {
    const hasOpenSubtasks = task.subtasks.some(subtask => !subtask.isClosed);
    if (hasOpenSubtasks) {
      alert("No se puede cerrar la tarea de TRAMITACIÓN mientras tenga subtareas abiertas.");
      return;
    }
  }

  const today = new Date();
  const updatedTask: ExtendedTask = {
    ...task,
    isClosed: true,
    progress: 100,
    end: today,
    start: task.start
  };

  try {
    await updateTaskInDatabase(updatedTask);
    onChangeTask(updatedTask);
  } catch (error) {
    console.error('Error al cerrar la tarea:', error);
    alert('Hubo un error al cerrar la tarea. Por favor, inténtelo de nuevo.');
  }
};

  const handleSaveChanges = async () => {
    try {
      const updatedTask = {
        ...task,
        comments,
        followUpDate,
      };
      onChangeTask(updatedTask);
      onClose();
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
      alert('Hubo un error al guardar los cambios. Por favor, inténtelo de nuevo.');
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <motion.div
            className="bg-white rounded-lg w-full max-w-2xl"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div className="text-white p-4 flex justify-between items-center rounded-t-lg" style={{ backgroundColor }}>
              <h2 className="text-xl font-bold">{task.name}</h2>
              <button onClick={onClose} className="text-white hover:text-gray-200">
                <X size={24} />
              </button>
            </div>
            <form className="p-6">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Estado</label>
                {daysDelayed > 0 && !task.isClosed ? (
                  <p className="text-red-600 font-bold">
                    Retrasada por {daysDelayed} día(s)
                  </p>
                ) : (
                  <p className="text-green-600 font-bold">A tiempo</p>
                )}
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Tarea</label>
                  <input
                    type="text"
                    name="description"
                    value={task.description || ''}
                    onChange={handleInputChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    disabled={task.isClosed}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Responsable</label>
                  <input
                    type="text"
                    name="responsible"
                    value={task.responsible || ''}
                    onChange={handleInputChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    disabled={task.isClosed}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha Esperada de Inicio</label>
                  <input
                    type="date"
                    name="start"
                    value={task.start.toISOString().split('T')[0]}
                    onChange={handleDateChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    disabled={task.isClosed}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Fecha Esperada de Término</label>
                  <input
                    type="date"
                    name="end"
                    value={task.end.toISOString().split('T')[0]}
                    onChange={handleDateChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    disabled={task.isClosed}
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="flex items-center mb-2">
                  <div
                    className="flex items-center cursor-pointer text-indigo-600 hover:text-indigo-800"
                    onClick={() => setShowComments(!showComments)}
                  >
                    <label className="cursor-pointer block text-sm font-medium text-gray-700 mr-2">
                      Comentarios
                    </label>
                    {showComments ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </div>
                </div>
              </div>
              <AnimatePresence>
                {showComments && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden"
                  >
                    <div className="mt-1 border border-gray-300 rounded-md p-2 max-h-40 overflow-y-auto">
                      {comments && (
                        (() => {
                          try {
                            const parsedComments = JSON.parse(comments);
                            return Array.isArray(parsedComments) && parsedComments.length > 0 ? (
                              parsedComments.map((comment, index) => {
                                const [datePart, userPart] = comment.split('] User:');
                                const date = datePart.replace('[', '').trim();
                                const content = userPart.trim();
                                
                                return (
                                  <div key={index} className="mb-2">
                                    <p className="text-xs text-gray-500">{date}</p>
                                    <p className="text-sm">{content}</p>
                                  </div>
                                );
                              })
                            ) : (
                              <p>No hay comentarios</p>
                            );
                          } catch (error) {
                            return <p>Error al cargar comentarios</p>;
                          }
                        })()
                      )}
                    </div>
                    <div className="mt-2 flex">
                      <input
                        type="text"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        className="flex-grow border focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-l-md"
                        placeholder="Agregar comentario..."
                        disabled={task.isClosed}
                      />
                      <button
                        type="button"
                        onClick={handleAddComment}
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        disabled={task.isClosed}
                      >
                        Agregar
                      </button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="mt-4 flex justify-between">
                <Button
                  type="button"
                  variant={task.isClosed ? "outline" : "destructive"}
                  onClick={task.isClosed ? handleOpenTask : handleCloseTask}
                  disabled={!task.isClosed && task.semaphoreStatus === "Gray"}
                >
                  {task.isClosed ? "ABRIR TAREA" : "CERRAR TAREA"}
                </Button>
                <Button
                  type="button"
                  onClick={handleSaveChanges}
                >
                  Guardar Cambios
                </Button>
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};



const ensureMinimumDuration = (start: Date, end: Date): Date => {
  // Si las fechas son iguales o la duración es menor a un día
  const duration = end.getTime() - start.getTime();
  if (duration <= 0) {
    // Crear una nueva fecha de fin que sea el mismo día pero algunas horas después
    const newEnd = new Date(start);
    newEnd.setHours(start.getHours() + 20); // Añadir 8 horas para que sea visible
    return newEnd;
  }
  return end;
};


const GanttChartView: React.FC<GanttChartViewProps> = ({ tasks, colors = {} }) => {
  const [zoom, setZoom] = useState(100);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Week);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [isGrouped, setIsGrouped] = useState(true);

      // Inicializa expandedGroups con todos los grupos expandidos desde el inicio
  const initialExpandedGroups = useMemo(() => {
    return new Set(tasks.groups.map((_, index) => `group-${index}`));
  }, [tasks.groups]);

  const initialExpandedSubgroups = useMemo(() => {
    const subgroups = new Set<string>();
    tasks.groups.forEach((group, groupIndex) => {
      group.tasks.forEach((task, taskIndex) => {
        if (task.name === 'TRAMITACIÓN') {
          subgroups.add(`${groupIndex}-${taskIndex + 1}`);
        }
      });
    });
    return subgroups;
  }, [tasks]);

  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(initialExpandedGroups);
  const [expandedSubgroups, setExpandedSubgroups] = useState<Set<string>>(initialExpandedSubgroups);


  const [areAllGroupsExpanded, setAreAllGroupsExpanded] = useState(true);

    const ganttRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectedTaskId, setSelectedTaskId] = useState<string>('');
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    
    
    const handleTaskChange = (updatedTask: Task) => {
      // Here you would implement the logic to update the task
      // This might involve updating your state or making an API call
      console.log('Task updated:', updatedTask);
    };
  

    const getTaskColor = (task: TaskInput, allTasks: TaskInput[], groupColor: string): string | Object => {
      const today = new Date();
      const start = new Date(task.start);
      const end = new Date(task.end);
      const daysUntilEnd = Math.ceil((end.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
  
      // Verificar si todas las tareas predecesoras están completadas
      const allPredecessorsCompleted = task.dependsOn.every(dep => {
        const predecessorTask = allTasks.find(t => t.id === dep.taskId);
        return predecessorTask && predecessorTask.progress === 100;
      });
  
      // Si hay predecesoras incompletas y la tarea aún no ha comenzado, se pinta de gris
      if (!allPredecessorsCompleted && today < start) {
        return '#AEB8C2'; // Gris para tareas con predecesoras incompletas
      }
  
      // Aplicar las condiciones originales para todas las demás situaciones
      if (today > end && task.progress === 100) {
        return groupColor; // Color del subproceso para tareas finalizadas

        // return '#48D2D4'; // Finalizada
      } else if (today < start)  {
        return '#AEB8C2'; // Gris para tareas no iniciadas
      } else if (today > end) {
        return '#EF4444'; // Rojo para tareas vencidas
      } else if (daysUntilEnd <= 2) {
        return '#FB923C'; // Naranja para tareas a punto de vencer (2 días o menos)
      } else if (daysUntilEnd <= 5) {
        return '#FDE047'; // Amarillo para tareas por vencer (5 días o menos)
      } else {
        return '#4ADE80'; // Verde para tareas en progreso
      }
    };

    const getTaskProgress = (groupIndex: number, taskPosition: number): number | null => {
      const group = tasks.groups[groupIndex];  // Obtener el grupo basado en el índice
    
      if (!group) {
        console.error(`No se encontró el grupo con índice ${groupIndex}`);
        return null; // Retorna null si no se encuentra el grupo
      }
    
      // Verificar que la posición sea válida dentro del grupo
      if (taskPosition < 1 || taskPosition > group.tasks.length) {
        console.error(`La posición de la tarea ${taskPosition} está fuera de rango en el grupo ${groupIndex}`);
        return null; // Retorna null si la posición está fuera de rango
      }
    
      const task = group.tasks[taskPosition - 1]; // Las posiciones empiezan en 1, así que restamos 1 para obtener el índice correcto
    
      return task.progress; // Retornar el progreso de la tarea
    };
    

    

    const getDefaultColor = (index: number): string => {
      const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#DDA0DD', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
      return colors[index % colors.length];
    };

    
    // Función para convertir fecha de string a Date en la zona horaria local
  // Update the date parsing function to handle invalid dates
 // Update the date parsing function to handle invalid dates
 const parseLocalDate = (dateString: string): Date => {
  if (!dateString) {
    // Return a default date if dateString is undefined or empty
    return new Date();
  }

  const [year, month, day] = dateString.split('-').map(Number);
  
  // Validate date components
  if (!year || !month || !day || isNaN(year) || isNaN(month) || isNaN(day)) {
    console.warn(`Invalid date string: ${dateString}, using current date`);
    return new Date();
  }

  const localDate = new Date(year, month - 1, day);
  
  // Check if date is valid
  if (isNaN(localDate.getTime())) {
    console.warn(`Invalid date created from: ${dateString}, using current date`);
    return new Date();
  }

  return localDate;
};
  


    const darkenColor = (color: string, amount: number = 0.2): string => {
      // Convertir el color hexadecimal a RGB
      let [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
      
      // Oscurecer los valores RGB
      r = Math.max(0, Math.min(255, r - Math.round(255 * amount)));
      g = Math.max(0, Math.min(255, g - Math.round(255 * amount)));
      b = Math.max(0, Math.min(255, b - Math.round(255 * amount)));
      
      // Convertir de vuelta a hexadecimal
      return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
    };

    
      const chartData: Task[] = useMemo(() => {
        const today = new Date();
      
        const processSubtask = (subtask: SubTask, taskId: string, subtaskIndex: number, groupColor: string): Task => {
          // Ensure valid dates for subtask
          let start = parseLocalDate(subtask.start);
          let end = parseLocalDate(subtask.end);
          
          start.setHours(0, 0, 0, 0);
          end = ensureMinimumDuration(start, end);


          // Ensure minimum duration
          const duration = end.getTime() - start.getTime();
          if (duration < 0) {
            end.setTime(start.getTime() + (24 * 60 * 60 * 1000)); // Set minimum 1 day duration
          }
    
      
          // Aplica la lógica del semáforo para obtener el color de la subtarea
          const subtaskColor = getTaskColor(subtask, tasks.groups.flatMap(group => group.tasks), groupColor);
      
          return {
              id: `${taskId}-${subtaskIndex + 1}`,
              type: 'task',
              name: subtask.name,
              start,
              end,
              progress: subtask.progress,
              project: taskId,
              dependencies: subtask.dependsOn.map(dep => 
                  dep.subtaskId 
                      ? `${taskId}-${dep.taskId}-${dep.subtaskId}`
                      : `${taskId}-${dep.taskId}`
              ),
              isDisabled: !subtask.enabled,
              styles: typeof subtaskColor === 'string'
                  ? {
                      progressColor: subtaskColor,
                      progressSelectedColor: subtaskColor,
                      backgroundColor: `${subtaskColor}66`,
                      backgroundSelectedColor: `${subtaskColor}aa`,
                  }
                  : {
                      ...subtaskColor,
                      progressColor: 'transparent',
                      progressSelectedColor: 'transparent',
                  }
          };
      };
      
      if (isGrouped) {
        return tasks.groups.flatMap((group, groupIndex): Task[] => {
          const groupColor = group.color || getDefaultColor(groupIndex);
    
          const groupTasks = group.tasks.map((task, taskIndex): Task[] => {
            // Ensure valid dates for task
            let start = parseLocalDate(task.start);
            let end = parseLocalDate(task.end);
            
            start.setHours(0, 0, 0, 0); 
            end = ensureMinimumDuration(start, end);

            
            // Ensure minimum duration
            const duration = end.getTime() - start.getTime();
            if (duration < 0) {
              end.setTime(start.getTime() + (24 * 60 * 60 * 1000)); // Set minimum 1 day duration
            }


              const isCompletedAndFinished = task.progress === 100 && today > end;
              let taskColor = getTaskColor(task, group.tasks, groupColor);
      
              task.dependsOn.forEach(dep => {
                const progresoDependencia = getTaskProgress(groupIndex, dep.taskId);
                if (progresoDependencia !== null && progresoDependencia !== undefined && progresoDependencia < 100) {
                  taskColor = '#AEB8C2';
                }
              });
      
              const mainTask: Task = {
                id: `${groupIndex}-${taskIndex + 1}`,
                name: task.name,
                start,
                end,
                progress: task.progress,
                type: task.isTramitacion ? 'project' : 'task',
                project: `group-${groupIndex}`,
                dependencies: task.dependsOn.map(dep => `${groupIndex}-${dep.taskId}`),
                styles: typeof taskColor === 'string'
                  ? {
                    progressColor: taskColor,
                    progressSelectedColor: taskColor,
                    backgroundColor: `${taskColor}66`,
                    backgroundSelectedColor: `${taskColor}aa`,
                  }
                  : {
                    ...taskColor,
                    progressColor: 'transparent',
                    progressSelectedColor: 'transparent',
                  },
                hideChildren: task.isTramitacion ? !expandedSubgroups.has(`${groupIndex}-${taskIndex + 1}`) : undefined,
              };
      
              // Si es una tarea de TRAMITACIÓN y tiene subtareas, procesar las subtareas
              if (task.isTramitacion && task.subtasks) {
                const subtasks = task.subtasks.map((subtask, subtaskIndex) => 
                  processSubtask(subtask, mainTask.id, subtaskIndex, groupColor)
                );
                return [mainTask, ...subtasks];
              }
      
              return [mainTask];
            }).flat(); // Aplanar el array resultante
      


            // Ensure valid group dates
            const validGroupTasks = groupTasks.filter(task => 
              task.start instanceof Date && 
              task.end instanceof Date && 
              !isNaN(task.start.getTime()) && 
              !isNaN(task.end.getTime())
            );
    
            if (validGroupTasks.length === 0) {
              console.warn(`No valid tasks found for group ${groupIndex}`);
              return [];
            }
    
            const groupStart = new Date(Math.min(...validGroupTasks.map(task => task.start.getTime())));
            const groupEnd = new Date(Math.max(...validGroupTasks.map(task => task.end.getTime())));
            groupStart.setHours(0, 0, 0, 0);
            groupEnd.setHours(23, 59, 0, 0);
            const groupProgress = groupTasks.reduce((sum, task) => sum + task.progress, 0) / groupTasks.length;
      
            const groupTask: Task = {
              id: `group-${groupIndex}`,
              name: group.agrupador,
              start: groupStart,
              end: groupEnd,
              progress: groupProgress,
              type: 'project',
              hideChildren: !expandedGroups.has(`group-${groupIndex}`),
              isDisabled: false,
              project: undefined,
              styles: {
                progressColor: groupColor,
                progressSelectedColor: darkenColor(groupColor),
                backgroundColor: groupColor,
                backgroundSelectedColor: darkenColor(groupColor),
              },
              dependencies: [],
            };
      
            return expandedGroups.has(`group-${groupIndex}`) ? [groupTask, ...groupTasks] : [groupTask];
          });
        } else {
          return tasks.groups.flatMap((group, groupIndex) =>
            group.tasks.map((task): Task => {
              // Ensure valid dates
              let start = parseLocalDate(task.start);
              let end = parseLocalDate(task.end);
              
                
          // Aplicar la misma lógica de duración mínima
          start.setHours(0, 0, 0, 0);
          end = ensureMinimumDuration(start, end);

              // Ensure minimum duration
              const duration = end.getTime() - start.getTime();
              if (duration < 0) {
                end.setTime(start.getTime() + (24 * 60 * 60 * 1000)); // Set minimum 1 day duration
              }
    
              const groupColor = group.color || getDefaultColor(groupIndex);



              let taskColor = getTaskColor(task, group.tasks, groupColor);

      
              // Comprobar el progreso de las dependencias
              task.dependsOn.forEach(dep => {
                const progresoDependencia = getTaskProgress(groupIndex, dep.taskId);
                if (progresoDependencia !== null && progresoDependencia !== undefined && progresoDependencia < 100) {
                  taskColor = 'gray'; // Cambiar color a gris si alguna dependencia no está completa
                }
              });
      
              return {
                id: `${groupIndex}-${task.id}`,
                name: task.name,
                start,
                end,
                progress: task.progress,
                type: 'task',
                project: group.agrupador,
                dependencies: task.dependsOn.map(dep => `${groupIndex}-${dep.taskId}`),
                styles: typeof taskColor === 'string'
                  ? {
                    progressColor: taskColor,
                    progressSelectedColor: taskColor,
                    backgroundColor: `${taskColor}66`,
                    backgroundSelectedColor: `${taskColor}aa`,
                  }
                  : {
                    ...taskColor,
                    progressColor: 'transparent',
                    progressSelectedColor: 'transparent',
                  },
              };
            })
          );
        }
      }, [tasks, isGrouped, expandedGroups, expandedSubgroups, colors]);
      
    
    
    const handleZoomChange = useCallback((value: number | number[]) => {
      if (Array.isArray(value)) {
        setZoom(value[0]);  // Si es un array, usamos el primer valor
      } else {
        setZoom(value);  // Si es un número, lo usamos directamente
      }
    }, []);
    

    const handleViewModeChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
      setViewMode(e.target.value as ViewMode);
    }, []);

    

    const toggleAllGroups = () => {
      if (areAllGroupsExpanded) {
        setExpandedGroups(new Set()); // Contrae todos los grupos
      } else {
        const allGroupIds = tasks.groups.map((_, index) => `group-${index}`);
        setExpandedGroups(new Set(allGroupIds)); // Expande todos los grupos
      }
      setAreAllGroupsExpanded(!areAllGroupsExpanded);
    };
    const toggleFullScreen = () => {
      if (!document.fullscreenElement) {
        containerRef.current?.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    };

    const handleTaskSelect = (task: Task) => {
      if (task.name === 'TRAMITACIÓN') {
        // Si es TRAMITACIÓN, no mostrar el modal al hacer clic en la tarea
        return;
      }
      setSelectedTask(task);
      setIsModalOpen(true);
      setSelectedTaskId(task.id);
    };

    useEffect(() => {
      // Escuchar cambios en el tamaño del gráfico y redibujarlo
      const handleResize = () => {
        if (ganttRef.current) {
          ganttRef.current.scrollTop = 0; // Reajusta el scroll vertical
          ganttRef.current.scrollLeft = 0; // Reajusta el scroll horizontal
          window.dispatchEvent(new Event('resize')); // Forza un evento de resize
        }
      };
    
      // Escuchar evento de redibujado o expansión/colapso
      const observer = new MutationObserver(handleResize);
      if (ganttRef.current) {
        observer.observe(ganttRef.current, { childList: true, subtree: true });
      }
    
      return () => observer.disconnect();
    }, [expandedGroups, expandedSubgroups]);
    
  
    const handleExpanderClick = (task: Task) => {
      if (task.type === 'project') {
          if (task.name === 'TRAMITACIÓN') {
              setExpandedSubgroups(prev => {
                  const newSet = new Set(prev);
                  if (newSet.has(task.id)) {
                      newSet.delete(task.id);
                  } else {
                      newSet.add(task.id);
                  }
                  return newSet;
              });
  
              // Forzar un redibujo
              requestAnimationFrame(() => {
                  if (ganttRef.current) {
                      ganttRef.current.style.transform = 'translateZ(0)';
                      setTimeout(() => {
                          if (ganttRef.current) {
                              ganttRef.current.style.transform = '';
                          }
                      }, 10);
                  }
              });
          } else {
              setExpandedGroups(prev => {
                  const newSet = new Set(prev);
                  if (newSet.has(task.id)) {
                      newSet.delete(task.id);
                  } else {
                      newSet.add(task.id);
                  }
                  return newSet;
              });
          }
      }
  };

  
const scrollToBottom = () => {
  if (ganttRef.current) {
      ganttRef.current.scrollTop = ganttRef.current.scrollHeight;
  }
};

useEffect(() => {
  scrollToBottom();
}, [expandedGroups, expandedSubgroups]);

    

    const toggleGrouping = () => {
      setIsGrouped(prev => !prev);
      setExpandedGroups(new Set());
    };

    const toggleGroup = (groupId: string) => {
      setExpandedGroups(prev => {
        const newSet = new Set(prev);
        if (newSet.has(groupId)) {
          newSet.delete(groupId);
        } else {
          newSet.add(groupId);
        }
        return newSet;
      });
    };

    // We no longer need handleExpanderClick as the expansion is handled by the chevron click

    useEffect(() => {
      const handleFullScreenChange = () => {
        setIsFullScreen(!!document.fullscreenElement);
      };

      document.addEventListener('fullscreenchange', handleFullScreenChange);

      return () => {
        document.removeEventListener('fullscreenchange', handleFullScreenChange);
      };
    }, []);

    
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
      if (isDragging && ganttRef.current) {
        const dx = e.clientX - dragStart.x;
        const dy = e.clientY - dragStart.y;
        ganttRef.current.scrollLeft -= dx;
        ganttRef.current.scrollTop -= dy;
        setDragStart({ x: e.clientX, y: e.clientY });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleWheel = useCallback((e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        const delta = e.deltaY < 0 ? 10 : -10; // Invertimos la dirección para un zoom más intuitivo
        setZoom(prevZoom => {
          const newZoom = Math.min(Math.max(prevZoom + delta, 50), 200);
          return newZoom;
        });
      }
    }, []);
  
    // Cambiamos el useEffect para usar el callback memorizado
    useEffect(() => {
      const ganttElement = ganttRef.current;
      
      const wheelHandler = (e: WheelEvent) => {
        if (e.ctrlKey) {
          e.preventDefault();
          handleWheel(e);
        }
      };
  
      if (ganttElement) {
        // Usamos 'wheel' en lugar de 'mousewheel' para mejor compatibilidad
        ganttElement.addEventListener('wheel', wheelHandler, { passive: false });
      }
  
      return () => {
        if (ganttElement) {
          ganttElement.removeEventListener('wheel', wheelHandler);
        }
      };
    }, [handleWheel]);
    const formatDate = (date: Date): string => {
      return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };

    const getWeekNumber = (date: Date): number => {
      const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
    };


    const columnWidth = useMemo(() => {
      return viewMode === ViewMode.Month ? 300 * (zoom / 100) : 65 * (zoom / 100);
  }, [zoom, viewMode]);

    const formatTimeTick = (date: Date): string => {
      const formatted = formatDate(date);
      if (viewMode === ViewMode.Week) {
        const weekNumber = getWeekNumber(date);
        return `S${String(weekNumber).padStart(2, '0')} - ${formatted}`;
      }
      return formatted;
    };

    useEffect(() => {
      const style = document.createElement('style');
      style.textContent = `
        .gantt-today-highlight {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 2px !important;
          background-color: rgba(255, 0, 0, 0.5) !important;
          z-index: 1;
        }
      `;
      document.head.appendChild(style);
    
      return () => {
        document.head.removeChild(style);
      };
    }, []);

    const totalTasks = useMemo(() => {
      return tasks.groups.reduce((total, group) => total + group.tasks.length + 1, 0);
  }, [tasks]);


  const FloatingActionBar = () => (
    <div className="fixed right-4 bottom-0 transform -translate-y-1/2 flex flex-col space-y-2 z-50">
      
    
    </div>
  );


  const immobileBarStyles = `
    .gantt-task-bar {
      pointer-events: none !important;
      user-select: none !important;
      -webkit-user-select: none !important;
      -moz-user-select: none !important;
      -ms-user-select: none !important;
    }
    .gantt-task-milestone {
      pointer-events: none !important;
    }
    .gantt-task-info {
      pointer-events: auto !important;
      cursor: pointer !important;
    }
    .gantt-task-handle {
      display: none !important;
    }
    .gantt-task-resize {
      display: none !important;
    }
    .gantt-task-progress-handle {
      display: none !important;
    }
    .gantt-task-bar:hover {
      transform: none !important;
    }
    .gantt-task-bar-main {
      cursor: default !important;
    }
  `;

    return (
      <div 
      ref={containerRef} 
      className={`gantt-container flex flex-col bg-white rounded-lg shadow-lg ${
        isFullScreen ? 'fixed inset-0 z-50' : ''
      }`}
    >
            <style>{immobileBarStyles}</style>

      <div className="flex justify-between items-center mb-4 p-4 border-b">
      <div className="flex items-center space-x-4">
  <ZoomOut 
    className="w-6 h-6 cursor-pointer" 
    onClick={() => handleZoomChange(zoom - 10)} 
  />
  <Slider
    value={zoom}
    min={50}
    max={200}
    step={10}
    onChange={handleZoomChange}
    className="w-40"
  />
  <ZoomIn 
    className="w-6 h-6 cursor-pointer" 
    onClick={() => handleZoomChange(zoom + 10)} 
  />
  <div className="ml-10 flex space-x-2">
  <Button onClick={toggleAllGroups} variant="outline" size="sm">
            {areAllGroupsExpanded ? <Shrink className="w-4 h-4 mr-2" /> : <Expand className="w-4 h-4 mr-2" />}
            {areAllGroupsExpanded ? 'Colapsar Todos' : 'Expandir Todos'}
          </Button>
          <Button onClick={toggleFullScreen} variant="outline" size="sm">
            {isFullScreen ? <Minimize className="w-4 h-4 mr-2" /> : <Maximize className="w-4 h-4 mr-2" />}
            {isFullScreen ? 'Salir de Pantalla Completa' : 'Pantalla Completa'}
          </Button>
  </div>


        </div>
        <select
          value={viewMode}
          onChange={handleViewModeChange}
          className="border p-2 rounded-md bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value={ViewMode.Day}>Día</option>
          <option value={ViewMode.Week}>Semana</option>
          <option value={ViewMode.Month}>Mes</option>
          <option value={ViewMode.Year}>Año</option>
        </select>
      </div>
      <div 
        ref={ganttRef}
        className="flex-grow overflow-auto"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <Gantt
tasks={chartData.map(task => ({
  ...task,
  isDisabled: true, // Deshabilitar cada tarea individualmente
}))}            viewMode={viewMode}
            locale="es"
            listCellWidth="250px"
            columnWidth={columnWidth}
            ganttHeight={Math.max(isFullScreen ? window.innerHeight - 150 : 391)}
            headerHeight={50}
            rowHeight={40}
            barCornerRadius={5}
            projectProgressColor="#3B82F6"
            barProgressColor="#F59E0B"
            barProgressSelectedColor="#FBBF24"
            barBackgroundColor="#E5E7EB"
            barBackgroundSelectedColor="#D1D5DB"
            handleWidth={0}
            timeStep={86400000}
            arrowColor="#6B7280"
            fontFamily="Inter, sans-serif"
            fontSize="14px"
            weekPrefix=""
            todayColor="rgba(239, 68, 68, 0.1)"
            todayLineColor="rgba(239, 68, 68, 0.9)"
            TaskListHeader={CustomHeader}
            TaskListTable={CustomTaskListTable}
            TooltipContent={({ task }) => (
              <div className="bg-white p-3 rounded-lg shadow-lg">
                <h3 className="font-bold text-lg mb-2">{task.name}</h3>
                <p className="mb-1"><span className="font-semibold">Inicio:</span> {formatDate(task.start)}</p>
                <p className="mb-1"><span className="font-semibold">Fin:</span> {formatDate(task.end)}</p>
                <p className="mb-1"><span className="font-semibold">Progreso:</span> {task.progress.toFixed(2)}%</p>
                <p><span className="font-semibold">Estado:</span> {
                  task.styles?.progressColor === '#E5E7EB' ? 'No iniciada' :
                  task.styles?.progressColor === '#4ADE80' ? 'En progreso' :
                  task.styles?.progressColor === '#FDE047' ? 'Por vencer' :
                  task.styles?.progressColor === '#FB923C' ? 'A punto de vencer' :
                  task.styles?.progressColor === '#EF4444' ? 'Vencida' :
                  'Completada'
                }</p>
              </div>
            )}
           // Deshabilitar la edición de las barras
           onDateChange={() => {}}
           onProgressChange={() => {}}
           onDoubleClick={() => {}}
           onSelect={handleTaskSelect}
           onExpanderClick={handleExpanderClick}
          />
        </div>
      </div>
      {selectedTask && (
          <TaskModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          task={selectedTask as ExtendedTask}
          onChangeTask={handleTaskChange}
          backgroundColor={selectedTask.styles?.backgroundColor || '#3B82F6'}
          daysDelayed={0}
        />
      )}
    </div>
  );
};
export default GanttChartView;

