import { AlertCircle, ChevronDown, ChevronsDown, ChevronsUp, ChevronUp, FileQuestion } from 'lucide-react';
import React, { useState, useEffect, useRef } from 'react';
import dateUtils from './dateUtils';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface Process {
  nombreProceso: string;
  codigo: string;
  comuna: string;
  subprocesos?: string[];
  plantilla: string;
}

interface Subproceso {
  id: number;
  subproceso: string;
  color: string;
}

interface Task {
  id: number;
  nombre: string;
  responsable: string;
  progreso: number;
  fecha_inicio: string;
  fecha_termino: string;
  descriptor: string;
  duracion: number;
  organismo: string;
  dependencia: string;
  enabled: boolean;
  isClosed: boolean;
  subtasks?: SubTask[];
}

interface SubTask {
  id: number;
  nombre: string;
  responsible: string;
  progress: number;
  fecha_inicio: string;
  fecha_termino: string;
  duracion: number;
  organismo: string;
  dependsOn: { groupId: number; taskId: number; subtaskId: number }[];
  enabled: boolean;
  tipo: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN' | 'OTROS';
  resolutionType?: 'APROBADO' | 'RECHAZADO' | 'DESISTIMIENTO' | 'SILENCIO ADMINISTRATIVO POSITIVO' | 'SILENCIO ADMINISTRATIVO NEGATIVO' | 'OTRO';
  orden: number;
  comments: string;
  followUpDate: string;
  followUp: boolean;
  isClosed: boolean;
  delayInDays: number;
}

interface DataItem {
  name: string;
  agrupador: string;
  tiempoTranscurrido: string;
  plazoTitular: number;
  plazoServicio: number;
  fechaInicio: string;
  fechaTermino: string;
}

interface TableRowProps {
  item: DataItem;
}

interface CollapsibleHeaderProps {
  title: string;
  color: string;
  isOpen: boolean;
  onToggle: () => void;
}

interface CollapsibleSectionProps {
  title: string;
  data: DataItem[];
  onTitleChange: (newTitle: string) => void;
}

interface DataChartProps {
  data: DataItem[];
  title: string;
  onTitleChange: (newTitle: string) => void;
}

interface RegistrationProtocolProps {
  selectedProcess: string;
  processes: Process[];
}


const NoTramitacionState = () => {
  return (
    <div className="flex flex-col items-center justify-center p-6 bg-gray-50 rounded-lg border border-gray-200">
      <AlertCircle className="w-12 h-12 text-amber-500 mb-3" />
      <div className="text-center">
        <h4 className="text-lg font-medium text-gray-900 mb-2">
          Sin datos de tramitación
        </h4>
        <p className="text-sm text-gray-600">
          No se encontró información de tramitación para este agrupador.
        </p>
      </div>
    </div>
  );
};

const EmptyState = () => {
  return (
    <div className="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
      <FileQuestion className="w-16 h-16 text-gray-400 mb-4" />
      <div className="text-center">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          No hay datos disponibles
        </h3>
        <p className="text-gray-600">
          No se encontraron registros para mostrar en este momento. Por favor, verifica los filtros aplicados o intenta más tarde.
        </p>
      </div>
    </div> 
  );
};

const getDefaultColor = (id: number): string => {
  const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F7DC6F', '#BB8FCE', '#5DADE2', '#45B39D', '#EC7063'];
  return colors[id % colors.length];
};

const formatDate = (dateString: string): string => {
  if (!dateString) return 'No disponible';
  const [year, month, day] = dateString.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  if (isNaN(date.getTime())) return 'No disponible';
  
  const formattedDay = date.getDate().toString().padStart(2, '0');
  const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
  const formattedYear = date.getFullYear();
  
  return `${formattedDay}-${formattedMonth}-${formattedYear}`;
};

const calculateDaysElapsed = (startDate: string, endDate: string, isClosed: boolean): string => {
  if (!startDate) return 'N/A';
  
  const today = new Date();
  const [startYear, startMonth, startDay] = startDate.split('-').map(Number);
  const start = new Date(startYear, startMonth - 1, startDay);
  
  let compareDate = today;
  if (isClosed && endDate) {
    const [endYear, endMonth, endDay] = endDate.split('-').map(Number);
    compareDate = new Date(endYear, endMonth - 1, endDay);
  }
  
  start.setHours(0, 0, 0, 0);
  compareDate.setHours(0, 0, 0, 0);
  
  if (isNaN(start.getTime())) return 'N/A';
  
  const diffTime = compareDate.getTime() - start.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
  return `${diffDays} días`;
};

const calculateDaysBetween = (startDate: string, endDate: string): number => {
  if (!startDate || !endDate) return 0;
  
  const start = new Date(startDate);
  const end = new Date(endDate);
  
  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);
  
  if (isNaN(start.getTime()) || isNaN(end.getTime())) return 0;
  
  const diffTime = Math.abs(end.getTime() - start.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

const TableRow: React.FC<TableRowProps> = ({ item }) => (
  <tr className="border-b border-gray-200">
    <td className="py-2 px-4">
      <span title={`Fecha de inicio: ${item.fechaInicio} \nFecha de término: ${item.fechaTermino}`} className="cursor-help">
        {item.tiempoTranscurrido}
      </span>
    </td>
    <td className="py-2 px-4">{item.agrupador}</td>
    <td className="py-2 px-4">{item.plazoTitular}</td>
    <td className="py-2 px-4">{item.plazoServicio}</td>
  </tr>
);

const CollapsibleHeader: React.FC<CollapsibleHeaderProps> = ({
  title,
  color,
  isOpen,
  onToggle,
}) => (
  <div 
    style={{ backgroundColor: color }}
    className="p-4 flex justify-between items-center cursor-pointer rounded-t-lg"
    onClick={onToggle}
  >
    <h3 className="font-bold text-lg">{title}</h3>
    {isOpen ? (
      <ChevronUp className="w-6 h-6" />
    ) : (
      <ChevronDown className="w-6 h-6" />
    )}
  </div>
);
const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  data = [],
  onTitleChange,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  if (data.length === 0) {
    return <NoTramitacionState />;
  }

  return (
    <div className="mb-4 overflow-x-auto">
      <div className="min-w-full">
        <table className="w-full text-sm border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 py-2 px-4 text-left">
                Tiempo Transcurrido
              </th>
              <th className="border border-gray-300 py-2 px-4 text-left whitespace-nowrap">
                Agrupador
              </th>
              <th className="border border-gray-300 py-2 px-4 text-left whitespace-nowrap">
                Plazo Titular (%)
              </th>
              <th className="border border-gray-300 py-2 px-4 text-left whitespace-nowrap">
                Plazo Servicio (%)
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <TableRow key={index} item={item} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const DataChart: React.FC<DataChartProps> = ({ data, title, onTitleChange }) => {
  if (data.length === 0) {
    return <NoTramitacionState />;
  }

  return (
    <div className="h-[300px] w-full">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" domain={[0, 100]} />
          <YAxis 
            dataKey="name" 
            type="category" 
            width={150}
            tick={{ fontSize: 12 }}
          />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          <Bar
            dataKey="plazoTitular"
            stackId="a"
            fill="#4e79a7"
            name="Plazo Titular (%)"
          />
          <Bar
            dataKey="plazoServicio"
            stackId="a"
            fill="#f28e2c"
            name="Plazo Servicio (%)"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};


const DataDisplay: React.FC<{ data: DataItem[] }> = ({ data }) => {
  if (data.length === 0) {
    return <NoTramitacionState />;
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div className="overflow-x-auto min-w-0">
        <div className="mb-4 overflow-x-auto">
          <div className="min-w-full">
            <table className="w-full text-sm border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 py-2 px-4 text-left">
                    Tiempo Transcurrido
                  </th>
                  <th className="border border-gray-300 py-2 px-4 text-left whitespace-nowrap">
                    Agrupador
                  </th>
                  <th className="border border-gray-300 py-2 px-4 text-left whitespace-nowrap">
                    Plazo Titular (%)
                  </th>
                  <th className="border border-gray-300 py-2 px-4 text-left whitespace-nowrap">
                    Plazo Servicio (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <TableRow key={index} item={item} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="border-l pl-4 min-w-0">
        <div className="h-[300px] w-full">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              layout="vertical"
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" domain={[0, 100]} />
              <YAxis 
                dataKey="name" 
                type="category" 
                width={150}
                tick={{ fontSize: 12 }}
              />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              <Bar
                dataKey="plazoTitular"
                stackId="a"
                fill="#4e79a7"
                name="Plazo Titular (%)"
              />
              <Bar
                dataKey="plazoServicio"
                stackId="a"
                fill="#f28e2c"
                name="Plazo Servicio (%)"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};





const RegistrationProtocol: React.FC<RegistrationProtocolProps> = ({
  selectedProcess,
  processes
}) => {
  const [subprocesosData, setSubprocesosData] = useState<{
    subproceso: Subproceso;
    agrupadores: DataItem[];
    isOpen: boolean;
  }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allExpanded, setAllExpanded] = useState(true);

  const calculateTimePercentages = (tramitacionTask: Task | undefined): { 
    plazoTitular: number, 
    plazoServicio: number 
  } => {
    if (!tramitacionTask?.subtasks) {
      return { plazoTitular: 0, plazoServicio: 0 };
    }
    
    console.log("Tareas de tramitación:", tramitacionTask.subtasks);
  
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    const calculateTotalTimeForSubtasks = (
      subtasks: SubTask[] | undefined,
      tipo: 'INGRESO' | 'OBSERVACIÓN' | 'RESPUESTA' | 'RESOLUCIÓN',
      today: Date
    ): number => {
      if (!subtasks) return 0;
  
      return subtasks
        .filter(subtask => {
          if (tipo === 'RESOLUCIÓN' && subtask.tipo === 'RESOLUCIÓN') {
            return true;
          }
          return subtask.tipo === tipo;
        })
        .reduce((total, subtask) => {
          const startDate = dateUtils.parseLocalDate(subtask.fecha_inicio);
          const endDate = subtask.isClosed ? dateUtils.parseLocalDate(subtask.fecha_termino) : today;
  
          const daysBetween = calculateDaysBetween(
            dateUtils.formatLocalDate(startDate),
            dateUtils.formatLocalDate(endDate)
          );
  
          console.log(`Días para ${subtask.tipo} (${subtask.nombre}): ${daysBetween} días`);
  
          // Asegura al menos 1 día contado incluso si las fechas son iguales
          return total + (daysBetween === 0 ? 1 : daysBetween);
        }, 0);
    };
  
    const tiempoIngreso = calculateTotalTimeForSubtasks(tramitacionTask.subtasks, 'INGRESO', today);
    const tiempoObservaciones = calculateTotalTimeForSubtasks(tramitacionTask.subtasks, 'OBSERVACIÓN', today);
    const tiempoRespuestas = calculateTotalTimeForSubtasks(tramitacionTask.subtasks, 'RESPUESTA', today);
    
    console.log("Tiempo de INGRESO:", tiempoIngreso);
    console.log("Tiempo de OBSERVACIÓN:", tiempoObservaciones);
    console.log("Tiempo de RESPUESTA:", tiempoRespuestas);
  
    const tiempoResolucionTitular = tramitacionTask.subtasks
      .filter(subtask => subtask.tipo === 'RESOLUCIÓN' && subtask.resolutionType === 'DESISTIMIENTO')
      .reduce((total, subtask) => {
        const endDate = subtask.isClosed ? dateUtils.parseLocalDate(subtask.fecha_termino) : today;
        const daysBetween = calculateDaysBetween(
          dateUtils.formatLocalDate(dateUtils.parseLocalDate(subtask.fecha_inicio)),
          dateUtils.formatLocalDate(endDate)
        );
        
        console.log(`Días para RESOLUCIÓN Titular (${subtask.nombre}): ${daysBetween} días`);
        
        return total + (daysBetween === 0 ? 1 : daysBetween);
      }, 0);
  
    const tiempoResolucionServicio = tramitacionTask.subtasks
      .filter(subtask => subtask.tipo === 'RESOLUCIÓN' && subtask.resolutionType !== 'DESISTIMIENTO')
      .reduce((total, subtask) => {
        const endDate = subtask.isClosed ? dateUtils.parseLocalDate(subtask.fecha_termino) : today;
        const daysBetween = calculateDaysBetween(
          dateUtils.formatLocalDate(dateUtils.parseLocalDate(subtask.fecha_inicio)),
          dateUtils.formatLocalDate(endDate)
        );
        
        console.log(`Días para RESOLUCIÓN Servicio (${subtask.nombre}): ${daysBetween} días`);
        
        return total + (daysBetween === 0 ? 1 : daysBetween);
      }, 0);
  
    const tiempoTotal = tiempoIngreso + tiempoObservaciones + tiempoRespuestas + tiempoResolucionTitular + tiempoResolucionServicio;
  
    console.log("Tiempo Total:", tiempoTotal);
    console.log("Tiempo Titular (Ingreso + Respuestas + Resolución Titular):", tiempoIngreso + tiempoRespuestas + tiempoResolucionTitular);
    console.log("Tiempo Servicio (Observaciones + Resolución Servicio):", tiempoObservaciones + tiempoResolucionServicio);
  
    if (tiempoTotal === 0) {
      return { plazoTitular: 0, plazoServicio: 0 };
    }
  
    return {
      plazoTitular: Math.round(((tiempoIngreso + tiempoRespuestas + tiempoResolucionTitular) / tiempoTotal) * 100),
      plazoServicio: Math.round(((tiempoObservaciones + tiempoResolucionServicio) / tiempoTotal) * 100)
    };
  };

  
  const loadProcessData = async (processName: string) => {
    if (!processName || !processes) {
      setSubprocesosData([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    try {
      const selectedProcess = processes.find(p => p.nombreProceso === processName);
      if (!selectedProcess?.codigo) {
        setSubprocesosData([]);
        setIsLoading(false);
        return;
      }

      const response = await fetch(`http://localhost:3000/php/pages/proceso/get_process_data.php?codigo=${selectedProcess.codigo}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      
      if (!data?.subprocesos || !Array.isArray(data.subprocesos)) {
        setSubprocesosData([]);
        setIsLoading(false);
        return;
      }

      const processedData = data.subprocesos
        .filter((sp: any) => sp && Array.isArray(sp.agrupadores))
        .map((sp: any) => {
          const agrupadoresValidos = sp.agrupadores
            .filter((agr: any) => agr && agr.enabled && agr.descriptor === "PERMISOLOGÍA" && Array.isArray(agr.tareas))
            .map((agrupador: any) => {
              const tramitacionTask = agrupador.tareas?.find((tarea: Task) => 
                tarea?.nombre === "TRAMITACIÓN"
              );

              if (!tramitacionTask) {
                return null;
              }

              const { plazoTitular, plazoServicio } = calculateTimePercentages(tramitacionTask);

              return {
                name: agrupador.nombre || '',
                agrupador: agrupador.nombre || '',
                tiempoTranscurrido: calculateDaysElapsed(
                  tramitacionTask.fecha_inicio || '',
                  tramitacionTask.fecha_termino || '',
                  !!tramitacionTask.isClosed
                ),
                fechaInicio: formatDate(tramitacionTask.fecha_inicio),
                fechaTermino: formatDate(tramitacionTask.fecha_termino),
                plazoTitular,
                plazoServicio,
              };
            })
            .filter(Boolean); // Remove null entries

          return {
            subproceso: {
              id: sp.id || 0,
              subproceso: sp.nombre || '',
              color: sp.color || getDefaultColor(sp.id || 0),
            },
            agrupadores: agrupadoresValidos,
            isOpen: true
          };
        });
        console.log(JSON.stringify(processedData))
      setSubprocesosData(processedData);
    } catch (error) {
      console.error('Error al cargar el proceso:', error);
      setSubprocesosData([]);
    } finally {
      setIsLoading(false);
    }
  };


  const toggleSubproceso = (index: number) => {
    setSubprocesosData(prevData => 
      prevData.map((item, i) => 
        i === index ? { ...item, isOpen: !item.isOpen } : item
      )
    );
    setAllExpanded(false);
  };
  
  const toggleAll = () => {
    const newExpandedState = !allExpanded;
    setAllExpanded(newExpandedState);
    setSubprocesosData(prevData =>
      prevData.map(item => ({
        ...item,
        isOpen: newExpandedState
      }))
    );
  };
  useEffect(() => {
    if (selectedProcess) {
      loadProcessData(selectedProcess);
    }
  }, [selectedProcess]);
  
  

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      {isLoading ? (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      ) : !subprocesosData || subprocesosData.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <div className="flex justify-end mb-4">
            <button
              onClick={toggleAll}
              className="flex items-center gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors duration-200"
            >
              {allExpanded ? (
                <>
                  <ChevronsUp className="w-5 h-5" />
                  <span>Colapsar todo</span>
                </>
              ) : (
                <>
                  <ChevronsDown className="w-5 h-5" />
                  <span>Expandir todo</span>
                </>
              )}
            </button>
          </div>

          {subprocesosData.map((spData, index) => (
            <div key={index} className="mb-8 border rounded-lg overflow-hidden">
              <CollapsibleHeader
                title={spData.subproceso.subproceso}
                color={spData.subproceso.color}
                isOpen={spData.isOpen}
                onToggle={() => toggleSubproceso(index)}
              />
              <div
                className={`transition-all duration-300 ease-in-out ${
                  spData.isOpen ? 'max-h-[800px] opacity-100' : 'max-h-0 opacity-0'
                } overflow-hidden`}
              >
                <div className="p-4">
                  <DataDisplay data={spData.agrupadores} />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default RegistrationProtocol;