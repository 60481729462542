import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Login.css';
import RequestPasswordReset from './RequestPasswordReset'; // Asegúrate de importar el componente

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false); // Estado para indicar carga
  const [showResetPopup, setShowResetPopup] = useState<boolean>(false); // Estado para mostrar el popup
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true); // Empieza a cargar
    setError(''); // Reinicia el error previo
    setSuccess(''); // Reinicia el éxito previo

    // Validar campos
    if (!username || !password) {
      setError('Por favor, completa todos los campos.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch('http://localhost:3000/php/login/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const text = await response.text(); // Obtén el texto de la respuesta
      let data;
      try {
        data = JSON.parse(text); // Intenta convertir el texto a JSON
      } catch (e) {
        throw new Error('La respuesta del servidor no es un JSON válido.');
      }

      console.log('Respuesta del servidor:', data);

      if (data.success) {
        setSuccess(data.message || 'Inicio de sesión exitoso.');
        sessionStorage.setItem('userId', data.id);
        sessionStorage.setItem('firstName', data.firstname);
        sessionStorage.setItem('lastName', data.lastname);
        sessionStorage.setItem('email', data.email);
        sessionStorage.setItem('title', data.title);
        sessionStorage.setItem('isAdmin', data.is_admin.toString());
      
        if (data.token) {
          localStorage.setItem('authToken', data.token);
        }
        setTimeout(() => {
          navigate('/ui'); // Redirige después de 2 segundos
        }, 2000); // Espera 2 segundos para redirigir
      } else {
        setError(data.error || 'Error al iniciar sesión.');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError(`Ocurrió un error durante el inicio de sesión. ${error}`);
    } finally {
      setLoading(false); // Termina la carga
    }
  };

  const handleResetPasswordClick = () => {
    setShowResetPopup(true);
  };

  const handleClosePopup = () => {
    setShowResetPopup(false);
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-logo"></div>
        <form onSubmit={handleLogin}>
          <div className="input-container">
            <i className="input-icon fas fa-user"></i>
            <input
              type="text"
              placeholder="Usuario"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="login-input"
            />
          </div>
          <div className="input-container">
            <i className="input-icon fas fa-lock"></i>
            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
            />
          </div>
          <button
            type="submit"
            className="login-button"
            disabled={loading} // Deshabilita el botón si loading es true
          >
            {loading ? <div className="spinner"></div> : 'INICIAR SESIÓN'}
          </button>
          {success && <p className="success-message">{success}</p>}
          {error && <p className="error-message">{error}</p>}
        </form>
        <a href="#!" className="forgot-password-link mb-3" onClick={handleResetPasswordClick}>
         ¿Olvidaste tu contraseña?
        </a>
        {showResetPopup && <RequestPasswordReset onClose={handleClosePopup} />}
      </div>
      <div className="triangle"></div>
    </div>
  );
};

export default Login;
