import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, Edit3, Save, PlusCircle, Trash2, ChevronRight } from 'lucide-react';
import { response } from 'express';
import { motion, AnimatePresence } from 'framer-motion';


interface TablaDesplegableProps {
  id: string;
  titulo: string;
  datos: { [key: string]: string }[];
  nivel: number;
  onDragStart: (id: string) => void;
  onDragEnter: (id: string) => void;
  onDragEnd: () => void;
  onEliminarTabla: (id: string) => void;
}




const TablaDesplegable: React.FC<TablaDesplegableProps> = ({ id, titulo, datos, nivel, onDragStart, onDragEnter, onDragEnd, onEliminarTabla }) => {
  const [expandido, setExpandido] = useState(true);
  const [modoEdicion, setModoEdicion] = useState(false);
  const [nuevoTitulo, setNuevoTitulo] = useState(titulo);
  const [datosEditados, setDatosEditados] = useState(datos);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [nuevoConcepto, setNuevoConcepto] = useState("");
  const [nuevaNomenclatura, setNuevaNomenclatura] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editConcept, setEditConcept] = useState("");
  const [editNomenclature, setEditNomenclature] = useState("");
  const [mensaje, setMensaje] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleGuardarTitulo = () => {
    // Lógica para guardar el título actualizado
  };

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
    setMensaje(''); // Resetear el mensaje de error al cambiar el texto
  };

  const handleAgregarNuevo = () => {
    // Lógica para agregar un nuevo concepto
  };

  const handleEliminar = (index: number) => {
    // Lógica para eliminar un concepto
  };

  const handleEditClick = (index: number) => {
    // Lógica para abrir el modal de edición
  };

  const handleSaveEdit = () => {
    // Lógica para guardar los cambios del concepto editado
  };


  return (
    <div
      className={`${
        !expandido ? '' : 'border'
      } rounded-lg flex-shrink-0 overflow-hidden min-w-[400px] w-[400px]`} // Borde solo si está colapsado
      onDragEnter={() => onDragEnter(id)}
      onDragOver={(e) => e.preventDefault()}
      onDrop={() => onDragEnd()}
    >
      <div
        onClick={() => setExpandido(!expandido)}
        className="w-full bg-cyan-300 text-black p-2 flex justify-between items-center hover:bg-cyan-400 transition-colors cursor-pointer"
        draggable
        onDragStart={() => onDragStart(id)}
      >
         {!expandido ? <ChevronRight size={20} /> : <ChevronDown size={20} />} {/* Icono tamaño 20 */}
        <h2 className="text-lg font-semibold truncate flex-grow">{titulo}</h2> {/* Título truncado */}
        <button onClick={(e) => { e.stopPropagation(); setExpandido(!expandido); }}>
         
        </button>
      </div>
      <AnimatePresence>
        {expandido && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="p-2 bg-white"
          >
            <table className="w-full border-collapse text-sm">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border p-1 text-left">{`Nivel${nivel + 1}`}</th>
                  {datos.length > 0 && Object.keys(datos[0]).filter(key => key !== `Nivel`).map((key, index) => (
                    <th key={index} className="border p-1 text-left">
                      {key}
                    </th>
                  ))}
                  <th className="border p-1 text-left">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {datos.map((fila, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                    {Object.keys(fila).map((header, idx) => (
                      <td key={idx} className="border p-1">
                        {fila[header]}
                      </td>
                    ))}
                    <td className="border p-1">
                      <button onClick={() => handleEditClick(index)} className="mr-1">
                        <Edit3 size={16} className="text-blue-500" />
                      </button>
                      <button onClick={() => handleEliminar(index)}>
                        <Trash2 size={16} className="text-red-500" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="p-2">
              <h3 className="text-sm font-semibold mb-1">Agregar concepto</h3>
              <div className="flex space-x-1">
                <input
                  type="text"
                  placeholder="Nuevo concepto"
                  value={nuevoConcepto}
                  onChange={(e) => setNuevoConcepto(e.target.value)}
                  className="p-1 border border-gray-300 rounded flex-grow text-sm"
                />
                <input
                  type="text"
                  placeholder="Nomenclatura"
                  value={nuevaNomenclatura}
                  onChange={(e) => setNuevaNomenclatura(e.target.value)}
                  className="p-1 border border-gray-300 rounded flex-grow text-sm"
                />
                <button
                  onClick={handleAgregarNuevo}
                  className="p-1 bg-green-500 text-white rounded flex items-center justify-center"
                >
                  <PlusCircle size={16} />
                </button>
              </div>
              {mensaje && (
                <div className={`mt-2 p-1 rounded text-sm ${mensaje.startsWith('Error') ? 'bg-red-200 text-red-800' : 'bg-green-200 text-green-800'}`}>
                  {mensaje}
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

/*
const TablasNomenclaturaProyecto: React.FC = () => {
  const [tabs, setTabs] = useState([
    { id: 'subcarpetas', titulo: 'Subcarpetas', datos: [
      { Nivel: 'Terreno', Nomenclatura: 'T' },
      { Nivel: 'Arquitectura', Nomenclatura: 'A' },
      { Nivel: 'Estructuras', Nomenclatura: 'E' },
      { Nivel: 'Urbanización', Nomenclatura: 'U' },
      { Nivel: 'Ingreso DOM', Nomenclatura: 'D' },
      { Nivel: 'Ingreso SERVIU', Nomenclatura: 'S' },
      { Nivel: 'Licitación', Nomenclatura: 'L' },
      { Nivel: 'Contrato', Nomenclatura: 'C' },
      { Nivel: 'Obra', Nomenclatura: 'O' },
      { Nivel: 'Normativa', Nomenclatura: 'N' },
      { Nivel: 'Finanzas', Nomenclatura: 'F' },
      { Nivel: 'Control de Proyecto', Nomenclatura: 'P' },
    ]},
    { id: 'especialidades', titulo: 'Especialidades', datos: [
      { Nivel: 'Electricidad', Nomenclatura: 'E' },
      { Nivel: 'Fontanería', Nomenclatura: 'F' },
      { Nivel: 'Climatización', Nomenclatura: 'C' },
      { Nivel: 'Seguridad', Nomenclatura: 'S' },
    ]},
    { id: 'tipoDocumento', titulo: 'Tipo de documento', datos: [
      { Nivel: 'Planos', Nomenclatura: 'P' },
      { Nivel: 'Memoria Descriptiva', Nomenclatura: 'MD' },
      { Nivel: 'Especificaciones Técnicas', Nomenclatura: 'ET' },
      { Nivel: 'Presupuestos', Nomenclatura: 'PR' },
      // ... más datos
    ]}
  ]);

  const [draggedId, setDraggedId] = useState<string | null>(null);

  const handleDragStart = (id: string) => {
    setDraggedId(id);
  };

  const handleDragEnter = (id: string) => {
    if (draggedId && draggedId !== id) {
      const draggedIndex = tabs.findIndex(tab => tab.id === draggedId);
      const targetIndex = tabs.findIndex(tab => tab.id === id);
      const updatedTabs = [...tabs];
      [updatedTabs[draggedIndex], updatedTabs[targetIndex]] = [updatedTabs[targetIndex], updatedTabs[draggedIndex]];
      setTabs(updatedTabs);
    }
  };

  const handleDragEnd = () => {
    setDraggedId(null);
  };

  const handleEliminarTabla = (id: string) => {
    setTabs(tabs.filter(tab => tab.id !== id));  // Filtrar la tabla a eliminar
  };
  

  const handleAgregarTabla = () => {
    const nuevaTabla = {
      id: `tabla-${Date.now()}`,
      titulo: 'Nuevo Nivel',
      datos: []
    };
    setTabs([...tabs, nuevaTabla]);
  };

  return (
    <div>
      <button
        onClick={handleAgregarTabla}
        className="mb-4 p-2 bg-blue-500 text-white rounded flex items-center"
      >
        <PlusCircle size={24} className="mr-2" />
        Agregar Nivel
      </button>
      {tabs.map((tab, index) => (
        <TablaDesplegable
          key={tab.id}
          id={tab.id}
          titulo={tab.titulo}
          datos={tab.datos || []}
          nivel={index + 1}
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragEnd={handleDragEnd}
          onEliminarTabla={handleEliminarTabla}
        />
      ))}
    </div>
  );
};

export default TablasNomenclaturaProyecto;
*/

// TablasNomenclaturaProyecto Component
interface Tab {
  id: string;
  titulo: string;
  datos: { Nivel: string; Nomenclatura: string }[];
}

const TablasNomenclaturaProyecto: React.FC = () => {
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    fetch('http://localhost:3000/php/pages/controldocument/glosario/get_data.php')
      .then(response => response.json())
      .then(data => setTabs(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const [draggedId, setDraggedId] = useState<string | null>(null);

  const handleDragStart = (id: string) => {
    setDraggedId(id);
  };
  const handleDragEnter = async (id: string) => {
    if (draggedId && draggedId !== id) {
        const draggedIndex = tabs.findIndex(tab => tab.id === draggedId);
        const targetIndex = tabs.findIndex(tab => tab.id === id);

        const updatedTabs = [...tabs];
        [updatedTabs[draggedIndex], updatedTabs[targetIndex]] = [updatedTabs[targetIndex], updatedTabs[draggedIndex]];
        setTabs(updatedTabs);

        // Mostrar las posiciones finales de cada carpeta
        updatedTabs.forEach((tab, index) => {
            console.log(`Index: ${index}, Name: ${tab.titulo}`);
        });

        // Enviar la actualización al backend
        try {
            const response = await fetch('http://localhost:3000/php/pages/controldocument/glosario/update_levels.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    updatedTabs.map((tab, index) => ({
                        nivel_id: index + 1, // El nuevo nivel_id basado en el índice
                        titulo: tab.titulo, // Incluir el título del tab
                    }))
                ),
            });
            const result = await response.json();
            if (result.success) {
                console.log('Base de datos actualizada con éxito');
            } else {
                console.error('Error al actualizar la base de datos:', result.message);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
        }
    }
};

  
  




  const handleDragEnd = () => {
    setDraggedId(null);
  };
  const handleEliminarTabla = async (id: string) => {
    // Encuentra el registro a eliminar usando el ID
    const tabToDelete = tabs.find(tab => tab.id === id);
   
    if (tabToDelete) {
        const { id} = tabToDelete;
        
        try {
            const response = await fetch('http://localhost:3000/php/pages/controldocument/glosario/delete_level.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ nivel_id: id}),
            });
            const data = await response.json();
            if (data.success) {
                // Actualizar el estado si la eliminación fue exitosa
                setTabs(tabs.filter(tab => tab.id !== id));
            } else {
                console.error('Error al eliminar la tabla:', data.message);
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    } else {
        console.error('No se encontró el registro con el ID proporcionado');
    }
};
  

/*
  const handleAgregarTabla = () => {
   const nuevaTabla = {
      id: `tabla-${Date.now()}`,
      titulo: 'Nuevo Nivel',
      datos: []
    };
    setTabs([...tabs, nuevaTabla]);
  };
*/
const handleAgregarTabla = () => {
  const nuevaTabla = {
    titulo: 'Nuevo Nivel',
    datos: []
  };

  fetch('http://localhost:3000/php/pages/controldocument/glosario/add_level.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(nuevaTabla),
  })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // Si la solicitud fue exitosa, agregar el nuevo nivel al estado con el id retornado
        setTabs(prevTabs => [
          ...prevTabs,
          {
           id: data.newId, // Usar el id retornado por el backend
            titulo: nuevaTabla.titulo,
            datos: nuevaTabla.datos
          }
        ]);

        // Mostrar mensaje de éxito
        setSuccessMessage('Nivel agregado exitosamente.');
        setErrorMessage('');

        // Cerrar el mensaje después de 3 segundos
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        // Mostrar mensaje de error
        setErrorMessage(`Error al agregar el nuevo nivel: ${data.message}`);
        setSuccessMessage('');
      }
    })
    .catch(error => {
      console.error('Error en la solicitud:', error);
      setErrorMessage('Error en la solicitud.');
      setSuccessMessage('');
    });
};

return (
  <div className="p-4">
    {/* Mostrar mensajes de éxito o error en la parte superior */}
    {successMessage && (
      <div className="mb-4 p-2 rounded bg-green-200 text-green-800">
        {successMessage}
      </div>
    )}
    {errorMessage && (
      <div className="mb-4 p-2 rounded bg-red-200 text-red-800">
        {errorMessage}
      </div>
    )}

    {/* Botón para agregar un nuevo nivel, separado de los mensajes */}
    <div className="flex justify-start mb-4">
      <button
        onClick={handleAgregarTabla}
        className="p-2 bg-blue-500 text-white rounded flex items-center hover:bg-blue-600 transition-colors"
      >
        <PlusCircle size={24} className="mr-2" />
        Agregar Nivel
      </button>
    </div>

    {/* Contenedor de tablas en una fila horizontal */}
    <div className="flex flex-row overflow-x-auto space-x-4">
      {tabs.map((tab, index) => (
        <TablaDesplegable
          key={tab.id}
          id={tab.id}
          titulo={tab.titulo}
          datos={tab.datos}
          nivel={index + 1}
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragEnd={handleDragEnd}
          onEliminarTabla={() => {
            // Lógica para eliminar el nivel y mostrar el mensaje
            setTabs(tabs.filter((_, i) => i !== index));
            setSuccessMessage('Nivel eliminado exitosamente');
            setTimeout(() => setSuccessMessage(''), 3000); // Ocultar mensaje después de 3 segundos
          }}
        />
      ))}
    </div>
  </div>
);
};
export default TablasNomenclaturaProyecto;